import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './pitch-deck-analysis.css';
import NavBar from '../../../../layout/navbar/navbar';
import CreateEditOverview from './create-edit-pitch-deck-analysis/overview';
import CreateEditBusiness from './create-edit-pitch-deck-analysis/business';
import CreateEditSolution from './create-edit-pitch-deck-analysis/solution';
import CreateEditMarket from './create-edit-pitch-deck-analysis/market';
import CreateEditCompetition from './create-edit-pitch-deck-analysis/competition';
import CreateEditOperations from './create-edit-pitch-deck-analysis/operations';
import CreateEditMarketing from './create-edit-pitch-deck-analysis/marketing';
import CreateEditFinancials from './create-edit-pitch-deck-analysis/financials';
import CreateEditRisk from './create-edit-pitch-deck-analysis/risk';
// import CreateEditManagement from './create-edit-pitch-deck-analysis/management';
import DataRoomComponent from '../../../data-room/data-room-component';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { FaEllipsisH } from 'react-icons/fa';
import { useRealmApp } from '../../../../RealmApp';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCheckSquare, AiOutlineCloseCircle } from 'react-icons/ai'
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';


const CreatePitchDeckAnalysis = () => {

    // get current user
    const { currentUser } = useRealmApp();

    const { analyzePitchDeck, savePitchDeck, generateAddEditFormId } = currentUser.functions;

    // use history to navigate page
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, {

        pitchDeckId: "",
        pitchDeckName: "",

        // Overview Tab
        entityName: "",
        entityType: "", // Multi-select
        locationCity: "",
        locationState: "",
        locationCountry: "", // Multi-select
        ownershipStatus: "", // Multi-select
        onesentencePitch: "",
        markets: [],
        stageOfDevelopment: "", // Multi-select
        transactionalModelType: "", // Multi-select
        defensibilityMoats: [],
        industry: "",  // Multi-select
        categories: [],
        vision: "",
        mission: "",
        lastFinancingDate: "",
        lastFinancingRound: "",
        numOfFundingRounds: "",

        // Business Tab
        businessDescription: "",
        customerPain: [""],
        areYouSolvingPersonalCriticalPainPoint: [""],
        howProblemIsAddressed: [""],
        currentSolutionsShortcomings: [""],
        howToGrowBusiness: [""],
        unitEconomics: [""],
        doesPriceChange: [""],
        preMoneyValuation: [""],
        postMoneyValuation: [""],
        equityOwnershipStakeToInvestors: [""],
        financingRound: "", // Multi-select
        financingStrategy: [""],
        howToUseMoney: [""],
        howMuchMoneyToRaise: [""],
        howMuchExpectedFromInvestors: [""],
        milestonesToAchieveBeforeRaiseMoney: [""],
        areYouRaisingEnoughToAccomplishMilestones: [""],
        exitStrategy: [""],
        howInvestorsGetMoney: [""],

        // Solution Tab
        customerGain: [""],
        howProductMeetNeeds: [""],
        willProductSolveNeeds: [""],
        howDidYouGetIdea: [""],
        isProductAspirin: [""],
        isProductVitamin: [""],
        factorsImportantInChoosing: [""],
        howYouRespondToNeed: [""],
        doCustomersLoveProduct: [""],

        // Market Tab
        customerSegments: [""],
        marketSegments: [""],
        problemProductSolves: [""],
        doesProductMakeLivesBetter: [""],
        marketTiming: [""],

        // Competition Tab
        directCompetitors: [""],
        similarCompanies: [""],
        productsAndServices: [""],
        marketShare: [""],
        strengthsAndWeaknesses: [""],
        doCompetitorsEnjoyRecognition: [""],
        marketingStrategies: [""],
        keyFactorsToProfitability: [""],
        competitiveDifferentiation: [""],
        whyValuePropositionIsUnique: [""],
        howYourProductIsBetter: [""],
        differentiationsFromCompetitorsProduct: [""],
        competitiveOutlookForIndustry: [""],
        howCompetitorsThreaten: [""],
        willCompetitorsBlock: [""],
        willCompetitorsPoach: [""],
        whoElseMightExploit: [""],

        // Operations Tab
        kpiMetrics: [""],
        metricsThatShowAchievement: [""],
        growthRate: [""],
        proofOfLatentDemand: [""],
        isBusinessGrowing: [""],
        isProductSelfPerpetuatingGrowth: [""],
        ableToAccomplishWithLess: [""],

        // Marketing Tab
        goToMarketStrategy: [""],
        doesBusinessModelSupportAcquisition: [""],
        planningToAcquireCustomersThroughMarketing: [""],
        howProductWillBeDistributed: [""],
        distributionChannelsUsed: [""],
        whenWhereWhyHowConsumersBuy: [""],
        isProductTransactionOriented: [""],
        isBuyingProcessRelationshipOriented: [""],
        areYouPlanningToBuildDirectForce: [""],
        howToCommunicateWithConsumers: [""],
        willUseWordOfMouth: [""],
        willOptForSalesPromotions: [""],
        doDirectSalesMakeSense: [""],
        isTraditionalBetter: [""],
        doYouNeedToBuildBrandLoyalty: [""],

        // Financials Tab
        monetizationModel: [""],
        areYouBuildingAnAnnuityBusiness: [""],
        areYouSellingDurableProduct: [""],
        financialMargins: [""],
        observedUnitEconomics: [""],
        doUnitEconomicsMakeSense: [""],
        pricingModel: [""],
        pricingModelCustomerDemand: [""],
        pricePointProductOffer: [""],
        isPriceEstablished: [""],
        relevantPricingStrategy: [""],
        appropriatePricingStrategy: [""],
        financialRevenue: [""],
        moneyMadeFromTargetMarket: [""],
        whenIsBusinessExpectedToMakeMoney: [""],
        durationToMakeMoney: [""],
        whenIsBusinessExpectedToReachBreakEven: [""],
        anticipateToBreakEven: [""],
        salesTargetBasedOnProjectedGrowth: [""],
        howLongToReachNextSalesMilestone: [""],
        costAcquiringCustomer: [""],
        howMuchWillCustomerBeWorth: [""],
        internalComponentsAssumptions: [""],
        // income statement year 2
        revenueYear2: 0,
        costOfRevenueYear2: 0,
        grossProfitYear2: 0,
        researchAndDevelopmentYear2: 0,
        salesAndMarketingYear2: 0,
        generalAndAdministrativeYear2: 0,
        totalOperatingExpensesYear2: 0,
        operatingIncomeYear2: 0,
        interestExpenseYear2: 0,
        otherIncomeNetYear2: 0,
        incomeBeforeIncomeTaxesYear2: 0,
        provisionForIncomeTaxesYear2: 0,
        netIncomeNetLossYear2: 0,
        // balance sheet year 2
        cashAndCashEquivalentsYear2: 0,
        accountsReceivableNetYear2: 0,
        inventoryYear2: 0,
        otherCurrentAssetsYear2: 0,
        totalCurrentAssetsYear2: 0,
        propertyPlantAndEquipmentNetYear2: 0,
        intangibleAssetsNetYear2: 0,
        otherNonCurrentAssetsYear2: 0,
        totalNonCurrentAssetsYear2: 0,
        totalAssetsYear2: 0,
        accountsPayableYear2: 0,
        accruedAndOtherYear2: 0,
        shortTermDebtYear2: 0,
        totalCurrentLiabilitiesYear2: 0,
        longTermDebtYear2: 0,
        otherLiabilitiesYear2: 0,
        totalNonCurrentLiabilitiesYear2: 0,
        totalLiabilitiesYear2: 0,
        equityCapitalYear2: 0,
        retainedEarningsYear2: 0,
        additionalPaidInCapitalYear2: 0,
        totalShareholdersEquityYear2: 0,
        totalLiabilitiesAndShareholdersEquityYear2: 0,
        // cash flow statement year 2
        netIncomeYear2: 0,
        depreciationAndAmortizationYear2: 0,
        changesInAssetsAndLiabilitiesYear2: 0,
        netCashProvidedByOperatingActivitiesYear2: 0,
        capitalExpenditureYear2: 0,
        capitalizedSoftwareDevelopmentCostsYear2: 0,
        maturitiesAndSalesOfInvestmentsYear2: 0,
        cashPaidForAcquisitionOfBusinessYear2: 0,
        netCashProvidedByInvestingActivitiesYear2: 0,
        proceedsFromIssuanceOfDebtYear2: 0,
        proceedsFromIssuanceOfEquityYear2: 0,
        debtRelatedCostsAndOtherNetYear2: 0,
        netCashProvidedByFinancingActivitiesYear2: 0,
        changesInCashDuringPeriodYear2: 0,
        cashAtBeginningOfPeriodYear2: 0,
        cashAtEndOfPeriodYear2: 0,
        // break even analysis year 2
        timeFrameYear2: "",
        averagePriceSoldYear2: 0,
        averageCostToMakeOrDeliverYear2: 0,
        fixedCostsforMonthOrYearYear2: 0,
        percentageOfPriceProfitYear2: 0,
        totalSalesNeededToBreakEvenYear2: 0,
        numberOfUnitsSoldNeededToBreakEvenYear2: 0,
        // financial ratios analysis year 2
        profitMarginYear2: 0,
        returnOnEquityAfterTaxYear2: 0,
        returnOnAssetsYear2: 0,
        grossMarginYear2: 0,
        grossProfitMarginYear2: 0,
        netProfitMarginYear2: 0,
        operatingMarginYear2: 0,
        currentRatioYear2: 0,
        quickRatioYear2: 0,
        cashRatioYear2: 0,
        inventoryTurnoverYear2: 0,
        assetTurnoverYear2: 0,
        receivablesTurnoverYear2: 0,
        ebitYear2: 0,
        ebitdaYear2: 0,
        debtRatioYear2: 0,
        debtToEquityRatioYear2: 0,
        interestCoverageRatioYear2: 0,
        earningsPerShareBasicYear2: 0,
        earningsPerShareDilutedYear2: 0,
        dividendPayoutRatioYear2: 0,
        priceToEarningsRatioYear2: 0,
        // income statement year 1
        revenueYear1: 0,
        costOfRevenueYear1: 0,
        grossProfitYear1: 0,
        researchAndDevelopmentYear1: 0,
        salesAndMarketingYear1: 0,
        generalAndAdministrativeYear1: 0,
        totalOperatingExpensesYear1: 0,
        operatingIncomeYear1: 0,
        interestExpenseYear1: 0,
        otherIncomeNetYear1: 0,
        incomeBeforeIncomeTaxesYear1: 0,
        provisionForIncomeTaxesYear1: 0,
        netIncomeNetLossYear1: 0,
        // balance sheet year 1
        cashAndCashEquivalentsYear1: 0,
        accountsReceivableNetYear1: 0,
        inventoryYear1: 0,
        otherCurrentAssetsYear1: 0,
        totalCurrentAssetsYear1: 0,
        propertyPlantAndEquipmentNetYear1: 0,
        intangibleAssetsNetYear1: 0,
        otherNonCurrentAssetsYear1: 0,
        totalNonCurrentAssetsYear1: 0,
        totalAssetsYear1: 0,
        accountsPayableYear1: 0,
        accruedAndOtherYear1: 0,
        shortTermDebtYear1: 0,
        totalCurrentLiabilitiesYear1: 0,
        longTermDebtYear1: 0,
        otherLiabilitiesYear1: 0,
        totalNonCurrentLiabilitiesYear1: 0,
        totalLiabilitiesYear1: 0,
        equityCapitalYear1: 0,
        retainedEarningsYear1: 0,
        additionalPaidInCapitalYear1: 0,
        totalShareholdersEquityYear1: 0,
        totalLiabilitiesAndShareholdersEquityYear1: 0,
        // cash flow statement year 1
        netIncomeYear1: 0,
        depreciationAndAmortizationYear1: 0,
        changesInAssetsAndLiabilitiesYear1: 0,
        netCashProvidedByOperatingActivitiesYear1: 0,
        capitalExpenditureYear1: 0,
        capitalizedSoftwareDevelopmentCostsYear1: 0,
        maturitiesAndSalesOfInvestmentsYear1: 0,
        cashPaidForAcquisitionOfBusinessYear1: 0,
        netCashProvidedByInvestingActivitiesYear1: 0,
        proceedsFromIssuanceOfDebtYear1: 0,
        proceedsFromIssuanceOfEquityYear1: 0,
        debtRelatedCostsAndOtherNetYear1: 0,
        netCashProvidedByFinancingActivitiesYear1: 0,
        changesInCashDuringPeriodYear1: 0,
        cashAtBeginningOfPeriodYear1: 0,
        cashAtEndOfPeriodYear1: 0,
        // break even analysis year 1
        timeFrameYear1: "",
        averagePriceSoldYear1: 0,
        averageCostToMakeOrDeliverYear1: 0,
        fixedCostsforMonthOrYearYear1: 0,
        percentageOfPriceProfitYear1: 0,
        totalSalesNeededToBreakEvenYear1: 0,
        numberOfUnitsSoldNeededToBreakEvenYear1: 0,
        // financial ratios analysis year 1
        profitMarginYear1: 0,
        returnOnEquityAfterTaxYear1: 0,
        returnOnAssetsYear1: 0,
        grossMarginYear1: 0,
        grossProfitMarginYear1: 0,
        netProfitMarginYear1: 0,
        operatingMarginYear1: 0,
        currentRatioYear1: 0,
        quickRatioYear1: 0,
        cashRatioYear1: 0,
        inventoryTurnoverYear1: 0,
        assetTurnoverYear1: 0,
        receivablesTurnoverYear1: 0,
        ebitYear1: 0,
        ebitdaYear1: 0,
        debtRatioYear1: 0,
        debtToEquityRatioYear1: 0,
        interestCoverageRatioYear1: 0,
        earningsPerShareBasicYear1: 0,
        earningsPerShareDilutedYear1: 0,
        dividendPayoutRatioYear1: 0,
        priceToEarningsRatioYear1: 0,
        // income statement year 0
        revenueYear0: 0,
        costOfRevenueYear0: 0,
        grossProfitYear0: 0,
        researchAndDevelopmentYear0: 0,
        salesAndMarketingYear0: 0,
        generalAndAdministrativeYear0: 0,
        totalOperatingExpensesYear0: 0,
        operatingIncomeYear0: 0,
        interestExpenseYear0: 0,
        otherIncomeNetYear0: 0,
        incomeBeforeIncomeTaxesYear0: 0,
        provisionForIncomeTaxesYear0: 0,
        netIncomeNetLossYear0: 0,
        // balance sheet year 0
        cashAndCashEquivalentsYear0: 0,
        accountsReceivableNetYear0: 0,
        inventoryYear0: 0,
        otherCurrentAssetsYear0: 0,
        totalCurrentAssetsYear0: 0,
        propertyPlantAndEquipmentNetYear0: 0,
        intangibleAssetsNetYear0: 0,
        otherNonCurrentAssetsYear0: 0,
        totalNonCurrentAssetsYear0: 0,
        totalAssetsYear0: 0,
        accountsPayableYear0: 0,
        accruedAndOtherYear0: 0,
        shortTermDebtYear0: 0,
        totalCurrentLiabilitiesYear0: 0,
        longTermDebtYear0: 0,
        otherLiabilitiesYear0: 0,
        totalNonCurrentLiabilitiesYear0: 0,
        totalLiabilitiesYear0: 0,
        equityCapitalYear0: 0,
        retainedEarningsYear0: 0,
        additionalPaidInCapitalYear0: 0,
        totalShareholdersEquityYear0: 0,
        totalLiabilitiesAndShareholdersEquityYear0: 0,
        // cash flow statement year 0
        netIncomeYear0: 0,
        depreciationAndAmortizationYear0: 0,
        changesInAssetsAndLiabilitiesYear0: 0,
        netCashProvidedByOperatingActivitiesYear0: 0,
        capitalExpenditureYear0: 0,
        capitalizedSoftwareDevelopmentCostsYear0: 0,
        maturitiesAndSalesOfInvestmentsYear0: 0,
        cashPaidForAcquisitionOfBusinessYear0: 0,
        netCashProvidedByInvestingActivitiesYear0: 0,
        proceedsFromIssuanceOfDebtYear0: 0,
        proceedsFromIssuanceOfEquityYear0: 0,
        debtRelatedCostsAndOtherNetYear0: 0,
        netCashProvidedByFinancingActivitiesYear0: 0,
        changesInCashDuringPeriodYear0: 0,
        cashAtBeginningOfPeriodYear0: 0,
        cashAtEndOfPeriodYear0: 0,
        // break even analysis year 0
        timeFrameYear0: "",
        averagePriceSoldYear0: 0,
        averageCostToMakeOrDeliverYear0: 0,
        fixedCostsforMonthOrYearYear0: 0,
        percentageOfPriceProfitYear0: 0,
        totalSalesNeededToBreakEvenYear0: 0,
        numberOfUnitsSoldNeededToBreakEvenYear0: 0,
        // financial ratios analysis year 0
        profitMarginYear0: 0,
        returnOnEquityAfterTaxYear0: 0,
        returnOnAssetsYear0: 0,
        grossMarginYear0: 0,
        grossProfitMarginYear0: 0,
        netProfitMarginYear0: 0,
        operatingMarginYear0: 0,
        currentRatioYear0: 0,
        quickRatioYear0: 0,
        cashRatioYear0: 0,
        inventoryTurnoverYear0: 0,
        assetTurnoverYear0: 0,
        receivablesTurnoverYear0: 0,
        ebitYear0: 0,
        ebitdaYear0: 0,
        debtRatioYear0: 0,
        debtToEquityRatioYear0: 0,
        interestCoverageRatioYear0: 0,
        earningsPerShareBasicYear0: 0,
        earningsPerShareDilutedYear0: 0,
        dividendPayoutRatioYear0: 0,
        priceToEarningsRatioYear0: 0,

        // Risk Tab
        technologyRisk: [""],
        intellectualPropertyRisk: [""],
        developmentRisk: [""],
        operationalRisk: [""],
        productFitRisk: [""],
        channelRisk: [""],
        regulatoryRisk: [""],
        competitorRisk: [""],
        teamRisk: [""],
        hiringRisk: [""],
        financingRisk: [""],
        ceoRisk: [""],

        // Management Tab
        // teamExperience: [""],
        // doFoundersHaveInsightsOfCompetitiveLandscape: [""],
        // whereTeamWorked: [""],
        // teamExperienceAmount: [""],
        // contactsInTarget: [""],
        // workDoneRelatingToBusiness: [""],
        // teamAccomplishments: [""],
        // teamSchool: [""],
        // teamAchievements: [""],
        // teamProvenTrackRecord: [""],
        // teamAbilities: [""],
        // teamReputation: [""],
        // teamIntegrity: [""],
        // teamDedication: [""],
        // teamRealisticAboutSuccess: [""],
        // teamCapableOfRecognizingRisks: [""],
        // teamCourageToMakeDecisions: [""],
        // teamWhoIsVisionary: [""],
        // teamOfferingCaution: [""],
        // teamCommitmentToVenture: [""],
        // whatMotivatesTeam: [""],
        // whatAchievementsTeamWant: [""],
        // whatBenefitsTeamWant: [""],

        analysisResult: null,
    });

    function reducer(state, action) {

        switch (action.type) {

            case 'update pitchDeckId': { return { ...state, pitchDeckId: action.value } }

            case 'update pitchDeckName': { return { ...state, pitchDeckName: action.value } }

            // Overview Tab
            case 'update entityName': { return { ...state, entityName: action.value } }

            case 'update entityType': { return { ...state, entityType: action.value } }

            case 'update locationCity': { return { ...state, locationCity: action.value } }

            case 'update locationState': { return { ...state, locationState: action.value } }

            case 'update locationCountry': { return { ...state, locationCountry: action.value } }

            case 'update ownershipStatus': { return { ...state, ownershipStatus: action.value } }

            case 'update onesentencePitch': { return { ...state, onesentencePitch: action.value } }

            case 'update markets': return { ...state, markets: action.value }

            case 'update stageOfDevelopment': return { ...state, stageOfDevelopment: action.value }

            case 'update transactionalModelType': return { ...state, transactionalModelType: action.value }

            case 'update defensibilityMoats': return { ...state, defensibilityMoats: action.value }

            case 'update industry': return { ...state, industry: action.value }

            case 'update categories': return { ...state, categories: action.value }

            case 'update vision': return { ...state, vision: action.value }

            case 'update mission': return { ...state, mission: action.value }

            case 'update lastFinancingDate': return { ...state, lastFinancingDate: action.value }

            case 'update lastFinancingRound': return { ...state, lastFinancingRound: action.value }

            case 'update numOfFundingRounds': return { ...state, numOfFundingRounds: action.value }

            // Business Tab
            // Add fields
            case 'add customerPain':
                return {
                    ...state,
                    customerPain: [
                        ...state.customerPain,
                        action.value
                    ]
                };

            case 'add areYouSolvingPersonalCriticalPainPoint':
                return {
                    ...state,
                    areYouSolvingPersonalCriticalPainPoint: [
                        ...state.areYouSolvingPersonalCriticalPainPoint,
                        action.value
                    ]
                };

            case 'add howProblemIsAddressed':
                return {
                    ...state,
                    howProblemIsAddressed: [
                        ...state.howProblemIsAddressed,
                        action.value
                    ]
                };

            case 'add currentSolutionsShortcomings':
                return {
                    ...state,
                    currentSolutionsShortcomings: [
                        ...state.currentSolutionsShortcomings,
                        action.value
                    ]
                };

            case 'add howToGrowBusiness':
                return {
                    ...state,
                    howToGrowBusiness: [
                        ...state.howToGrowBusiness,
                        action.value
                    ]
                };

            case 'add unitEconomics':
                return {
                    ...state,
                    unitEconomics: [
                        ...state.unitEconomics,
                        action.value
                    ]
                };

            case 'add doesPriceChange':
                return {
                    ...state,
                    doesPriceChange: [
                        ...state.doesPriceChange,
                        action.value
                    ]
                };

            case 'add preMoneyValuation':
                return {
                    ...state,
                    preMoneyValuation: [
                        ...state.preMoneyValuation,
                        action.value
                    ]
                };

            case 'add postMoneyValuation':
                return {
                    ...state,
                    postMoneyValuation: [
                        ...state.postMoneyValuation,
                        action.value
                    ]
                };

            case 'add equityOwnershipStakeToInvestors':
                return {
                    ...state,
                    equityOwnershipStakeToInvestors: [
                        ...state.equityOwnershipStakeToInvestors,
                        action.value
                    ]
                };

            case 'add financingStrategy':
                return {
                    ...state,
                    financingStrategy: [
                        ...state.financingStrategy,
                        action.value
                    ]
                };

            case 'add howToUseMoney':
                return {
                    ...state,
                    howToUseMoney: [
                        ...state.howToUseMoney,
                        action.value
                    ]
                };

            case 'add howMuchMoneyToRaise':
                return {
                    ...state,
                    howMuchMoneyToRaise: [
                        ...state.howMuchMoneyToRaise,
                        action.value
                    ]
                };

            case 'add howMuchExpectedFromInvestors':
                return {
                    ...state,
                    howMuchExpectedFromInvestors: [
                        ...state.howMuchExpectedFromInvestors,
                        action.value
                    ]
                };

            case 'add milestonesToAchieveBeforeRaiseMoney':
                return {
                    ...state,
                    milestonesToAchieveBeforeRaiseMoney: [
                        ...state.milestonesToAchieveBeforeRaiseMoney,
                        action.value
                    ]
                };

            case 'add areYouRaisingEnoughToAccomplishMilestones':
                return {
                    ...state,
                    areYouRaisingEnoughToAccomplishMilestones: [
                        ...state.areYouRaisingEnoughToAccomplishMilestones,
                        action.value
                    ]
                };

            case 'add exitStrategy':
                return {
                    ...state,
                    exitStrategy: [
                        ...state.exitStrategy,
                        action.value
                    ]
                };

            case 'add howInvestorsGetMoney':
                return {
                    ...state,
                    howInvestorsGetMoney: [
                        ...state.howInvestorsGetMoney,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove customerPain': {
                const copiedList = [...state.customerPain];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerPain: copiedList
                }
            }

            case 'remove areYouSolvingPersonalCriticalPainPoint': {
                const copiedList = [...state.areYouSolvingPersonalCriticalPainPoint];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    areYouSolvingPersonalCriticalPainPoint: copiedList
                }
            }

            case 'remove howProblemIsAddressed': {
                const copiedList = [...state.howProblemIsAddressed];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howProblemIsAddressed: copiedList
                }
            }

            case 'remove currentSolutionsShortcomings': {
                const copiedList = [...state.currentSolutionsShortcomings];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    currentSolutionsShortcomings: copiedList
                }
            }

            case 'remove howToGrowBusiness': {
                const copiedList = [...state.howToGrowBusiness];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howToGrowBusiness: copiedList
                }
            }

            case 'remove unitEconomics': {
                const copiedList = [...state.unitEconomics];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    unitEconomics: copiedList
                }
            }

            case 'remove doesPriceChange': {
                const copiedList = [...state.doesPriceChange];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doesPriceChange: copiedList
                }
            }

            case 'remove preMoneyValuation': {
                const copiedList = [...state.preMoneyValuation];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    preMoneyValuation: copiedList
                }
            }

            case 'remove postMoneyValuation': {
                const copiedList = [...state.postMoneyValuation];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    postMoneyValuation: copiedList
                }
            }

            case 'remove equityOwnershipStakeToInvestors': {
                const copiedList = [...state.equityOwnershipStakeToInvestors];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    equityOwnershipStakeToInvestors: copiedList
                }
            }

            case 'remove financingStrategy': {
                const copiedList = [...state.financingStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    financingStrategy: copiedList
                }
            }

            case 'remove howToUseMoney': {
                const copiedList = [...state.howToUseMoney];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howToUseMoney: copiedList
                }
            }

            case 'remove howMuchMoneyToRaise': {
                const copiedList = [...state.howMuchMoneyToRaise];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howMuchMoneyToRaise: copiedList
                }
            }

            case 'remove howMuchExpectedFromInvestors': {
                const copiedList = [...state.howMuchExpectedFromInvestors];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howMuchExpectedFromInvestors: copiedList
                }
            }

            case 'remove milestonesToAchieveBeforeRaiseMoney': {
                const copiedList = [...state.milestonesToAchieveBeforeRaiseMoney];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    milestonesToAchieveBeforeRaiseMoney: copiedList
                }
            }

            case 'remove areYouRaisingEnoughToAccomplishMilestones': {
                const copiedList = [...state.areYouRaisingEnoughToAccomplishMilestones];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    areYouRaisingEnoughToAccomplishMilestones: copiedList
                }
            }

            case 'remove exitStrategy': {
                const copiedList = [...state.exitStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    exitStrategy: copiedList
                }
            }

            case 'remove howInvestorsGetMoney': {
                const copiedList = [...state.howInvestorsGetMoney];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howInvestorsGetMoney: copiedList
                }
            }

            // Update fields
            case 'update businessDescription': { return { ...state, businessDescription: action.value } }

            case 'update customerPain': {
                const index = action.index;
                const copiedList = [...state.customerPain];
                copiedList[index] = action.value;
                return { ...state, customerPain: copiedList }
            }

            case 'update areYouSolvingPersonalCriticalPainPoint': {
                const index = action.index;
                const copiedList = [...state.areYouSolvingPersonalCriticalPainPoint];
                copiedList[index] = action.value;
                return { ...state, areYouSolvingPersonalCriticalPainPoint: copiedList }
            }

            case 'update howProblemIsAddressed': {
                const index = action.index;
                const copiedList = [...state.howProblemIsAddressed];
                copiedList[index] = action.value;
                return { ...state, howProblemIsAddressed: copiedList }
            }

            case 'update currentSolutionsShortcomings': {
                const index = action.index;
                const copiedList = [...state.currentSolutionsShortcomings];
                copiedList[index] = action.value;
                return { ...state, currentSolutionsShortcomings: copiedList }
            }

            case 'update howToGrowBusiness': {
                const index = action.index;
                const copiedList = [...state.howToGrowBusiness];
                copiedList[index] = action.value;
                return { ...state, howToGrowBusiness: copiedList }
            }

            case 'update unitEconomics': {
                const index = action.index;
                const copiedList = [...state.unitEconomics];
                copiedList[index] = action.value;
                return { ...state, unitEconomics: copiedList }
            }

            case 'update doesPriceChange': {
                const index = action.index;
                const copiedList = [...state.doesPriceChange];
                copiedList[index] = action.value;
                return { ...state, doesPriceChange: copiedList }
            }

            case 'update preMoneyValuation': {
                const index = action.index;
                const copiedList = [...state.preMoneyValuation];
                copiedList[index] = action.value;
                return { ...state, preMoneyValuation: copiedList }
            }

            case 'update postMoneyValuation': {
                const index = action.index;
                const copiedList = [...state.postMoneyValuation];
                copiedList[index] = action.value;
                return { ...state, postMoneyValuation: copiedList }
            }

            case 'update equityOwnershipStakeToInvestors': {
                const index = action.index;
                const copiedList = [...state.equityOwnershipStakeToInvestors];
                copiedList[index] = action.value;
                return { ...state, equityOwnershipStakeToInvestors: copiedList }
            }

            case 'update financingRound': { return { ...state, financingRound: action.value } }

            case 'update financingStrategy': {
                const index = action.index;
                const copiedList = [...state.financingStrategy];
                copiedList[index] = action.value;
                return { ...state, financingStrategy: copiedList }
            }

            case 'update howToUseMoney': {
                const index = action.index;
                const copiedList = [...state.howToUseMoney];
                copiedList[index] = action.value;
                return { ...state, howToUseMoney: copiedList }
            }

            case 'update howMuchMoneyToRaise': {
                const index = action.index;
                const copiedList = [...state.howMuchMoneyToRaise];
                copiedList[index] = action.value;
                return { ...state, howMuchMoneyToRaise: copiedList }
            }

            case 'update howMuchExpectedFromInvestors': {
                const index = action.index;
                const copiedList = [...state.howMuchExpectedFromInvestors];
                copiedList[index] = action.value;
                return { ...state, howMuchExpectedFromInvestors: copiedList }
            }

            case 'update milestonesToAchieveBeforeRaiseMoney': {
                const index = action.index;
                const copiedList = [...state.milestonesToAchieveBeforeRaiseMoney];
                copiedList[index] = action.value;
                return { ...state, milestonesToAchieveBeforeRaiseMoney: copiedList }
            }

            case 'update areYouRaisingEnoughToAccomplishMilestones': {
                const index = action.index;
                const copiedList = [...state.areYouRaisingEnoughToAccomplishMilestones];
                copiedList[index] = action.value;
                return { ...state, areYouRaisingEnoughToAccomplishMilestones: copiedList }
            }

            case 'update exitStrategy': {
                const index = action.index;
                const copiedList = [...state.exitStrategy];
                copiedList[index] = action.value;
                return { ...state, exitStrategy: copiedList }
            }

            case 'update howInvestorsGetMoney': {
                const index = action.index;
                const copiedList = [...state.howInvestorsGetMoney];
                copiedList[index] = action.value;
                return { ...state, howInvestorsGetMoney: copiedList }
            }

            // Solution Tab
            // Add fields
            case 'add customerGain':
                return {
                    ...state,
                    customerGain: [
                        ...state.customerGain,
                        action.value
                    ]
                };

            case 'add howProductMeetNeeds':
                return {
                    ...state,
                    howProductMeetNeeds: [
                        ...state.howProductMeetNeeds,
                        action.value
                    ]
                };

            case 'add willProductSolveNeeds':
                return {
                    ...state,
                    willProductSolveNeeds: [
                        ...state.willProductSolveNeeds,
                        action.value
                    ]
                };

            case 'add howDidYouGetIdea':
                return {
                    ...state,
                    howDidYouGetIdea: [
                        ...state.howDidYouGetIdea,
                        action.value
                    ]
                };

            case 'add isProductAspirin':
                return {
                    ...state,
                    isProductAspirin: [
                        ...state.isProductAspirin,
                        action.value
                    ]
                };

            case 'add isProductVitamin':
                return {
                    ...state,
                    isProductVitamin: [
                        ...state.isProductVitamin,
                        action.value
                    ]
                };

            case 'add factorsImportantInChoosing':
                return {
                    ...state,
                    factorsImportantInChoosing: [
                        ...state.factorsImportantInChoosing,
                        action.value
                    ]
                };

            case 'add howYouRespondToNeed':
                return {
                    ...state,
                    howYouRespondToNeed: [
                        ...state.howYouRespondToNeed,
                        action.value
                    ]
                };

            case 'add doCustomersLoveProduct':
                return {
                    ...state,
                    doCustomersLoveProduct: [
                        ...state.doCustomersLoveProduct,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove customerGain': {
                const copiedList = [...state.customerGain];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerGain: copiedList
                }
            }

            case 'remove howProductMeetNeeds': {
                const copiedList = [...state.howProductMeetNeeds];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howProductMeetNeeds: copiedList
                }
            }

            case 'remove willProductSolveNeeds': {
                const copiedList = [...state.willProductSolveNeeds];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    willProductSolveNeeds: copiedList
                }
            }

            case 'remove howDidYouGetIdea': {
                const copiedList = [...state.howDidYouGetIdea];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howDidYouGetIdea: copiedList
                }
            }

            case 'remove isProductAspirin': {
                const copiedList = [...state.isProductAspirin];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isProductAspirin: copiedList
                }
            }

            case 'remove isProductVitamin': {
                const copiedList = [...state.isProductVitamin];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isProductVitamin: copiedList
                }
            }

            case 'remove factorsImportantInChoosing': {
                const copiedList = [...state.factorsImportantInChoosing];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    factorsImportantInChoosing: copiedList
                }
            }

            case 'remove howYouRespondToNeed': {
                const copiedList = [...state.howYouRespondToNeed];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howYouRespondToNeed: copiedList
                }
            }

            case 'remove doCustomersLoveProduct': {
                const copiedList = [...state.doCustomersLoveProduct];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doCustomersLoveProduct: copiedList
                }
            }

            // Update fields
            case 'update customerGain': {
                const index = action.index;
                const copiedList = [...state.customerGain];
                copiedList[index] = action.value;
                return { ...state, customerGain: copiedList }
            }

            case 'update howProductMeetNeeds': {
                const index = action.index;
                const copiedList = [...state.howProductMeetNeeds];
                copiedList[index] = action.value;
                return { ...state, howProductMeetNeeds: copiedList }
            }

            case 'update willProductSolveNeeds': {
                const index = action.index;
                const copiedList = [...state.willProductSolveNeeds];
                copiedList[index] = action.value;
                return { ...state, willProductSolveNeeds: copiedList }
            }

            case 'update howDidYouGetIdea': {
                const index = action.index;
                const copiedList = [...state.howDidYouGetIdea];
                copiedList[index] = action.value;
                return { ...state, howDidYouGetIdea: copiedList }
            }

            case 'update isProductAspirin': {
                const index = action.index;
                const copiedList = [...state.isProductAspirin];
                copiedList[index] = action.value;
                return { ...state, isProductAspirin: copiedList }
            }

            case 'update isProductVitamin': {
                const index = action.index;
                const copiedList = [...state.isProductVitamin];
                copiedList[index] = action.value;
                return { ...state, isProductVitamin: copiedList }
            }

            case 'update factorsImportantInChoosing': {
                const index = action.index;
                const copiedList = [...state.factorsImportantInChoosing];
                copiedList[index] = action.value;
                return { ...state, factorsImportantInChoosing: copiedList }
            }

            case 'update howYouRespondToNeed': {
                const index = action.index;
                const copiedList = [...state.howYouRespondToNeed];
                copiedList[index] = action.value;
                return { ...state, howYouRespondToNeed: copiedList }
            }

            case 'update doCustomersLoveProduct': {
                const index = action.index;
                const copiedList = [...state.doCustomersLoveProduct];
                copiedList[index] = action.value;
                return { ...state, doCustomersLoveProduct: copiedList }
            }

            // Market Tab
            // Add fields
            case 'add customerSegments':
                return {
                    ...state,
                    customerSegments: [
                        ...state.customerSegments,
                        action.value
                    ]
                };

            case 'add marketSegments':
                return {
                    ...state,
                    marketSegments: [
                        ...state.marketSegments,
                        action.value
                    ]
                };

            case 'add problemProductSolves':
                return {
                    ...state,
                    problemProductSolves: [
                        ...state.problemProductSolves,
                        action.value
                    ]
                };

            case 'add doesProductMakeLivesBetter':
                return {
                    ...state,
                    doesProductMakeLivesBetter: [
                        ...state.doesProductMakeLivesBetter,
                        action.value
                    ]
                };

            case 'add marketTiming':
                return {
                    ...state,
                    marketTiming: [
                        ...state.marketTiming,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove customerSegments': {
                const copiedList = [...state.customerSegments];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerSegments: copiedList
                }
            }

            case 'remove marketSegments': {
                const copiedList = [...state.marketSegments];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketSegments: copiedList
                }
            }

            case 'remove problemProductSolves': {
                const copiedList = [...state.problemProductSolves];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    problemProductSolves: copiedList
                }
            }

            case 'remove doesProductMakeLivesBetter': {
                const copiedList = [...state.doesProductMakeLivesBetter];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doesProductMakeLivesBetter: copiedList
                }
            }

            case 'remove marketTiming': {
                const copiedList = [...state.marketTiming];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketTiming: copiedList
                }
            }

            // Update fields
            case 'update customerSegments': {
                const index = action.index;
                const copiedList = [...state.customerSegments];
                copiedList[index] = action.value;
                return { ...state, customerSegments: copiedList }
            }

            case 'update marketSegments': {
                const index = action.index;
                const copiedList = [...state.marketSegments];
                copiedList[index] = action.value;
                return { ...state, marketSegments: copiedList }
            }

            case 'update problemProductSolves': {
                const index = action.index;
                const copiedList = [...state.problemProductSolves];
                copiedList[index] = action.value;
                return { ...state, problemProductSolves: copiedList }
            }

            case 'update doesProductMakeLivesBetter': {
                const index = action.index;
                const copiedList = [...state.doesProductMakeLivesBetter];
                copiedList[index] = action.value;
                return { ...state, doesProductMakeLivesBetter: copiedList }
            }

            case 'update marketTiming': {
                const index = action.index;
                const copiedList = [...state.marketTiming];
                copiedList[index] = action.value;
                return { ...state, marketTiming: copiedList }
            }

            // Competition Tab
            // Add fields
            case 'add directCompetitors':
                return {
                    ...state,
                    directCompetitors: [
                        ...state.directCompetitors,
                        action.value
                    ]
                };

            case 'add similarCompanies':
                return {
                    ...state,
                    similarCompanies: [
                        ...state.similarCompanies,
                        action.value
                    ]
                };

            case 'add productsAndServices':
                return {
                    ...state,
                    productsAndServices: [
                        ...state.productsAndServices,
                        action.value
                    ]
                };

            case 'add marketShare':
                return {
                    ...state,
                    marketShare: [
                        ...state.marketShare,
                        action.value
                    ]
                };

            case 'add strengthsAndWeaknesses':
                return {
                    ...state,
                    strengthsAndWeaknesses: [
                        ...state.strengthsAndWeaknesses,
                        action.value
                    ]
                };

            case 'add doCompetitorsEnjoyRecognition':
                return {
                    ...state,
                    doCompetitorsEnjoyRecognition: [
                        ...state.doCompetitorsEnjoyRecognition,
                        action.value
                    ]
                };

            case 'add marketingStrategies':
                return {
                    ...state,
                    marketingStrategies: [
                        ...state.marketingStrategies,
                        action.value
                    ]
                };

            case 'add keyFactorsToProfitability':
                return {
                    ...state,
                    keyFactorsToProfitability: [
                        ...state.keyFactorsToProfitability,
                        action.value
                    ]
                };

            case 'add competitiveDifferentiation':
                return {
                    ...state,
                    competitiveDifferentiation: [
                        ...state.competitiveDifferentiation,
                        action.value
                    ]
                };

            case 'add whyValuePropositionIsUnique':
                return {
                    ...state,
                    whyValuePropositionIsUnique: [
                        ...state.whyValuePropositionIsUnique,
                        action.value
                    ]
                };

            case 'add howYourProductIsBetter':
                return {
                    ...state,
                    howYourProductIsBetter: [
                        ...state.howYourProductIsBetter,
                        action.value
                    ]
                };

            case 'add differentiationsFromCompetitorsProduct':
                return {
                    ...state,
                    differentiationsFromCompetitorsProduct: [
                        ...state.differentiationsFromCompetitorsProduct,
                        action.value
                    ]
                };

            case 'add competitiveOutlookForIndustry':
                return {
                    ...state,
                    competitiveOutlookForIndustry: [
                        ...state.competitiveOutlookForIndustry,
                        action.value
                    ]
                };

            case 'add howCompetitorsThreaten':
                return {
                    ...state,
                    howCompetitorsThreaten: [
                        ...state.howCompetitorsThreaten,
                        action.value
                    ]
                };

            case 'add willCompetitorsBlock':
                return {
                    ...state,
                    willCompetitorsBlock: [
                        ...state.willCompetitorsBlock,
                        action.value
                    ]
                };

            case 'add willCompetitorsPoach':
                return {
                    ...state,
                    willCompetitorsPoach: [
                        ...state.willCompetitorsPoach,
                        action.value
                    ]
                };

            case 'add whoElseMightExploit':
                return {
                    ...state,
                    whoElseMightExploit: [
                        ...state.whoElseMightExploit,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove directCompetitors': {
                const copiedList = [...state.directCompetitors];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    directCompetitors: copiedList
                }
            }

            case 'remove similarCompanies': {
                const copiedList = [...state.similarCompanies];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    similarCompanies: copiedList
                }
            }

            case 'remove productsAndServices': {
                const copiedList = [...state.productsAndServices];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    productsAndServices: copiedList
                }
            }

            case 'remove marketShare': {
                const copiedList = [...state.marketShare];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketShare: copiedList
                }
            }

            case 'remove strengthsAndWeaknesses': {
                const copiedList = [...state.strengthsAndWeaknesses];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    strengthsAndWeaknesses: copiedList
                }
            }

            case 'remove doCompetitorsEnjoyRecognition': {
                const copiedList = [...state.doCompetitorsEnjoyRecognition];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doCompetitorsEnjoyRecognition: copiedList
                }
            }

            case 'remove marketingStrategies': {
                const copiedList = [...state.marketingStrategies];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketingStrategies: copiedList
                }
            }

            case 'remove keyFactorsToProfitability': {
                const copiedList = [...state.keyFactorsToProfitability];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    keyFactorsToProfitability: copiedList
                }
            }

            case 'remove competitiveDifferentiation': {
                const copiedList = [...state.competitiveDifferentiation];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    competitiveDifferentiation: copiedList
                }
            }

            case 'remove whyValuePropositionIsUnique': {
                const copiedList = [...state.whyValuePropositionIsUnique];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    whyValuePropositionIsUnique: copiedList
                }
            }

            case 'remove howYourProductIsBetter': {
                const copiedList = [...state.howYourProductIsBetter];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howYourProductIsBetter: copiedList
                }
            }

            case 'remove differentiationsFromCompetitorsProduct': {
                const copiedList = [...state.differentiationsFromCompetitorsProduct];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    differentiationsFromCompetitorsProduct: copiedList
                }
            }

            case 'remove competitiveOutlookForIndustry': {
                const copiedList = [...state.competitiveOutlookForIndustry];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    competitiveOutlookForIndustry: copiedList
                }
            }

            case 'remove howCompetitorsThreaten': {
                const copiedList = [...state.howCompetitorsThreaten];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howCompetitorsThreaten: copiedList
                }
            }

            case 'remove willCompetitorsBlock': {
                const copiedList = [...state.willCompetitorsBlock];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    willCompetitorsBlock: copiedList
                }
            }

            case 'remove willCompetitorsPoach': {
                const copiedList = [...state.willCompetitorsPoach];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    willCompetitorsPoach: copiedList
                }
            }

            case 'remove whoElseMightExploit': {
                const copiedList = [...state.whoElseMightExploit];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    whoElseMightExploit: copiedList
                }
            }

            // Update fields
            case 'update directCompetitors': {
                const index = action.index;
                const copiedList = [...state.directCompetitors];
                copiedList[index] = action.value;
                return { ...state, directCompetitors: copiedList }
            }

            case 'update similarCompanies': {
                const index = action.index;
                const copiedList = [...state.similarCompanies];
                copiedList[index] = action.value;
                return { ...state, similarCompanies: copiedList }
            }

            case 'update productsAndServices': {
                const index = action.index;
                const copiedList = [...state.productsAndServices];
                copiedList[index] = action.value;
                return { ...state, productsAndServices: copiedList }
            }

            case 'update marketShare': {
                const index = action.index;
                const copiedList = [...state.marketShare];
                copiedList[index] = action.value;
                return { ...state, marketShare: copiedList }
            }

            case 'update strengthsAndWeaknesses': {
                const index = action.index;
                const copiedList = [...state.strengthsAndWeaknesses];
                copiedList[index] = action.value;
                return { ...state, strengthsAndWeaknesses: copiedList }
            }

            case 'update doCompetitorsEnjoyRecognition': {
                const index = action.index;
                const copiedList = [...state.doCompetitorsEnjoyRecognition];
                copiedList[index] = action.value;
                return { ...state, doCompetitorsEnjoyRecognition: copiedList }
            }

            case 'update marketingStrategies': {
                const index = action.index;
                const copiedList = [...state.marketingStrategies];
                copiedList[index] = action.value;
                return { ...state, marketingStrategies: copiedList }
            }

            case 'update keyFactorsToProfitability': {
                const index = action.index;
                const copiedList = [...state.keyFactorsToProfitability];
                copiedList[index] = action.value;
                return { ...state, keyFactorsToProfitability: copiedList }
            }

            case 'update competitiveDifferentiation': {
                const index = action.index;
                const copiedList = [...state.competitiveDifferentiation];
                copiedList[index] = action.value;
                return { ...state, competitiveDifferentiation: copiedList }
            }

            case 'update whyValuePropositionIsUnique': {
                const index = action.index;
                const copiedList = [...state.whyValuePropositionIsUnique];
                copiedList[index] = action.value;
                return { ...state, whyValuePropositionIsUnique: copiedList }
            }

            case 'update howYourProductIsBetter': {
                const index = action.index;
                const copiedList = [...state.howYourProductIsBetter];
                copiedList[index] = action.value;
                return { ...state, howYourProductIsBetter: copiedList }
            }

            case 'update differentiationsFromCompetitorsProduct': {
                const index = action.index;
                const copiedList = [...state.differentiationsFromCompetitorsProduct];
                copiedList[index] = action.value;
                return { ...state, differentiationsFromCompetitorsProduct: copiedList }
            }

            case 'update competitiveOutlookForIndustry': {
                const index = action.index;
                const copiedList = [...state.competitiveOutlookForIndustry];
                copiedList[index] = action.value;
                return { ...state, competitiveOutlookForIndustry: copiedList }
            }

            case 'update howCompetitorsThreaten': {
                const index = action.index;
                const copiedList = [...state.howCompetitorsThreaten];
                copiedList[index] = action.value;
                return { ...state, howCompetitorsThreaten: copiedList }
            }

            case 'update willCompetitorsBlock': {
                const index = action.index;
                const copiedList = [...state.willCompetitorsBlock];
                copiedList[index] = action.value;
                return { ...state, willCompetitorsBlock: copiedList }
            }

            case 'update willCompetitorsPoach': {
                const index = action.index;
                const copiedList = [...state.willCompetitorsPoach];
                copiedList[index] = action.value;
                return { ...state, willCompetitorsPoach: copiedList }
            }

            case 'update whoElseMightExploit': {
                const index = action.index;
                const copiedList = [...state.whoElseMightExploit];
                copiedList[index] = action.value;
                return { ...state, whoElseMightExploit: copiedList }
            }

            // Operations Tab
            // Add fields
            case 'add kpiMetrics':
                return {
                    ...state,
                    kpiMetrics: [
                        ...state.kpiMetrics,
                        action.value
                    ]
                };

            case 'add metricsThatShowAchievement':
                return {
                    ...state,
                    metricsThatShowAchievement: [
                        ...state.metricsThatShowAchievement,
                        action.value
                    ]
                };

            case 'add growthRate':
                return {
                    ...state,
                    growthRate: [
                        ...state.growthRate,
                        action.value
                    ]
                };

            case 'add proofOfLatentDemand':
                return {
                    ...state,
                    proofOfLatentDemand: [
                        ...state.proofOfLatentDemand,
                        action.value
                    ]
                };

            case 'add isBusinessGrowing':
                return {
                    ...state,
                    isBusinessGrowing: [
                        ...state.isBusinessGrowing,
                        action.value
                    ]
                };

            case 'add isProductSelfPerpetuatingGrowth':
                return {
                    ...state,
                    isProductSelfPerpetuatingGrowth: [
                        ...state.isProductSelfPerpetuatingGrowth,
                        action.value
                    ]
                };

            case 'add ableToAccomplishWithLess':
                return {
                    ...state,
                    ableToAccomplishWithLess: [
                        ...state.ableToAccomplishWithLess,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove kpiMetrics': {
                const copiedList = [...state.kpiMetrics];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    kpiMetrics: copiedList
                }
            }

            case 'remove metricsThatShowAchievement': {
                const copiedList = [...state.metricsThatShowAchievement];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    metricsThatShowAchievement: copiedList
                }
            }

            case 'remove growthRate': {
                const copiedList = [...state.growthRate];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    growthRate: copiedList
                }
            }

            case 'remove proofOfLatentDemand': {
                const copiedList = [...state.proofOfLatentDemand];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    proofOfLatentDemand: copiedList
                }
            }

            case 'remove isBusinessGrowing': {
                const copiedList = [...state.isBusinessGrowing];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isBusinessGrowing: copiedList
                }
            }

            case 'remove isProductSelfPerpetuatingGrowth': {
                const copiedList = [...state.isProductSelfPerpetuatingGrowth];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isProductSelfPerpetuatingGrowth: copiedList
                }
            }

            case 'remove ableToAccomplishWithLess': {
                const copiedList = [...state.ableToAccomplishWithLess];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    ableToAccomplishWithLess: copiedList
                }
            }

            // Update fields
            case 'update kpiMetrics': {
                const index = action.index;
                const copiedList = [...state.kpiMetrics];
                copiedList[index] = action.value;
                return { ...state, kpiMetrics: copiedList }
            }

            case 'update metricsThatShowAchievement': {
                const index = action.index;
                const copiedList = [...state.metricsThatShowAchievement];
                copiedList[index] = action.value;
                return { ...state, metricsThatShowAchievement: copiedList }
            }

            case 'update growthRate': {
                const index = action.index;
                const copiedList = [...state.growthRate];
                copiedList[index] = action.value;
                return { ...state, growthRate: copiedList }
            }

            case 'update proofOfLatentDemand': {
                const index = action.index;
                const copiedList = [...state.proofOfLatentDemand];
                copiedList[index] = action.value;
                return { ...state, proofOfLatentDemand: copiedList }
            }

            case 'update isBusinessGrowing': {
                const index = action.index;
                const copiedList = [...state.isBusinessGrowing];
                copiedList[index] = action.value;
                return { ...state, isBusinessGrowing: copiedList }
            }

            case 'update isProductSelfPerpetuatingGrowth': {
                const index = action.index;
                const copiedList = [...state.isProductSelfPerpetuatingGrowth];
                copiedList[index] = action.value;
                return { ...state, isProductSelfPerpetuatingGrowth: copiedList }
            }

            case 'update ableToAccomplishWithLess': {
                const index = action.index;
                const copiedList = [...state.ableToAccomplishWithLess];
                copiedList[index] = action.value;
                return { ...state, ableToAccomplishWithLess: copiedList }
            }

            // Marketing Tab
            // Add fields
            case 'add goToMarketStrategy':
                return {
                    ...state,
                    goToMarketStrategy: [
                        ...state.goToMarketStrategy,
                        action.value
                    ]
                };

            case 'add doesBusinessModelSupportAcquisition':
                return {
                    ...state,
                    doesBusinessModelSupportAcquisition: [
                        ...state.doesBusinessModelSupportAcquisition,
                        action.value
                    ]
                };

            case 'add planningToAcquireCustomersThroughMarketing':
                return {
                    ...state,
                    planningToAcquireCustomersThroughMarketing: [
                        ...state.planningToAcquireCustomersThroughMarketing,
                        action.value
                    ]
                };

            case 'add howProductWillBeDistributed':
                return {
                    ...state,
                    howProductWillBeDistributed: [
                        ...state.howProductWillBeDistributed,
                        action.value
                    ]
                };

            case 'add distributionChannelsUsed':
                return {
                    ...state,
                    distributionChannelsUsed: [
                        ...state.distributionChannelsUsed,
                        action.value
                    ]
                };

            case 'add whenWhereWhyHowConsumersBuy':
                return {
                    ...state,
                    whenWhereWhyHowConsumersBuy: [
                        ...state.whenWhereWhyHowConsumersBuy,
                        action.value
                    ]
                };

            case 'add isProductTransactionOriented':
                return {
                    ...state,
                    isProductTransactionOriented: [
                        ...state.isProductTransactionOriented,
                        action.value
                    ]
                };

            case 'add isBuyingProcessRelationshipOriented':
                return {
                    ...state,
                    isBuyingProcessRelationshipOriented: [
                        ...state.isBuyingProcessRelationshipOriented,
                        action.value
                    ]
                };

            case 'add areYouPlanningToBuildDirectForce':
                return {
                    ...state,
                    areYouPlanningToBuildDirectForce: [
                        ...state.areYouPlanningToBuildDirectForce,
                        action.value
                    ]
                };

            case 'add howToCommunicateWithConsumers':
                return {
                    ...state,
                    howToCommunicateWithConsumers: [
                        ...state.howToCommunicateWithConsumers,
                        action.value
                    ]
                };

            case 'add willUseWordOfMouth':
                return {
                    ...state,
                    willUseWordOfMouth: [
                        ...state.willUseWordOfMouth,
                        action.value
                    ]
                };

            case 'add willOptForSalesPromotions':
                return {
                    ...state,
                    willOptForSalesPromotions: [
                        ...state.willOptForSalesPromotions,
                        action.value
                    ]
                };

            case 'add doDirectSalesMakeSense':
                return {
                    ...state,
                    doDirectSalesMakeSense: [
                        ...state.doDirectSalesMakeSense,
                        action.value
                    ]
                };

            case 'add isTraditionalBetter':
                return {
                    ...state,
                    isTraditionalBetter: [
                        ...state.isTraditionalBetter,
                        action.value
                    ]
                };

            case 'add doYouNeedToBuildBrandLoyalty':
                return {
                    ...state,
                    doYouNeedToBuildBrandLoyalty: [
                        ...state.doYouNeedToBuildBrandLoyalty,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove goToMarketStrategy': {
                const copiedList = [...state.goToMarketStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    goToMarketStrategy: copiedList
                }
            }

            case 'remove doesBusinessModelSupportAcquisition': {
                const copiedList = [...state.doesBusinessModelSupportAcquisition];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doesBusinessModelSupportAcquisition: copiedList
                }
            }

            case 'remove planningToAcquireCustomersThroughMarketing': {
                const copiedList = [...state.planningToAcquireCustomersThroughMarketing];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    planningToAcquireCustomersThroughMarketing: copiedList
                }
            }

            case 'remove howProductWillBeDistributed': {
                const copiedList = [...state.howProductWillBeDistributed];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howProductWillBeDistributed: copiedList
                }
            }

            case 'remove distributionChannelsUsed': {
                const copiedList = [...state.distributionChannelsUsed];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    distributionChannelsUsed: copiedList
                }
            }

            case 'remove whenWhereWhyHowConsumersBuy': {
                const copiedList = [...state.whenWhereWhyHowConsumersBuy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    whenWhereWhyHowConsumersBuy: copiedList
                }
            }

            case 'remove isProductTransactionOriented': {
                const copiedList = [...state.isProductTransactionOriented];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isProductTransactionOriented: copiedList
                }
            }

            case 'remove isBuyingProcessRelationshipOriented': {
                const copiedList = [...state.isBuyingProcessRelationshipOriented];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isBuyingProcessRelationshipOriented: copiedList
                }
            }

            case 'remove areYouPlanningToBuildDirectForce': {
                const copiedList = [...state.areYouPlanningToBuildDirectForce];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    areYouPlanningToBuildDirectForce: copiedList
                }
            }

            case 'remove howToCommunicateWithConsumers': {
                const copiedList = [...state.howToCommunicateWithConsumers];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howToCommunicateWithConsumers: copiedList
                }
            }

            case 'remove willUseWordOfMouth': {
                const copiedList = [...state.willUseWordOfMouth];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    willUseWordOfMouth: copiedList
                }
            }

            case 'remove willOptForSalesPromotions': {
                const copiedList = [...state.willOptForSalesPromotions];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    willOptForSalesPromotions: copiedList
                }
            }

            case 'remove doDirectSalesMakeSense': {
                const copiedList = [...state.doDirectSalesMakeSense];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doDirectSalesMakeSense: copiedList
                }
            }

            case 'remove isTraditionalBetter': {
                const copiedList = [...state.isTraditionalBetter];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isTraditionalBetter: copiedList
                }
            }

            case 'remove doYouNeedToBuildBrandLoyalty': {
                const copiedList = [...state.doYouNeedToBuildBrandLoyalty];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doYouNeedToBuildBrandLoyalty: copiedList
                }
            }

            // Update fields
            case 'update goToMarketStrategy': {
                const index = action.index;
                const copiedList = [...state.goToMarketStrategy];
                copiedList[index] = action.value;
                return { ...state, goToMarketStrategy: copiedList }
            }

            case 'update doesBusinessModelSupportAcquisition': {
                const index = action.index;
                const copiedList = [...state.doesBusinessModelSupportAcquisition];
                copiedList[index] = action.value;
                return { ...state, doesBusinessModelSupportAcquisition: copiedList }
            }

            case 'update planningToAcquireCustomersThroughMarketing': {
                const index = action.index;
                const copiedList = [...state.planningToAcquireCustomersThroughMarketing];
                copiedList[index] = action.value;
                return { ...state, planningToAcquireCustomersThroughMarketing: copiedList }
            }

            case 'update howProductWillBeDistributed': {
                const index = action.index;
                const copiedList = [...state.howProductWillBeDistributed];
                copiedList[index] = action.value;
                return { ...state, howProductWillBeDistributed: copiedList }
            }

            case 'update distributionChannelsUsed': {
                const index = action.index;
                const copiedList = [...state.distributionChannelsUsed];
                copiedList[index] = action.value;
                return { ...state, distributionChannelsUsed: copiedList }
            }

            case 'update whenWhereWhyHowConsumersBuy': {
                const index = action.index;
                const copiedList = [...state.whenWhereWhyHowConsumersBuy];
                copiedList[index] = action.value;
                return { ...state, whenWhereWhyHowConsumersBuy: copiedList }
            }

            case 'update isProductTransactionOriented': {
                const index = action.index;
                const copiedList = [...state.isProductTransactionOriented];
                copiedList[index] = action.value;
                return { ...state, isProductTransactionOriented: copiedList }
            }

            case 'update isBuyingProcessRelationshipOriented': {
                const index = action.index;
                const copiedList = [...state.isBuyingProcessRelationshipOriented];
                copiedList[index] = action.value;
                return { ...state, isBuyingProcessRelationshipOriented: copiedList }
            }

            case 'update areYouPlanningToBuildDirectForce': {
                const index = action.index;
                const copiedList = [...state.areYouPlanningToBuildDirectForce];
                copiedList[index] = action.value;
                return { ...state, areYouPlanningToBuildDirectForce: copiedList }
            }

            case 'update howToCommunicateWithConsumers': {
                const index = action.index;
                const copiedList = [...state.howToCommunicateWithConsumers];
                copiedList[index] = action.value;
                return { ...state, howToCommunicateWithConsumers: copiedList }
            }

            case 'update willUseWordOfMouth': {
                const index = action.index;
                const copiedList = [...state.willUseWordOfMouth];
                copiedList[index] = action.value;
                return { ...state, willUseWordOfMouth: copiedList }
            }

            case 'update willOptForSalesPromotions': {
                const index = action.index;
                const copiedList = [...state.willOptForSalesPromotions];
                copiedList[index] = action.value;
                return { ...state, willOptForSalesPromotions: copiedList }
            }

            case 'update doDirectSalesMakeSense': {
                const index = action.index;
                const copiedList = [...state.doDirectSalesMakeSense];
                copiedList[index] = action.value;
                return { ...state, doDirectSalesMakeSense: copiedList }
            }

            case 'update isTraditionalBetter': {
                const index = action.index;
                const copiedList = [...state.isTraditionalBetter];
                copiedList[index] = action.value;
                return { ...state, isTraditionalBetter: copiedList }
            }

            case 'update doYouNeedToBuildBrandLoyalty': {
                const index = action.index;
                const copiedList = [...state.doYouNeedToBuildBrandLoyalty];
                copiedList[index] = action.value;
                return { ...state, doYouNeedToBuildBrandLoyalty: copiedList }
            }

            // Financials Tab
            // Add fields
            case 'add monetizationModel':
                return {
                    ...state,
                    monetizationModel: [
                        ...state.monetizationModel,
                        action.value
                    ]
                };

            case 'add areYouBuildingAnAnnuityBusiness':
                return {
                    ...state,
                    areYouBuildingAnAnnuityBusiness: [
                        ...state.areYouBuildingAnAnnuityBusiness,
                        action.value
                    ]
                };

            case 'add areYouSellingDurableProduct':
                return {
                    ...state,
                    areYouSellingDurableProduct: [
                        ...state.areYouSellingDurableProduct,
                        action.value
                    ]
                };

            case 'add financialMargins':
                return {
                    ...state,
                    financialMargins: [
                        ...state.financialMargins,
                        action.value
                    ]
                };

            case 'add observedUnitEconomics':
                return {
                    ...state,
                    observedUnitEconomics: [
                        ...state.observedUnitEconomics,
                        action.value
                    ]
                };

            case 'add doUnitEconomicsMakeSense':
                return {
                    ...state,
                    doUnitEconomicsMakeSense: [
                        ...state.doUnitEconomicsMakeSense,
                        action.value
                    ]
                };

            case 'add pricingModel':
                return {
                    ...state,
                    pricingModel: [
                        ...state.pricingModel,
                        action.value
                    ]
                };

            case 'add pricingModelCustomerDemand':
                return {
                    ...state,
                    pricingModelCustomerDemand: [
                        ...state.pricingModelCustomerDemand,
                        action.value
                    ]
                };

            case 'add pricePointProductOffer':
                return {
                    ...state,
                    pricePointProductOffer: [
                        ...state.pricePointProductOffer,
                        action.value
                    ]
                };

            case 'add isPriceEstablished':
                return {
                    ...state,
                    isPriceEstablished: [
                        ...state.isPriceEstablished,
                        action.value
                    ]
                };

            case 'add relevantPricingStrategy':
                return {
                    ...state,
                    relevantPricingStrategy: [
                        ...state.relevantPricingStrategy,
                        action.value
                    ]
                };

            case 'add appropriatePricingStrategy':
                return {
                    ...state,
                    appropriatePricingStrategy: [
                        ...state.appropriatePricingStrategy,
                        action.value
                    ]
                };

            case 'add financialRevenue':
                return {
                    ...state,
                    financialRevenue: [
                        ...state.financialRevenue,
                        action.value
                    ]
                };

            case 'add moneyMadeFromTargetMarket':
                return {
                    ...state,
                    moneyMadeFromTargetMarket: [
                        ...state.moneyMadeFromTargetMarket,
                        action.value
                    ]
                };

            case 'add whenIsBusinessExpectedToMakeMoney':
                return {
                    ...state,
                    whenIsBusinessExpectedToMakeMoney: [
                        ...state.whenIsBusinessExpectedToMakeMoney,
                        action.value
                    ]
                };

            case 'add durationToMakeMoney':
                return {
                    ...state,
                    durationToMakeMoney: [
                        ...state.durationToMakeMoney,
                        action.value
                    ]
                };

            case 'add whenIsBusinessExpectedToReachBreakEven':
                return {
                    ...state,
                    whenIsBusinessExpectedToReachBreakEven: [
                        ...state.whenIsBusinessExpectedToReachBreakEven,
                        action.value
                    ]
                };

            case 'add anticipateToBreakEven':
                return {
                    ...state,
                    anticipateToBreakEven: [
                        ...state.anticipateToBreakEven,
                        action.value
                    ]
                };

            case 'add salesTargetBasedOnProjectedGrowth':
                return {
                    ...state,
                    salesTargetBasedOnProjectedGrowth: [
                        ...state.salesTargetBasedOnProjectedGrowth,
                        action.value
                    ]
                };

            case 'add howLongToReachNextSalesMilestone':
                return {
                    ...state,
                    howLongToReachNextSalesMilestone: [
                        ...state.howLongToReachNextSalesMilestone,
                        action.value
                    ]
                };

            case 'add costAcquiringCustomer':
                return {
                    ...state,
                    costAcquiringCustomer: [
                        ...state.costAcquiringCustomer,
                        action.value
                    ]
                };

            case 'add howMuchWillCustomerBeWorth':
                return {
                    ...state,
                    howMuchWillCustomerBeWorth: [
                        ...state.howMuchWillCustomerBeWorth,
                        action.value
                    ]
                };

            case 'add internalComponentsAssumptions':
                return {
                    ...state,
                    internalComponentsAssumptions: [
                        ...state.internalComponentsAssumptions,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove monetizationModel': {
                const copiedList = [...state.monetizationModel];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    monetizationModel: copiedList
                }
            }

            case 'remove areYouBuildingAnAnnuityBusiness': {
                const copiedList = [...state.areYouBuildingAnAnnuityBusiness];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    areYouBuildingAnAnnuityBusiness: copiedList
                }
            }

            case 'remove areYouSellingDurableProduct': {
                const copiedList = [...state.areYouSellingDurableProduct];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    areYouSellingDurableProduct: copiedList
                }
            }

            case 'remove financialMargins': {
                const copiedList = [...state.financialMargins];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    financialMargins: copiedList
                }
            }

            case 'remove observedUnitEconomics': {
                const copiedList = [...state.observedUnitEconomics];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    observedUnitEconomics: copiedList
                }
            }

            case 'remove doUnitEconomicsMakeSense': {
                const copiedList = [...state.doUnitEconomicsMakeSense];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    doUnitEconomicsMakeSense: copiedList
                }
            }

            case 'remove pricingModel': {
                const copiedList = [...state.pricingModel];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    pricingModel: copiedList
                }
            }

            case 'remove pricingModelCustomerDemand': {
                const copiedList = [...state.pricingModelCustomerDemand];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    pricingModelCustomerDemand: copiedList
                }
            }

            case 'remove pricePointProductOffer': {
                const copiedList = [...state.pricePointProductOffer];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    pricePointProductOffer: copiedList
                }
            }

            case 'remove isPriceEstablished': {
                const copiedList = [...state.isPriceEstablished];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    isPriceEstablished: copiedList
                }
            }

            case 'remove relevantPricingStrategy': {
                const copiedList = [...state.relevantPricingStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    relevantPricingStrategy: copiedList
                }
            }

            case 'remove appropriatePricingStrategy': {
                const copiedList = [...state.appropriatePricingStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    appropriatePricingStrategy: copiedList
                }
            }

            case 'remove financialRevenue': {
                const copiedList = [...state.financialRevenue];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    financialRevenue: copiedList
                }
            }

            case 'remove moneyMadeFromTargetMarket': {
                const copiedList = [...state.moneyMadeFromTargetMarket];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    moneyMadeFromTargetMarket: copiedList
                }
            }

            case 'remove whenIsBusinessExpectedToMakeMoney': {
                const copiedList = [...state.whenIsBusinessExpectedToMakeMoney];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    whenIsBusinessExpectedToMakeMoney: copiedList
                }
            }

            case 'remove durationToMakeMoney': {
                const copiedList = [...state.durationToMakeMoney];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    durationToMakeMoney: copiedList
                }
            }

            case 'remove whenIsBusinessExpectedToReachBreakEven': {
                const copiedList = [...state.whenIsBusinessExpectedToReachBreakEven];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    whenIsBusinessExpectedToReachBreakEven: copiedList
                }
            }

            case 'remove anticipateToBreakEven': {
                const copiedList = [...state.anticipateToBreakEven];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    anticipateToBreakEven: copiedList
                }
            }

            case 'remove salesTargetBasedOnProjectedGrowth': {
                const copiedList = [...state.salesTargetBasedOnProjectedGrowth];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    salesTargetBasedOnProjectedGrowth: copiedList
                }
            }

            case 'remove howLongToReachNextSalesMilestone': {
                const copiedList = [...state.howLongToReachNextSalesMilestone];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howLongToReachNextSalesMilestone: copiedList
                }
            }

            case 'remove costAcquiringCustomer': {
                const copiedList = [...state.costAcquiringCustomer];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    costAcquiringCustomer: copiedList
                }
            }

            case 'remove howMuchWillCustomerBeWorth': {
                const copiedList = [...state.howMuchWillCustomerBeWorth];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    howMuchWillCustomerBeWorth: copiedList
                }
            }

            case 'remove internalComponentsAssumptions': {
                const copiedList = [...state.internalComponentsAssumptions];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    internalComponentsAssumptions: copiedList
                }
            }

            // Update fields
            case 'update monetizationModel': {
                const index = action.index;
                const copiedList = [...state.monetizationModel];
                copiedList[index] = action.value;
                return { ...state, monetizationModel: copiedList }
            }

            case 'update areYouBuildingAnAnnuityBusiness': {
                const index = action.index;
                const copiedList = [...state.areYouBuildingAnAnnuityBusiness];
                copiedList[index] = action.value;
                return { ...state, areYouBuildingAnAnnuityBusiness: copiedList }
            }

            case 'update areYouSellingDurableProduct': {
                const index = action.index;
                const copiedList = [...state.areYouSellingDurableProduct];
                copiedList[index] = action.value;
                return { ...state, areYouSellingDurableProduct: copiedList }
            }

            case 'update financialMargins': {
                const index = action.index;
                const copiedList = [...state.financialMargins];
                copiedList[index] = action.value;
                return { ...state, financialMargins: copiedList }
            }

            case 'update observedUnitEconomics': {
                const index = action.index;
                const copiedList = [...state.observedUnitEconomics];
                copiedList[index] = action.value;
                return { ...state, observedUnitEconomics: copiedList }
            }

            case 'update doUnitEconomicsMakeSense': {
                const index = action.index;
                const copiedList = [...state.doUnitEconomicsMakeSense];
                copiedList[index] = action.value;
                return { ...state, doUnitEconomicsMakeSense: copiedList }
            }

            case 'update pricingModel': {
                const index = action.index;
                const copiedList = [...state.pricingModel];
                copiedList[index] = action.value;
                return { ...state, pricingModel: copiedList }
            }

            case 'update pricingModelCustomerDemand': {
                const index = action.index;
                const copiedList = [...state.pricingModelCustomerDemand];
                copiedList[index] = action.value;
                return { ...state, pricingModelCustomerDemand: copiedList }
            }

            case 'update pricePointProductOffer': {
                const index = action.index;
                const copiedList = [...state.pricePointProductOffer];
                copiedList[index] = action.value;
                return { ...state, pricePointProductOffer: copiedList }
            }

            case 'update isPriceEstablished': {
                const index = action.index;
                const copiedList = [...state.isPriceEstablished];
                copiedList[index] = action.value;
                return { ...state, isPriceEstablished: copiedList }
            }

            case 'update relevantPricingStrategy': {
                const index = action.index;
                const copiedList = [...state.relevantPricingStrategy];
                copiedList[index] = action.value;
                return { ...state, relevantPricingStrategy: copiedList }
            }

            case 'update appropriatePricingStrategy': {
                const index = action.index;
                const copiedList = [...state.appropriatePricingStrategy];
                copiedList[index] = action.value;
                return { ...state, appropriatePricingStrategy: copiedList }
            }

            case 'update financialRevenue': {
                const index = action.index;
                const copiedList = [...state.financialRevenue];
                copiedList[index] = action.value;
                return { ...state, financialRevenue: copiedList }
            }

            case 'update moneyMadeFromTargetMarket': {
                const index = action.index;
                const copiedList = [...state.moneyMadeFromTargetMarket];
                copiedList[index] = action.value;
                return { ...state, moneyMadeFromTargetMarket: copiedList }
            }

            case 'update whenIsBusinessExpectedToMakeMoney': {
                const index = action.index;
                const copiedList = [...state.whenIsBusinessExpectedToMakeMoney];
                copiedList[index] = action.value;
                return { ...state, whenIsBusinessExpectedToMakeMoney: copiedList }
            }

            case 'update durationToMakeMoney': {
                const index = action.index;
                const copiedList = [...state.durationToMakeMoney];
                copiedList[index] = action.value;
                return { ...state, durationToMakeMoney: copiedList }
            }

            case 'update whenIsBusinessExpectedToReachBreakEven': {
                const index = action.index;
                const copiedList = [...state.whenIsBusinessExpectedToReachBreakEven];
                copiedList[index] = action.value;
                return { ...state, whenIsBusinessExpectedToReachBreakEven: copiedList }
            }

            case 'update anticipateToBreakEven': {
                const index = action.index;
                const copiedList = [...state.anticipateToBreakEven];
                copiedList[index] = action.value;
                return { ...state, anticipateToBreakEven: copiedList }
            }

            case 'update salesTargetBasedOnProjectedGrowth': {
                const index = action.index;
                const copiedList = [...state.salesTargetBasedOnProjectedGrowth];
                copiedList[index] = action.value;
                return { ...state, salesTargetBasedOnProjectedGrowth: copiedList }
            }

            case 'update howLongToReachNextSalesMilestone': {
                const index = action.index;
                const copiedList = [...state.howLongToReachNextSalesMilestone];
                copiedList[index] = action.value;
                return { ...state, howLongToReachNextSalesMilestone: copiedList }
            }

            case 'update costAcquiringCustomer': {
                const index = action.index;
                const copiedList = [...state.costAcquiringCustomer];
                copiedList[index] = action.value;
                return { ...state, costAcquiringCustomer: copiedList }
            }

            case 'update howMuchWillCustomerBeWorth': {
                const index = action.index;
                const copiedList = [...state.howMuchWillCustomerBeWorth];
                copiedList[index] = action.value;
                return { ...state, howMuchWillCustomerBeWorth: copiedList }
            }

            case 'update internalComponentsAssumptions': {
                const index = action.index;
                const copiedList = [...state.internalComponentsAssumptions];
                copiedList[index] = action.value;
                return { ...state, internalComponentsAssumptions: copiedList }
            }

            // income statement year 2
            case 'update revenueYear2': { return { ...state, revenueYear2: action.value } }
            case 'update costOfRevenueYear2': { return { ...state, costOfRevenueYear2: action.value } }
            case 'update grossProfitYear2': { return { ...state, grossProfitYear2: action.value } }
            case 'update researchAndDevelopmentYear2': { return { ...state, researchAndDevelopmentYear2: action.value } }
            case 'update salesAndMarketingYear2': { return { ...state, salesAndMarketingYear2: action.value } }
            case 'update generalAndAdministrativeYear2': { return { ...state, generalAndAdministrativeYear2: action.value } }
            case 'update totalOperatingExpensesYear2': { return { ...state, totalOperatingExpensesYear2: action.value } }
            case 'update operatingIncomeYear2': { return { ...state, operatingIncomeYear2: action.value } }
            case 'update interestExpenseYear2': { return { ...state, interestExpenseYear2: action.value } }
            case 'update otherIncomeNetYear2': { return { ...state, otherIncomeNetYear2: action.value } }
            case 'update incomeBeforeIncomeTaxesYear2': { return { ...state, incomeBeforeIncomeTaxesYear2: action.value } }
            case 'update provisionForIncomeTaxesYear2': { return { ...state, provisionForIncomeTaxesYear2: action.value } }
            case 'update netIncomeNetLossYear2': { return { ...state, netIncomeNetLossYear2: action.value } }

            // balance sheet year 2
            case 'update cashAndCashEquivalentsYear2': { return { ...state, cashAndCashEquivalentsYear2: action.value } }
            case 'update accountsReceivableNetYear2': { return { ...state, accountsReceivableNetYear2: action.value } }
            case 'update inventoryYear2': { return { ...state, inventoryYear2: action.value } }
            case 'update otherCurrentAssetsYear2': { return { ...state, otherCurrentAssetsYear2: action.value } }
            case 'update totalCurrentAssetsYear2': { return { ...state, totalCurrentAssetsYear2: action.value } }
            case 'update propertyPlantAndEquipmentNetYear2': { return { ...state, propertyPlantAndEquipmentNetYear2: action.value } }
            case 'update intangibleAssetsNetYear2': { return { ...state, intangibleAssetsNetYear2: action.value } }
            case 'update otherNonCurrentAssetsYear2': { return { ...state, otherNonCurrentAssetsYear2: action.value } }
            case 'update totalNonCurrentAssetsYear2': { return { ...state, totalNonCurrentAssetsYear2: action.value } }
            case 'update totalAssetsYear2': { return { ...state, totalAssetsYear2: action.value } }
            case 'update accountsPayableYear2': { return { ...state, accountsPayableYear2: action.value } }
            case 'update accruedAndOtherYear2': { return { ...state, accruedAndOtherYear2: action.value } }
            case 'update shortTermDebtYear2': { return { ...state, shortTermDebtYear2: action.value } }
            case 'update totalCurrentLiabilitiesYear2': { return { ...state, totalCurrentLiabilitiesYear2: action.value } }
            case 'update longTermDebtYear2': { return { ...state, longTermDebtYear2: action.value } }
            case 'update otherLiabilitiesYear2': { return { ...state, otherLiabilitiesYear2: action.value } }
            case 'update totalNonCurrentLiabilitiesYear2': { return { ...state, totalNonCurrentLiabilitiesYear2: action.value } }
            case 'update totalLiabilitiesYear2': { return { ...state, totalLiabilitiesYear2: action.value } }
            case 'update equityCapitalYear2': { return { ...state, equityCapitalYear2: action.value } }
            case 'update retainedEarningsYear2': { return { ...state, retainedEarningsYear2: action.value } }
            case 'update additionalPaidInCapitalYear2': { return { ...state, additionalPaidInCapitalYear2: action.value } }
            case 'update totalShareholdersEquityYear2': { return { ...state, totalShareholdersEquityYear2: action.value } }
            case 'update totalLiabilitiesAndShareholdersEquityYear2': { return { ...state, totalLiabilitiesAndShareholdersEquityYear2: action.value } }

            // cash flow statement year 2
            case 'update netIncomeYear2': { return { ...state, netIncomeYear2: action.value } }
            case 'update depreciationAndAmortizationYear2': { return { ...state, depreciationAndAmortizationYear2: action.value } }
            case 'update changesInAssetsAndLiabilitiesYear2': { return { ...state, changesInAssetsAndLiabilitiesYear2: action.value } }
            case 'update netCashProvidedByOperatingActivitiesYear2': { return { ...state, netCashProvidedByOperatingActivitiesYear2: action.value } }
            case 'update capitalExpenditureYear2': { return { ...state, capitalExpenditureYear2: action.value } }
            case 'update capitalizedSoftwareDevelopmentCostsYear2': { return { ...state, capitalizedSoftwareDevelopmentCostsYear2: action.value } }
            case 'update maturitiesAndSalesOfInvestmentsYear2': { return { ...state, maturitiesAndSalesOfInvestmentsYear2: action.value } }
            case 'update cashPaidForAcquisitionOfBusinessYear2': { return { ...state, cashPaidForAcquisitionOfBusinessYear2: action.value } }
            case 'update netCashProvidedByInvestingActivitiesYear2': { return { ...state, netCashProvidedByInvestingActivitiesYear2: action.value } }
            case 'update proceedsFromIssuanceOfDebtYear2': { return { ...state, proceedsFromIssuanceOfDebtYear2: action.value } }
            case 'update proceedsFromIssuanceOfEquityYear2': { return { ...state, proceedsFromIssuanceOfEquityYear2: action.value } }
            case 'update debtRelatedCostsAndOtherNetYear2': { return { ...state, debtRelatedCostsAndOtherNetYear2: action.value } }
            case 'update netCashProvidedByFinancingActivitiesYear2': { return { ...state, netCashProvidedByFinancingActivitiesYear2: action.value } }
            case 'update changesInCashDuringPeriodYear2': { return { ...state, changesInCashDuringPeriodYear2: action.value } }
            case 'update cashAtBeginningOfPeriodYear2': { return { ...state, cashAtBeginningOfPeriodYear2: action.value } }
            case 'update cashAtEndOfPeriodYear2': { return { ...state, cashAtEndOfPeriodYear2: action.value } }

            // break even analysis year 2
            case 'update timeFrameYear2': { return { ...state, timeFrameYear2: action.value } }
            case 'update averagePriceSoldYear2': { return { ...state, averagePriceSoldYear2: action.value } }
            case 'update averageCostToMakeOrDeliverYear2': { return { ...state, averageCostToMakeOrDeliverYear2: action.value } }
            case 'update fixedCostsforMonthOrYearYear2': { return { ...state, fixedCostsforMonthOrYearYear2: action.value } }
            case 'update percentageOfPriceProfitYear2': { return { ...state, percentageOfPriceProfitYear2: action.value } }
            case 'update totalSalesNeededToBreakEvenYear2': { return { ...state, totalSalesNeededToBreakEvenYear2: action.value } }
            case 'update numberOfUnitsSoldNeededToBreakEvenYear2': { return { ...state, numberOfUnitsSoldNeededToBreakEvenYear2: action.value } }

            // financial ratios analysis year 2
            case 'update profitMarginYear2': { return { ...state, profitMarginYear2: action.value } }
            case 'update returnOnEquityAfterTaxYear2': { return { ...state, returnOnEquityAfterTaxYear2: action.value } }
            case 'update returnOnAssetsYear2': { return { ...state, returnOnAssetsYear2: action.value } }
            case 'update grossMarginYear2': { return { ...state, grossMarginYear2: action.value } }
            case 'update grossProfitMarginYear2': { return { ...state, grossProfitMarginYear2: action.value } }
            case 'update netProfitMarginYear2': { return { ...state, netProfitMarginYear2: action.value } }
            case 'update operatingMarginYear2': { return { ...state, operatingMarginYear2: action.value } }
            case 'update currentRatioYear2': { return { ...state, currentRatioYear2: action.value } }
            case 'update quickRatioYear2': { return { ...state, quickRatioYear2: action.value } }
            case 'update cashRatioYear2': { return { ...state, cashRatioYear2: action.value } }
            case 'update inventoryTurnoverYear2': { return { ...state, inventoryTurnoverYear2: action.value } }
            case 'update assetTurnoverYear2': { return { ...state, assetTurnoverYear2: action.value } }
            case 'update receivablesTurnoverYear2': { return { ...state, receivablesTurnoverYear2: action.value } }
            case 'update ebitYear2': { return { ...state, ebitYear2: action.value } }
            case 'update ebitdaYear2': { return { ...state, ebitdaYear2: action.value } }
            case 'update debtRatioYear2': { return { ...state, debtRatioYear2: action.value } }
            case 'update debtToEquityRatioYear2': { return { ...state, debtToEquityRatioYear2: action.value } }
            case 'update interestCoverageRatioYear2': { return { ...state, interestCoverageRatioYear2: action.value } }
            case 'update earningsPerShareBasicYear2': { return { ...state, earningsPerShareBasicYear2: action.value } }
            case 'update earningsPerShareDilutedYear2': { return { ...state, earningsPerShareDilutedYear2: action.value } }
            case 'update dividendPayoutRatioYear2': { return { ...state, dividendPayoutRatioYear2: action.value } }
            case 'update priceToEarningsRatioYear2': { return { ...state, priceToEarningsRatioYear2: action.value } }

            // income statement year 1
            case 'update revenueYear1': { return { ...state, revenueYear1: action.value } }
            case 'update costOfRevenueYear1': { return { ...state, costOfRevenueYear1: action.value } }
            case 'update grossProfitYear1': { return { ...state, grossProfitYear1: action.value } }
            case 'update researchAndDevelopmentYear1': { return { ...state, researchAndDevelopmentYear1: action.value } }
            case 'update salesAndMarketingYear1': { return { ...state, salesAndMarketingYear1: action.value } }
            case 'update generalAndAdministrativeYear1': { return { ...state, generalAndAdministrativeYear1: action.value } }
            case 'update totalOperatingExpensesYear1': { return { ...state, totalOperatingExpensesYear1: action.value } }
            case 'update operatingIncomeYear1': { return { ...state, operatingIncomeYear1: action.value } }
            case 'update interestExpenseYear1': { return { ...state, interestExpenseYear1: action.value } }
            case 'update otherIncomeNetYear1': { return { ...state, otherIncomeNetYear1: action.value } }
            case 'update incomeBeforeIncomeTaxesYear1': { return { ...state, incomeBeforeIncomeTaxesYear1: action.value } }
            case 'update provisionForIncomeTaxesYear1': { return { ...state, provisionForIncomeTaxesYear1: action.value } }
            case 'update netIncomeNetLossYear1': { return { ...state, netIncomeNetLossYear1: action.value } }

            // balance sheet year 1
            case 'update cashAndCashEquivalentsYear1': { return { ...state, cashAndCashEquivalentsYear1: action.value } }
            case 'update accountsReceivableNetYear1': { return { ...state, accountsReceivableNetYear1: action.value } }
            case 'update inventoryYear1': { return { ...state, inventoryYear1: action.value } }
            case 'update otherCurrentAssetsYear1': { return { ...state, otherCurrentAssetsYear1: action.value } }
            case 'update totalCurrentAssetsYear1': { return { ...state, totalCurrentAssetsYear1: action.value } }
            case 'update propertyPlantAndEquipmentNetYear1': { return { ...state, propertyPlantAndEquipmentNetYear1: action.value } }
            case 'update intangibleAssetsNetYear1': { return { ...state, intangibleAssetsNetYear1: action.value } }
            case 'update otherNonCurrentAssetsYear1': { return { ...state, otherNonCurrentAssetsYear1: action.value } }
            case 'update totalNonCurrentAssetsYear1': { return { ...state, totalNonCurrentAssetsYear1: action.value } }
            case 'update totalAssetsYear1': { return { ...state, totalAssetsYear1: action.value } }
            case 'update accountsPayableYear1': { return { ...state, accountsPayableYear1: action.value } }
            case 'update accruedAndOtherYear1': { return { ...state, accruedAndOtherYear1: action.value } }
            case 'update shortTermDebtYear1': { return { ...state, shortTermDebtYear1: action.value } }
            case 'update totalCurrentLiabilitiesYear1': { return { ...state, totalCurrentLiabilitiesYear1: action.value } }
            case 'update longTermDebtYear1': { return { ...state, longTermDebtYear1: action.value } }
            case 'update otherLiabilitiesYear1': { return { ...state, otherLiabilitiesYear1: action.value } }
            case 'update totalNonCurrentLiabilitiesYear1': { return { ...state, totalNonCurrentLiabilitiesYear1: action.value } }
            case 'update totalLiabilitiesYear1': { return { ...state, totalLiabilitiesYear1: action.value } }
            case 'update equityCapitalYear1': { return { ...state, equityCapitalYear1: action.value } }
            case 'update retainedEarningsYear1': { return { ...state, retainedEarningsYear1: action.value } }
            case 'update additionalPaidInCapitalYear1': { return { ...state, additionalPaidInCapitalYear1: action.value } }
            case 'update totalShareholdersEquityYear1': { return { ...state, totalShareholdersEquityYear1: action.value } }
            case 'update totalLiabilitiesAndShareholdersEquityYear1': { return { ...state, totalLiabilitiesAndShareholdersEquityYear1: action.value } }

            // cash flow statement year 1
            case 'update netIncomeYear1': { return { ...state, netIncomeYear1: action.value } }
            case 'update depreciationAndAmortizationYear1': { return { ...state, depreciationAndAmortizationYear1: action.value } }
            case 'update changesInAssetsAndLiabilitiesYear1': { return { ...state, changesInAssetsAndLiabilitiesYear1: action.value } }
            case 'update netCashProvidedByOperatingActivitiesYear1': { return { ...state, netCashProvidedByOperatingActivitiesYear1: action.value } }
            case 'update capitalExpenditureYear1': { return { ...state, capitalExpenditureYear1: action.value } }
            case 'update capitalizedSoftwareDevelopmentCostsYear1': { return { ...state, capitalizedSoftwareDevelopmentCostsYear1: action.value } }
            case 'update maturitiesAndSalesOfInvestmentsYear1': { return { ...state, maturitiesAndSalesOfInvestmentsYear1: action.value } }
            case 'update cashPaidForAcquisitionOfBusinessYear1': { return { ...state, cashPaidForAcquisitionOfBusinessYear1: action.value } }
            case 'update netCashProvidedByInvestingActivitiesYear1': { return { ...state, netCashProvidedByInvestingActivitiesYear1: action.value } }
            case 'update proceedsFromIssuanceOfDebtYear1': { return { ...state, proceedsFromIssuanceOfDebtYear1: action.value } }
            case 'update proceedsFromIssuanceOfEquityYear1': { return { ...state, proceedsFromIssuanceOfEquityYear1: action.value } }
            case 'update debtRelatedCostsAndOtherNetYear1': { return { ...state, debtRelatedCostsAndOtherNetYear1: action.value } }
            case 'update netCashProvidedByFinancingActivitiesYear1': { return { ...state, netCashProvidedByFinancingActivitiesYear1: action.value } }
            case 'update changesInCashDuringPeriodYear1': { return { ...state, changesInCashDuringPeriodYear1: action.value } }
            case 'update cashAtBeginningOfPeriodYear1': { return { ...state, cashAtBeginningOfPeriodYear1: action.value } }
            case 'update cashAtEndOfPeriodYear1': { return { ...state, cashAtEndOfPeriodYear1: action.value } }

            // break even analysis year 1
            case 'update timeFrameYear1': { return { ...state, timeFrameYear1: action.value } }
            case 'update averagePriceSoldYear1': { return { ...state, averagePriceSoldYear1: action.value } }
            case 'update averageCostToMakeOrDeliverYear1': { return { ...state, averageCostToMakeOrDeliverYear1: action.value } }
            case 'update fixedCostsforMonthOrYearYear1': { return { ...state, fixedCostsforMonthOrYearYear1: action.value } }
            case 'update percentageOfPriceProfitYear1': { return { ...state, percentageOfPriceProfitYear1: action.value } }
            case 'update totalSalesNeededToBreakEvenYear1': { return { ...state, totalSalesNeededToBreakEvenYear1: action.value } }
            case 'update numberOfUnitsSoldNeededToBreakEvenYear1': { return { ...state, numberOfUnitsSoldNeededToBreakEvenYear1: action.value } }

            // financial ratios analysis year 1
            case 'update profitMarginYear1': { return { ...state, profitMarginYear1: action.value } }
            case 'update returnOnEquityAfterTaxYear1': { return { ...state, returnOnEquityAfterTaxYear1: action.value } }
            case 'update returnOnAssetsYear1': { return { ...state, returnOnAssetsYear1: action.value } }
            case 'update grossMarginYear1': { return { ...state, grossMarginYear1: action.value } }
            case 'update grossProfitMarginYear1': { return { ...state, grossProfitMarginYear1: action.value } }
            case 'update netProfitMarginYear1': { return { ...state, netProfitMarginYear1: action.value } }
            case 'update operatingMarginYear1': { return { ...state, operatingMarginYear1: action.value } }
            case 'update currentRatioYear1': { return { ...state, currentRatioYear1: action.value } }
            case 'update quickRatioYear1': { return { ...state, quickRatioYear1: action.value } }
            case 'update cashRatioYear1': { return { ...state, cashRatioYear1: action.value } }
            case 'update inventoryTurnoverYear1': { return { ...state, inventoryTurnoverYear1: action.value } }
            case 'update assetTurnoverYear1': { return { ...state, assetTurnoverYear1: action.value } }
            case 'update receivablesTurnoverYear1': { return { ...state, receivablesTurnoverYear1: action.value } }
            case 'update ebitYear1': { return { ...state, ebitYear1: action.value } }
            case 'update ebitdaYear1': { return { ...state, ebitdaYear1: action.value } }
            case 'update debtRatioYear1': { return { ...state, debtRatioYear1: action.value } }
            case 'update debtToEquityRatioYear1': { return { ...state, debtToEquityRatioYear1: action.value } }
            case 'update interestCoverageRatioYear1': { return { ...state, interestCoverageRatioYear1: action.value } }
            case 'update earningsPerShareBasicYear1': { return { ...state, earningsPerShareBasicYear1: action.value } }
            case 'update earningsPerShareDilutedYear1': { return { ...state, earningsPerShareDilutedYear1: action.value } }
            case 'update dividendPayoutRatioYear1': { return { ...state, dividendPayoutRatioYear1: action.value } }
            case 'update priceToEarningsRatioYear1': { return { ...state, priceToEarningsRatioYear1: action.value } }

            // income statement year 0
            case 'update revenueYear0': { return { ...state, revenueYear0: action.value } }
            case 'update costOfRevenueYear0': { return { ...state, costOfRevenueYear0: action.value } }
            case 'update grossProfitYear0': { return { ...state, grossProfitYear0: action.value } }
            case 'update researchAndDevelopmentYear0': { return { ...state, researchAndDevelopmentYear0: action.value } }
            case 'update salesAndMarketingYear0': { return { ...state, salesAndMarketingYear0: action.value } }
            case 'update generalAndAdministrativeYear0': { return { ...state, generalAndAdministrativeYear0: action.value } }
            case 'update totalOperatingExpensesYear0': { return { ...state, totalOperatingExpensesYear0: action.value } }
            case 'update operatingIncomeYear0': { return { ...state, operatingIncomeYear0: action.value } }
            case 'update interestExpenseYear0': { return { ...state, interestExpenseYear0: action.value } }
            case 'update otherIncomeNetYear0': { return { ...state, otherIncomeNetYear0: action.value } }
            case 'update incomeBeforeIncomeTaxesYear0': { return { ...state, incomeBeforeIncomeTaxesYear0: action.value } }
            case 'update provisionForIncomeTaxesYear0': { return { ...state, provisionForIncomeTaxesYear0: action.value } }
            case 'update netIncomeNetLossYear0': { return { ...state, netIncomeNetLossYear0: action.value } }

            // balance sheet year 0
            case 'update cashAndCashEquivalentsYear0': { return { ...state, cashAndCashEquivalentsYear0: action.value } }
            case 'update accountsReceivableNetYear0': { return { ...state, accountsReceivableNetYear0: action.value } }
            case 'update inventoryYear0': { return { ...state, inventoryYear0: action.value } }
            case 'update otherCurrentAssetsYear0': { return { ...state, otherCurrentAssetsYear0: action.value } }
            case 'update totalCurrentAssetsYear0': { return { ...state, totalCurrentAssetsYear0: action.value } }
            case 'update propertyPlantAndEquipmentNetYear0': { return { ...state, propertyPlantAndEquipmentNetYear0: action.value } }
            case 'update intangibleAssetsNetYear0': { return { ...state, intangibleAssetsNetYear0: action.value } }
            case 'update otherNonCurrentAssetsYear0': { return { ...state, otherNonCurrentAssetsYear0: action.value } }
            case 'update totalNonCurrentAssetsYear0': { return { ...state, totalNonCurrentAssetsYear0: action.value } }
            case 'update totalAssetsYear0': { return { ...state, totalAssetsYear0: action.value } }
            case 'update accountsPayableYear0': { return { ...state, accountsPayableYear0: action.value } }
            case 'update accruedAndOtherYear0': { return { ...state, accruedAndOtherYear0: action.value } }
            case 'update shortTermDebtYear0': { return { ...state, shortTermDebtYear0: action.value } }
            case 'update totalCurrentLiabilitiesYear0': { return { ...state, totalCurrentLiabilitiesYear0: action.value } }
            case 'update longTermDebtYear0': { return { ...state, longTermDebtYear0: action.value } }
            case 'update otherLiabilitiesYear0': { return { ...state, otherLiabilitiesYear0: action.value } }
            case 'update totalNonCurrentLiabilitiesYear0': { return { ...state, totalNonCurrentLiabilitiesYear0: action.value } }
            case 'update totalLiabilitiesYear0': { return { ...state, totalLiabilitiesYear0: action.value } }
            case 'update equityCapitalYear0': { return { ...state, equityCapitalYear0: action.value } }
            case 'update retainedEarningsYear0': { return { ...state, retainedEarningsYear0: action.value } }
            case 'update additionalPaidInCapitalYear0': { return { ...state, additionalPaidInCapitalYear0: action.value } }
            case 'update totalShareholdersEquityYear0': { return { ...state, totalShareholdersEquityYear0: action.value } }
            case 'update totalLiabilitiesAndShareholdersEquityYear0': { return { ...state, totalLiabilitiesAndShareholdersEquityYear0: action.value } }

            // cash flow statement year 0
            case 'update netIncomeYear0': { return { ...state, netIncomeYear0: action.value } }
            case 'update depreciationAndAmortizationYear0': { return { ...state, depreciationAndAmortizationYear0: action.value } }
            case 'update changesInAssetsAndLiabilitiesYear0': { return { ...state, changesInAssetsAndLiabilitiesYear0: action.value } }
            case 'update netCashProvidedByOperatingActivitiesYear0': { return { ...state, netCashProvidedByOperatingActivitiesYear0: action.value } }
            case 'update capitalExpenditureYear0': { return { ...state, capitalExpenditureYear0: action.value } }
            case 'update capitalizedSoftwareDevelopmentCostsYear0': { return { ...state, capitalizedSoftwareDevelopmentCostsYear0: action.value } }
            case 'update maturitiesAndSalesOfInvestmentsYear0': { return { ...state, maturitiesAndSalesOfInvestmentsYear0: action.value } }
            case 'update cashPaidForAcquisitionOfBusinessYear0': { return { ...state, cashPaidForAcquisitionOfBusinessYear0: action.value } }
            case 'update netCashProvidedByInvestingActivitiesYear0': { return { ...state, netCashProvidedByInvestingActivitiesYear0: action.value } }
            case 'update proceedsFromIssuanceOfDebtYear0': { return { ...state, proceedsFromIssuanceOfDebtYear0: action.value } }
            case 'update proceedsFromIssuanceOfEquityYear0': { return { ...state, proceedsFromIssuanceOfEquityYear0: action.value } }
            case 'update debtRelatedCostsAndOtherNetYear0': { return { ...state, debtRelatedCostsAndOtherNetYear0: action.value } }
            case 'update netCashProvidedByFinancingActivitiesYear0': { return { ...state, netCashProvidedByFinancingActivitiesYear0: action.value } }
            case 'update changesInCashDuringPeriodYear0': { return { ...state, changesInCashDuringPeriodYear0: action.value } }
            case 'update cashAtBeginningOfPeriodYear0': { return { ...state, cashAtBeginningOfPeriodYear0: action.value } }
            case 'update cashAtEndOfPeriodYear0': { return { ...state, cashAtEndOfPeriodYear0: action.value } }

            // break even analysis year 0
            case 'update timeFrameYear0': { return { ...state, timeFrameYear0: action.value } }
            case 'update averagePriceSoldYear0': { return { ...state, averagePriceSoldYear0: action.value } }
            case 'update averageCostToMakeOrDeliverYear0': { return { ...state, averageCostToMakeOrDeliverYear0: action.value } }
            case 'update fixedCostsforMonthOrYearYear0': { return { ...state, fixedCostsforMonthOrYearYear0: action.value } }
            case 'update percentageOfPriceProfitYear0': { return { ...state, percentageOfPriceProfitYear0: action.value } }
            case 'update totalSalesNeededToBreakEvenYear0': { return { ...state, totalSalesNeededToBreakEvenYear0: action.value } }
            case 'update numberOfUnitsSoldNeededToBreakEvenYear0': { return { ...state, numberOfUnitsSoldNeededToBreakEvenYear0: action.value } }

            // financial ratios analysis year 0
            case 'update profitMarginYear0': { return { ...state, profitMarginYear0: action.value } }
            case 'update returnOnEquityAfterTaxYear0': { return { ...state, returnOnEquityAfterTaxYear0: action.value } }
            case 'update returnOnAssetsYear0': { return { ...state, returnOnAssetsYear0: action.value } }
            case 'update grossMarginYear0': { return { ...state, grossMarginYear0: action.value } }
            case 'update grossProfitMarginYear0': { return { ...state, grossProfitMarginYear0: action.value } }
            case 'update netProfitMarginYear0': { return { ...state, netProfitMarginYear0: action.value } }
            case 'update operatingMarginYear0': { return { ...state, operatingMarginYear0: action.value } }
            case 'update currentRatioYear0': { return { ...state, currentRatioYear0: action.value } }
            case 'update quickRatioYear0': { return { ...state, quickRatioYear0: action.value } }
            case 'update cashRatioYear0': { return { ...state, cashRatioYear0: action.value } }
            case 'update inventoryTurnoverYear0': { return { ...state, inventoryTurnoverYear0: action.value } }
            case 'update assetTurnoverYear0': { return { ...state, assetTurnoverYear0: action.value } }
            case 'update receivablesTurnoverYear0': { return { ...state, receivablesTurnoverYear0: action.value } }
            case 'update ebitYear0': { return { ...state, ebitYear0: action.value } }
            case 'update ebitdaYear0': { return { ...state, ebitdaYear0: action.value } }
            case 'update debtRatioYear0': { return { ...state, debtRatioYear0: action.value } }
            case 'update debtToEquityRatioYear0': { return { ...state, debtToEquityRatioYear0: action.value } }
            case 'update interestCoverageRatioYear0': { return { ...state, interestCoverageRatioYear0: action.value } }
            case 'update earningsPerShareBasicYear0': { return { ...state, earningsPerShareBasicYear0: action.value } }
            case 'update earningsPerShareDilutedYear0': { return { ...state, earningsPerShareDilutedYear0: action.value } }
            case 'update dividendPayoutRatioYear0': { return { ...state, dividendPayoutRatioYear0: action.value } }
            case 'update priceToEarningsRatioYear0': { return { ...state, priceToEarningsRatioYear0: action.value } }

            // Risk Tab
            // Add fields
            case 'add technologyRisk':
                return {
                    ...state,
                    technologyRisk: [
                        ...state.technologyRisk,
                        action.value
                    ]
                };

            case 'add intellectualPropertyRisk':
                return {
                    ...state,
                    intellectualPropertyRisk: [
                        ...state.intellectualPropertyRisk,
                        action.value
                    ]
                };

            case 'add developmentRisk':
                return {
                    ...state,
                    developmentRisk: [
                        ...state.developmentRisk,
                        action.value
                    ]
                };

            case 'add operationalRisk':
                return {
                    ...state,
                    operationalRisk: [
                        ...state.operationalRisk,
                        action.value
                    ]
                };

            case 'add productFitRisk':
                return {
                    ...state,
                    productFitRisk: [
                        ...state.productFitRisk,
                        action.value
                    ]
                };

            case 'add channelRisk':
                return {
                    ...state,
                    channelRisk: [
                        ...state.channelRisk,
                        action.value
                    ]
                };

            case 'add regulatoryRisk':
                return {
                    ...state,
                    regulatoryRisk: [
                        ...state.regulatoryRisk,
                        action.value
                    ]
                };

            case 'add competitorRisk':
                return {
                    ...state,
                    competitorRisk: [
                        ...state.competitorRisk,
                        action.value
                    ]
                };

            case 'add teamRisk':
                return {
                    ...state,
                    teamRisk: [
                        ...state.teamRisk,
                        action.value
                    ]
                };

            case 'add hiringRisk':
                return {
                    ...state,
                    hiringRisk: [
                        ...state.hiringRisk,
                        action.value
                    ]
                };

            case 'add financingRisk':
                return {
                    ...state,
                    financingRisk: [
                        ...state.financingRisk,
                        action.value
                    ]
                };

            case 'add ceoRisk':
                return {
                    ...state,
                    ceoRisk: [
                        ...state.ceoRisk,
                        action.value
                    ]
                };

            // Remove fields
            case 'remove technologyRisk': {
                const copiedList = [...state.technologyRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    technologyRisk: copiedList
                }
            }

            case 'remove intellectualPropertyRisk': {
                const copiedList = [...state.intellectualPropertyRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    intellectualPropertyRisk: copiedList
                }
            }

            case 'remove developmentRisk': {
                const copiedList = [...state.developmentRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    developmentRisk: copiedList
                }
            }

            case 'remove operationalRisk': {
                const copiedList = [...state.operationalRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    operationalRisk: copiedList
                }
            }

            case 'remove productFitRisk': {
                const copiedList = [...state.productFitRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    productFitRisk: copiedList
                }
            }

            case 'remove channelRisk': {
                const copiedList = [...state.channelRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    channelRisk: copiedList
                }
            }

            case 'remove regulatoryRisk': {
                const copiedList = [...state.regulatoryRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    regulatoryRisk: copiedList
                }
            }

            case 'remove competitorRisk': {
                const copiedList = [...state.competitorRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    competitorRisk: copiedList
                }
            }

            case 'remove teamRisk': {
                const copiedList = [...state.teamRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    teamRisk: copiedList
                }
            }

            case 'remove hiringRisk': {
                const copiedList = [...state.hiringRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    hiringRisk: copiedList
                }
            }

            case 'remove financingRisk': {
                const copiedList = [...state.financingRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    financingRisk: copiedList
                }
            }

            case 'remove ceoRisk': {
                const copiedList = [...state.ceoRisk];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    ceoRisk: copiedList
                }
            }

            // Update fields
            case 'update technologyRisk': {
                const index = action.index;
                const copiedList = [...state.technologyRisk];
                copiedList[index] = action.value;
                return { ...state, technologyRisk: copiedList }
            }

            case 'update intellectualPropertyRisk': {
                const index = action.index;
                const copiedList = [...state.intellectualPropertyRisk];
                copiedList[index] = action.value;
                return { ...state, intellectualPropertyRisk: copiedList }
            }

            case 'update developmentRisk': {
                const index = action.index;
                const copiedList = [...state.developmentRisk];
                copiedList[index] = action.value;
                return { ...state, developmentRisk: copiedList }
            }

            case 'update operationalRisk': {
                const index = action.index;
                const copiedList = [...state.operationalRisk];
                copiedList[index] = action.value;
                return { ...state, operationalRisk: copiedList }
            }

            case 'update productFitRisk': {
                const index = action.index;
                const copiedList = [...state.productFitRisk];
                copiedList[index] = action.value;
                return { ...state, productFitRisk: copiedList }
            }

            case 'update channelRisk': {
                const index = action.index;
                const copiedList = [...state.channelRisk];
                copiedList[index] = action.value;
                return { ...state, channelRisk: copiedList }
            }

            case 'update regulatoryRisk': {
                const index = action.index;
                const copiedList = [...state.regulatoryRisk];
                copiedList[index] = action.value;
                return { ...state, regulatoryRisk: copiedList }
            }

            case 'update competitorRisk': {
                const index = action.index;
                const copiedList = [...state.competitorRisk];
                copiedList[index] = action.value;
                return { ...state, competitorRisk: copiedList }
            }

            case 'update teamRisk': {
                const index = action.index;
                const copiedList = [...state.teamRisk];
                copiedList[index] = action.value;
                return { ...state, teamRisk: copiedList }
            }

            case 'update hiringRisk': {
                const index = action.index;
                const copiedList = [...state.hiringRisk];
                copiedList[index] = action.value;
                return { ...state, hiringRisk: copiedList }
            }

            case 'update financingRisk': {
                const index = action.index;
                const copiedList = [...state.financingRisk];
                copiedList[index] = action.value;
                return { ...state, financingRisk: copiedList }
            }

            case 'update ceoRisk': {
                const index = action.index;
                const copiedList = [...state.ceoRisk];
                copiedList[index] = action.value;
                return { ...state, ceoRisk: copiedList }
            }


            // Management Tab 
            // Add fields
            // case 'add teamExperience':
            //     return {
            //         ...state,
            //         teamExperience: [
            //             ...state.teamExperience,
            //             action.value
            //         ]
            //     };

            // case 'add doFoundersHaveInsightsOfCompetitiveLandscape':
            //     return {
            //         ...state,
            //         doFoundersHaveInsightsOfCompetitiveLandscape: [
            //             ...state.doFoundersHaveInsightsOfCompetitiveLandscape,
            //             action.value
            //         ]
            //     };

            // case 'add whereTeamWorked':
            //     return {
            //         ...state,
            //         whereTeamWorked: [
            //             ...state.whereTeamWorked,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // case 'add X':
            //     return {
            //         ...state,
            //         X: [
            //             ...state.X,
            //             action.value
            //         ]
            //     };

            // Remove fields
            // case 'remove teamExperience': {
            //     const copiedList = [...state.teamExperience];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         teamExperience: copiedList
            //     }
            // }

            // case 'remove doFoundersHaveInsightsOfCompetitiveLandscape': {
            //     const copiedList = [...state.doFoundersHaveInsightsOfCompetitiveLandscape];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         doFoundersHaveInsightsOfCompetitiveLandscape: copiedList
            //     }
            // }

            // case 'remove whereTeamWorked': {
            //     const copiedList = [...state.whereTeamWorked];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         whereTeamWorked: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // case 'remove X': {
            //     const copiedList = [...state.X];
            //     copiedList.splice(action.index, 1);
            //     return {
            //         ...state,
            //         X: copiedList
            //     }
            // }

            // Update fields
            // case 'update teamExperience': {
            //     const index = action.index;
            //     const copiedList = [...state.teamExperience];
            //     copiedList[index] = action.value;
            //     return { ...state, teamExperience: copiedList }
            // }

            // case 'update doFoundersHaveInsightsOfCompetitiveLandscape': {
            //     const index = action.index;
            //     const copiedList = [...state.doFoundersHaveInsightsOfCompetitiveLandscape];
            //     copiedList[index] = action.value;
            //     return { ...state, doFoundersHaveInsightsOfCompetitiveLandscape: copiedList }
            // }

            // case 'update whereTeamWorked': {
            //     const index = action.index;
            //     const copiedList = [...state.whereTeamWorked];
            //     copiedList[index] = action.value;
            //     return { ...state, whereTeamWorked: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }


            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }

            // case 'update X': {
            //     const index = action.index;
            //     const copiedList = [...state.X];
            //     copiedList[index] = action.value;
            //     return { ...state, X: copiedList }
            // }


            // Results
            case 'update analysisResult': { return { ...state, analysisResult: action.value } }

            default: { return 'default'; }
        }
    }

    // realm function to generate id
    useEffect(() => {
        if (!state.pitchDeckId) {
            async function getPitchDeckId() {
                const id = await generateAddEditFormId();
                dispatch({ type: 'update pitchDeckId', value: id });
            }
            try {
                getPitchDeckId();
            } catch (err) {
                // To-do: Alert user an error was returned
                // console.error("Unable to generate unique ID");
            }
        }
    }, []);

    const [alert, setAlert] = useState(false);

    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const [message, setMessage] = useState("");

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    // submit function
    async function handleSubmit() {

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Analyzing</p>
                <p style={{ marginLeft: "28px" }}>Our real-time analytics might take up to 1 min to compute and show results.</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            // console.log("Submitted form:", state);
            let response = await analyzePitchDeck(state);

            const data = JSON.parse(response);

            if (data === -1) throw "Negative.";

            if (data) dispatch({ type: 'update analysisResult', value: data });

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View results</a></p>
            </div>);

        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }
    }

    // save function
    async function handleSave() {
        if (state.pitchDeckName === "") {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>New business plan must be named before saving and/or adding.</p>
            </div>);
        }
        else {
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Saving</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your business plan is ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            // Save
            try {
                await savePitchDeck(state);

                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Saved</p>
                    <p style={{ marginLeft: "28px" }}>Your business plan is saved and updated. <Link to={`/founders/business-plan/view/${state.pitchDeckId}`} target="_blank" rel="noopener noreferrer" className="alert-cta">View business plan</Link></p>
                </div>);

            } catch (err) {
                // To-do: Alert user an error was returned
                // console.error("Could not save the Pitch Deck. Reasons:", err);

                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>We failed to save your updated work. Please try again.</p>
                </div>);
            }
        }
    }

    function handleClose() {
        // Send user to the Pitch Deck Landing Page
        history.replace("/tools/founders/business-plan");
    }

    return (

        <>
            <NavBar />

            <div id="ideachecker">

                {/* loading alert */}
                {alert
                    ? (<Alert className="error-message floating-alert" variant="light">
                        <div>
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                            {message}
                        </div>
                    </Alert>)
                    : ""
                }

                <Container className="col-11" style={{ paddingBottom: "200px" }}>

                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdeckcreatetext">
                                Title
                            </span>
                            <br />
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        placeholder="Give this work a name"
                                        className="pitchdecktitlefield"
                                        onChange={(e) => { dispatch({ 'type': 'update pitchDeckName', 'value': e.target.value }) }}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="pitchdeckstatsdiv">
                            <Button onClick={() => handleSubmit()} className="pitchdeckanalysebutton glintanimation">
                                <span>Analyze</span>
                            </Button>

                            <button onClick={() => handleSave()} className="pitchdecksavebutton">
                                <span>Save</span>
                            </button>

                            <button onClick={() => handleClose()} className="pitchdeckclosebutton">
                                <span>Close</span>
                            </button>

                            <FaEllipsisH style={{ marginLeft: "20px" }} />
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="overview">

                            <Tab eventKey="overview" title="Overview">
                                <CreateEditOverview state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="business" title="Business">
                                <CreateEditBusiness state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="solution" title="Solution">
                                <CreateEditSolution state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="market" title="Market">
                                <CreateEditMarket state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="competition" title="Competition">
                                <CreateEditCompetition state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="operations" title="Operations">
                                <CreateEditOperations state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="marketing" title="Marketing">
                                <CreateEditMarketing state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="financials" title="Financials">
                                <CreateEditFinancials state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="risk" title="Risk">
                                <CreateEditRisk state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="management" title="Management" disabled>
                                {/* <CreateEditManagement /> */}
                            </Tab>

                            <Tab eventKey="dataRoom" title="Data Room">
                                <DataRoomComponent />
                            </Tab>

                        </Tabs>
                    </div>
                </Container>
            </div >
        </>

    )

}

export default CreatePitchDeckAnalysis;