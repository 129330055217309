import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewMarketing(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="goToMarket">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="goToMarket">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Go-to-market (distribution channel)
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="goToMarket">
                        <Card.Body id="companyinformation">
                            {!props?.goToMarketStrategy
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your go-to-market strategy to gain visibility and expand distribution channels for your product-or-service to acquire new customers?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.goToMarketStrategy.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doesBusinessModelSupportAcquisition
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Does your business model support customer acquisition profitably?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doesBusinessModelSupportAcquisition.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.planningToAcquireCustomersThroughMarketing
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Are you planning to acquire customers through brand marketing or other online forms of acquisition?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.planningToAcquireCustomersThroughMarketing.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howProductWillBeDistributed
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How will your product-or-service be distributed to the end user?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howProductWillBeDistributed.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.distributionChannelsUsed
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What distribution channels will you use?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.distributionChannelsUsed.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.whenWhereWhyHowConsumersBuy
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">When, where, why, and how do consumers buy your product-or-service?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.whenWhereWhyHowConsumersBuy.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isProductTransactionOriented
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is it transaction oriented (where you have limited communication with your customers and focus instead on efficiency or speed, such as a self-service kiosk at an airport terminal)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isProductTransactionOriented.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isBuyingProcessRelationshipOriented
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is the process of buying your product-or-service relationship oriented (like a loyalty program where you learn customer preferences and make suggestions about other items of interest)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isBuyingProcessRelationshipOriented.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="promotion" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="promotion">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Promotion
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="promotion">
                        <Card.Body id="companyinformation">
                            {!props?.areYouPlanningToBuildDirectForce
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Are you planning to build a direct, outside sales force, and can the average selling price of your product-or-service support this go-to-market strategy?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.areYouPlanningToBuildDirectForce.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howToCommunicateWithConsumers
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How will you communicate with consumers and make them aware of your product-or-service?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howToCommunicateWithConsumers.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.willUseWordOfMouth
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will you use word of mouth?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.willUseWordOfMouth.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.willOptForSalesPromotions
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will you opt for sales promotions?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.willOptForSalesPromotions.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doDirectSalesMakeSense
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Do direct sales make the most sense?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doDirectSalesMakeSense.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isTraditionalBetter
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is traditional advertising a better approach?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isTraditionalBetter.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doYouNeedToBuildBrandLoyalty
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Do you need to build brand loyalty, or is your product-or-service the only one that will fill customers' needs?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doYouNeedToBuildBrandLoyalty.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewMarketing;