import React, { useState, useEffect } from 'react';
import NavBar from '../../layout/navbar/navbar';
import { Button, Alert, Container, Spinner, Modal } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineDelete, AiOutlineCheckSquare, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdRestore } from 'react-icons/md'
import { useRealmApp } from '../../RealmApp';
// import { useHistory } from 'react-router-dom';

const ArchiveRoom = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // hook for id
    const [fileId, setFileId] = useState();

    // hook for fileName
    const [fileName, setFileName] = useState();

    // hook for restore modal
    const [restoreModal, setRestoreModal] = useState(false);

    // function to open restore modal
    const openRestoreModal = (e) => {
        setFileId(e);
        setRestoreModal(true);
    }

    // function to close restore modal
    function closeRestoreModal() {
        setFileId(null);
        setRestoreModal(false);
    }

    // hook for delete modal
    const [deleteModal, setDeleteModal] = useState(false);

    // function to open delete modal
    const openDeleteModal = (e) => {
        setFileId(e._id);
        setFileName(e.fileName);
        setDeleteModal(true);
    }

    // function to close delete modal
    function closeDeleteModal() {
        setFileId(null);
        setDeleteModal(false);
    }

    const { currentUser } = useRealmApp();

    const { getMyArchivedFiles, restoreFile, deleteFile, getDataRoomPresignedUrl } = currentUser.functions;

    const [files, setFiles] = useState([]);

    useEffect(async () => {
        try {
            const response = await getMyArchivedFiles();

            const data = JSON.parse(response);

            setFiles(data);

            setAlert(false);

        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }
    }, []);

    // restore file
    async function restoreFileConfirmation() {

        // hide modal component
        closeRestoreModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Restoring</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your document is restored.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await restoreFile(fileId);

            const data = JSON.parse(response);

            setFiles(data);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Restored</p>
                <p style={{ marginLeft: "28px" }}>Your document is restored.</p>
            </div>);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    // delete file
    async function deleteFileConfirmation() {

        // hide modal component
        closeDeleteModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        // check if fileName exists - if it does, file is on s3
        if (fileName) {

            let url;

            // request for presigned url
            try {
                const response = await getDataRoomPresignedUrl({ type: "delete", fileName: fileName });
                url = response;
            }
            catch (error) {
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Failed to get presigned url.</p>
                </div>);

                return;
            }

            // delete file from S3 using url
            try {
                await fetch(url, {
                    method: "DELETE"
                });
            }
            catch (error) {
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Failed to delete document.</p>
                </div>);
                
                return;
            }
        }

        // delete document from mongodb collection
        try {
            const response = await deleteFile(fileId);

            const data = JSON.parse(response);

            setFiles(data);
        } catch (err) {
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Failed to delete document details.</p>
            </div>);

            return;
        }

        // close alert
        closeAlert();
    }

    // function to close page
    function closePage() {
        window.close();
    }

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                Archive
                                <sup data-tip="">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* buttons */}
                        <div className="pitchdeckstatsdiv" style={{ display: "flex", justifyContent: "end" }}>
                            <a href="#" onClick={closePage}>
                                <Button className="pitchdeckaddbutton glintanimation">
                                    <span>Close</span>
                                </Button>
                            </a>
                        </div>
                    </div>

                    <div>
                        <table className="pitchdeckhistorytable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Uploaded</th>
                                    <th>Restore</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {files?.length === 0
                                    ? (<tr className="pitchdecktablerow">
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                    </tr>)
                                    : files?.map((file, index) =>
                                        <tr key={index} className="pitchdecktablerow">
                                            <td className="pitchdecktabledata">{file.name ? file.name : "Unnamed document"}</td>
                                            <td className="pitchdecktabledata">{file.uploadDate.substring(0, 10)}</td>
                                            <td className="pitchdecktabledata">
                                                <Button className="edit-button" onClick={() => openRestoreModal(file._id)}><MdRestore /></Button>
                                            </td>
                                            <td className="pitchdecktabledata">
                                                <Button className="delete-button" onClick={() => openDeleteModal(file)}><AiOutlineDelete /></Button>
                                            </td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>

            <Modal className="modal-div" show={restoreModal} onHide={closeRestoreModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to restore this document?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeRestoreModal}>Cancel</button>

                        <button className="modal-compare glintanimation" onClick={restoreFileConfirmation}>
                            Restore
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modal-div" show={deleteModal} onHide={closeDeleteModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to delete this document?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeDeleteModal}>Cancel</button>

                        <button className="modal-delete glintanimation" onClick={deleteFileConfirmation}>
                            Delete
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ArchiveRoom;