import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewFinancials(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="monetizationModel">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="monetizationModel">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Monetization model
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="monetizationModel">
                        <Card.Body id="companyinformation">
                            {!props?.monetizationModel
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How do you monetize or charge for your product-or-service offering?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.monetizationModel.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.areYouBuildingAnAnnuityBusiness
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Are you building an annuity business, such as a movie streaming subscription service that continues for years?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.areYouBuildingAnAnnuityBusiness.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.areYouSellingDurableProduct
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Are you selling a durable product that is purchased only occasionally during a lifetime, such as a washing machine or a car?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.areYouSellingDurableProduct.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.financialMargins
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the margins?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.financialMargins.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.observedUnitEconomics
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the financial metrics are you observing?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.observedUnitEconomics.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doUnitEconomicsMakeSense
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Do the financial metrics make sense for your industry and/or vertical market?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doUnitEconomicsMakeSense.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="pricingModel" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="pricingModel">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Pricing model
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="pricingModel">
                        <Card.Body id="companyinformation">
                            {!props?.pricingModel
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your pricing model?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.pricingModel.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.pricingModelCustomerDemand
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will your pricing model be fixed or tiered or variable depending on customer demand?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.pricingModelCustomerDemand.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.pricePointProductOffer
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">At what price point are you offering your product-or-service?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.pricePointProductOffer.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isPriceEstablished
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will there be an established price?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isPriceEstablished.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.relevantPricingStrategy
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your relevant pricing strategy?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.relevantPricingStrategy.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.appropriatePricingStrategy
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is the appropriate pricing strategy?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.appropriatePricingStrategy.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="revenue" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="revenue">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Revenue
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="revenue">
                        <Card.Body id="companyinformation">
                            {!props?.financialRevenue
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your revenue?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.financialRevenue.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.moneyMadeFromTargetMarket
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How much money have you made or going to make from your target market?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.moneyMadeFromTargetMarket.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.whenIsBusinessExpectedToMakeMoney
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">At what point do you expect the business to make money?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.whenIsBusinessExpectedToMakeMoney.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.durationToMakeMoney
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will it take up to six months or more than two years?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.durationToMakeMoney.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.whenIsBusinessExpectedToReachBreakEven
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">At what level of sales will you reach the break-even point?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.whenIsBusinessExpectedToReachBreakEven.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.anticipateToBreakEven
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">When do you anticipate to break-even?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.anticipateToBreakEven.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.salesTargetBasedOnProjectedGrowth
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your sales target based on projected year-on-year growth?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.salesTargetBasedOnProjectedGrowth.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howLongToReachNextSalesMilestone
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">After that, how long will it take to reach the next sales milestones?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howLongToReachNextSalesMilestone.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="costStructure" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="costStructure">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Cost structure
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="costStructure">
                        <Card.Body id="companyinformation">
                            {!props?.costAcquiringCustomer
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is the cost of acquiring a customer?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.costAcquiringCustomer.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howMuchWillCustomerBeWorth
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What do you think that customer will be worth over time with the small sample size of data that you may have?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howMuchWillCustomerBeWorth.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.internalComponentsAssumptions
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are your general costs assumptions based on the internal components of your business, such as variable and fixed costs, cost of capital, seasonal cash flow fluctuations, and so on?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.internalComponentsAssumptions.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            {!props?.incomeStatement
                ? ("")
                : (<Accordion defaultActiveKey="incomeStatement" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="incomeStatement">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Income statement
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="incomeStatement">
                            <Card.Body id="companyinformation">
                                <div className="financialstatements2">
                                    <div>
                                        <div className="financialstatements">
                                            <table className="financialstatementstable">
                                                <thead>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th className="financialstatementstabledatacenter">{!props.incomeStatement.year[0] ? "" : props.incomeStatement.year[0]}</th>
                                                        <th className="financialstatementstabledatacenter">{!props.incomeStatement.year[1] ? "" : props.incomeStatement.year[1]}</th>
                                                        <th className="financialstatementstabledatalast">{!props.incomeStatement.year[2] ? "" : props.incomeStatement.year[2]}</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                    </tr>
                                                </thead>
                                                {/* form fields */}
                                                <tbody>
                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Revenue</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.revenue[0] ? "" : props.incomeStatement.revenue[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.revenue[1] ? "" : props.incomeStatement.revenue[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.revenue[2] ? "" : props.incomeStatement.revenue[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cost of revenue</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.costOfRevenue[0] ? "" : props.incomeStatement.costOfRevenue[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.costOfRevenue[1] ? "" : props.incomeStatement.costOfRevenue[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.costOfRevenue[2] ? "" : props.incomeStatement.costOfRevenue[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Gross profit</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.grossProfit[0] ? "" : props.incomeStatement.grossProfit[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.grossProfit[1] ? "" : props.incomeStatement.grossProfit[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.grossProfit[2] ? "" : props.incomeStatement.grossProfit[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Operating expenses:</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Research and development</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.researchAndDevelopment[0] ? "" : props.incomeStatement.operatingExpenses.researchAndDevelopment[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.researchAndDevelopment[1] ? "" : props.incomeStatement.operatingExpenses.researchAndDevelopment[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.operatingExpenses.researchAndDevelopment[2] ? "" : props.incomeStatement.operatingExpenses.researchAndDevelopment[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Sales and marketing</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.salesAndMarketing[0] ? "" : props.incomeStatement.operatingExpenses.salesAndMarketing[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.salesAndMarketing[1] ? "" : props.incomeStatement.operatingExpenses.salesAndMarketing[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.operatingExpenses.salesAndMarketing[2] ? "" : props.incomeStatement.operatingExpenses.salesAndMarketing[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>General and administrative</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.generalAndAdministrative[0] ? "" : props.incomeStatement.operatingExpenses.generalAndAdministrative[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.generalAndAdministrative[1] ? "" : props.incomeStatement.operatingExpenses.generalAndAdministrative[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.operatingExpenses.generalAndAdministrative[2] ? "" : props.incomeStatement.operatingExpenses.generalAndAdministrative[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total operating expenses</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.totalOperatingExpenses[0] ? "" : props.incomeStatement.operatingExpenses.totalOperatingExpenses[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingExpenses.totalOperatingExpenses[1] ? "" : props.incomeStatement.operatingExpenses.totalOperatingExpenses[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.operatingExpenses.totalOperatingExpenses[2] ? "" : props.incomeStatement.operatingExpenses.totalOperatingExpenses[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Operating income / (loss)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingIncome[0] ? "" : props.incomeStatement.operatingIncome[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.operatingIncome[1] ? "" : props.incomeStatement.operatingIncome[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.operatingIncome[2] ? "" : props.incomeStatement.operatingIncome[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Interest expense</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.interestExpense[0] ? "" : props.incomeStatement.interestExpense[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.interestExpense[1] ? "" : props.incomeStatement.interestExpense[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.interestExpense[2] ? "" : props.incomeStatement.interestExpense[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other income / (loss), net</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.otherIncomeNet[0] ? "" : props.incomeStatement.otherIncomeNet[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.otherIncomeNet[1] ? "" : props.incomeStatement.otherIncomeNet[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.otherIncomeNet[2] ? "" : props.incomeStatement.otherIncomeNet[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Income / (loss) before income taxes</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.incomeBeforeIncomeTaxes[0] ? "" : props.incomeStatement.incomeBeforeIncomeTaxes[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.incomeBeforeIncomeTaxes[1] ? "" : props.incomeStatement.incomeBeforeIncomeTaxes[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.incomeBeforeIncomeTaxes[2] ? "" : props.incomeStatement.incomeBeforeIncomeTaxes[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Provision / (benefit) for income taxes</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.provisionForIncomeTaxes[0] ? "" : props.incomeStatement.provisionForIncomeTaxes[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.provisionForIncomeTaxes[1] ? "" : props.incomeStatement.provisionForIncomeTaxes[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.provisionForIncomeTaxes[2] ? "" : props.incomeStatement.provisionForIncomeTaxes[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net income / (net loss)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.netIncomeNetLoss[0] ? "" : props.incomeStatement.netIncomeNetLoss[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.incomeStatement.netIncomeNetLoss[1] ? "" : props.incomeStatement.netIncomeNetLoss[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.incomeStatement.netIncomeNetLoss[2] ? "" : props.incomeStatement.netIncomeNetLoss[2]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>)
            }

            {!props?.balanceSheet
                ? ("")
                : (<Accordion defaultActiveKey="balanceSheet" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="balanceSheet">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Balance sheet
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="balanceSheet">
                            <Card.Body id="companyinformation">
                                <div className="financialstatements2">
                                    <div>
                                        <div className="financialstatements">
                                            <table className="financialstatementstable">
                                                <thead>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th className="financialstatementstabledatacenter">{!props.incomeStatement.year[0] ? "" : props.incomeStatement.year[0]}</th>
                                                        <th className="financialstatementstabledatacenter">{!props.incomeStatement.year[1] ? "" : props.incomeStatement.year[1]}</th>
                                                        <th className="financialstatementstabledatalast">{!props.incomeStatement.year[2] ? "" : props.incomeStatement.year[2]}</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                    </tr>
                                                </thead>
                                                {/* form fields */}
                                                <tbody>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={5} className="financialstatementstabledatalast">ASSETS</th>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Current assets:</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cash and cash equivalents</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.cashAndCashEquivalents[0] ? "" : props.balanceSheet.assets.currentAssets.cashAndCashEquivalents[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.cashAndCashEquivalents[1] ? "" : props.balanceSheet.assets.currentAssets.cashAndCashEquivalents[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.currentAssets.cashAndCashEquivalents[2] ? "" : props.balanceSheet.assets.currentAssets.cashAndCashEquivalents[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Accounts receivable, net</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.accountsReceivableNet[0] ? "" : props.balanceSheet.assets.currentAssets.accountsReceivableNet[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.accountsReceivableNet[1] ? "" : props.balanceSheet.assets.currentAssets.accountsReceivableNet[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.currentAssets.accountsReceivableNet[2] ? "" : props.balanceSheet.assets.currentAssets.accountsReceivableNet[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Inventory</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.inventory[0] ? "" : props.balanceSheet.assets.currentAssets.inventory[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.inventory[1] ? "" : props.balanceSheet.assets.currentAssets.inventory[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.currentAssets.inventory[2] ? "" : props.balanceSheet.assets.currentAssets.inventory[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other current assets</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.otherCurrentAssets[0] ? "" : props.balanceSheet.assets.currentAssets.otherCurrentAssets[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.otherCurrentAssets[1] ? "" : props.balanceSheet.assets.currentAssets.otherCurrentAssets[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.currentAssets.otherCurrentAssets[2] ? "" : props.balanceSheet.assets.currentAssets.otherCurrentAssets[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total current assets</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.totalCurrentAssets[0] ? "" : props.balanceSheet.assets.currentAssets.totalCurrentAssets[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.currentAssets.totalCurrentAssets[1] ? "" : props.balanceSheet.assets.currentAssets.totalCurrentAssets[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.currentAssets.totalCurrentAssets[2] ? "" : props.balanceSheet.assets.currentAssets.totalCurrentAssets[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Non-current assets:</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Property, plant, and equipment, net</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.propertyPlantAndEquipmentNet[0] ? "" : props.balanceSheet.assets.nonCurrentAssets.propertyPlantAndEquipmentNet[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.propertyPlantAndEquipmentNet[1] ? "" : props.balanceSheet.assets.nonCurrentAssets.propertyPlantAndEquipmentNet[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.propertyPlantAndEquipmentNet[2] ? "" : props.balanceSheet.assets.nonCurrentAssets.propertyPlantAndEquipmentNet[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Intangible assets, net</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.intangibleAssetsNet[0] ? "" : props.balanceSheet.assets.nonCurrentAssets.intangibleAssetsNet[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.intangibleAssetsNet[1] ? "" : props.balanceSheet.assets.nonCurrentAssets.intangibleAssetsNet[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.intangibleAssetsNet[2] ? "" : props.balanceSheet.assets.nonCurrentAssets.intangibleAssetsNet[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other non-current assets</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.otherNonCurrentAssets[0] ? "" : props.balanceSheet.assets.nonCurrentAssets.otherNonCurrentAssets[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.otherNonCurrentAssets[1] ? "" : props.balanceSheet.assets.nonCurrentAssets.otherNonCurrentAssets[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.otherNonCurrentAssets[2] ? "" : props.balanceSheet.assets.nonCurrentAssets.otherNonCurrentAssets[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent">Total non-current assets</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.totalNonCurrentAssets[0] ? "" : props.balanceSheet.assets.nonCurrentAssets.totalNonCurrentAssets[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.totalNonCurrentAssets[1] ? "" : props.balanceSheet.assets.nonCurrentAssets.totalNonCurrentAssets[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.nonCurrentAssets.totalNonCurrentAssets[2] ? "" : props.balanceSheet.assets.nonCurrentAssets.totalNonCurrentAssets[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total assets</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.totalAssets[0] ? "" : props.balanceSheet.assets.totalAssets[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.assets.totalAssets[1] ? "" : props.balanceSheet.assets.totalAssets[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.assets.totalAssets[2] ? "" : props.balanceSheet.assets.totalAssets[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={5} className="financialstatementstabledatalast">LIABILITIES & SHAREHOLDERS' EQUITY</th>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Current liabilities:</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Accounts payable</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accountsPayable[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accountsPayable[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accountsPayable[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accountsPayable[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accountsPayable[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accountsPayable[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Accrued and other</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accruedAndOther[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accruedAndOther[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accruedAndOther[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accruedAndOther[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accruedAndOther[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.accruedAndOther[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Short-term debt</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.shortTermDebt[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.shortTermDebt[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.shortTermDebt[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.shortTermDebt[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.shortTermDebt[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.shortTermDebt[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total current liabilities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.totalCurrentLiabilities[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.totalCurrentLiabilities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.totalCurrentLiabilities[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.totalCurrentLiabilities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.totalCurrentLiabilities[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.currentLiabilities.totalCurrentLiabilities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft">Non-current liabilities:</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Long-term debt</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.longTermDebt[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.longTermDebt[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.longTermDebt[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.longTermDebt[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.longTermDebt[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.longTermDebt[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other liabilities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.otherLiabilities[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.otherLiabilities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.otherLiabilities[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.otherLiabilities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.otherLiabilities[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.otherLiabilities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total non-current liabilities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.totalNonCurrentLiabilities[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.totalNonCurrentLiabilities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.totalNonCurrentLiabilities[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.totalNonCurrentLiabilities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.totalNonCurrentLiabilities[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.nonCurrentLiabilites.totalNonCurrentLiabilities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total liabilities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.totalLiabilities[0] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.totalLiabilities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.totalLiabilities[1] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.totalLiabilities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.liabilitiesAndShareholdersEquity.totalLiabilities[2] ? "" : props.balanceSheet.liabilitiesAndShareholdersEquity.totalLiabilities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={5} className="financialstatementstabledatalast">SHAREHOLDERS' EQUITY</th>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Equity capital</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.equityCapital[0] ? "" : props.balanceSheet.shareholdersEquity.equityCapital[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.equityCapital[1] ? "" : props.balanceSheet.shareholdersEquity.equityCapital[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.shareholdersEquity.equityCapital[2] ? "" : props.balanceSheet.shareholdersEquity.equityCapital[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Retained earnings / (accumulated deficit)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.retainedEarning[0] ? "" : props.balanceSheet.shareholdersEquity.retainedEarning[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.retainedEarning[1] ? "" : props.balanceSheet.shareholdersEquity.retainedEarning[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.shareholdersEquity.retainedEarning[2] ? "" : props.balanceSheet.shareholdersEquity.retainedEarning[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Additional paid-in capital</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.additionalPaidInCapital[0] ? "" : props.balanceSheet.shareholdersEquity.additionalPaidInCapital[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.additionalPaidInCapital[1] ? "" : props.balanceSheet.shareholdersEquity.additionalPaidInCapital[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.shareholdersEquity.additionalPaidInCapital[2] ? "" : props.balanceSheet.shareholdersEquity.additionalPaidInCapital[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total shareholders' equity</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.totalShareholdersEquity[0] ? "" : props.balanceSheet.shareholdersEquity.totalShareholdersEquity[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.totalShareholdersEquity[1] ? "" : props.balanceSheet.shareholdersEquity.totalShareholdersEquity[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.shareholdersEquity.totalShareholdersEquity[2] ? "" : props.balanceSheet.shareholdersEquity.totalShareholdersEquity[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total liabilities and shareholders' equity</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.totalLiabilitiesAndShareholdersEquity[0] ? "" : props.balanceSheet.shareholdersEquity.totalLiabilitiesAndShareholdersEquity[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.balanceSheet.shareholdersEquity.totalLiabilitiesAndShareholdersEquity[1] ? "" : props.balanceSheet.shareholdersEquity.totalLiabilitiesAndShareholdersEquity[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.balanceSheet.shareholdersEquity.totalLiabilitiesAndShareholdersEquity[2] ? "" : props.balanceSheet.shareholdersEquity.totalLiabilitiesAndShareholdersEquity[2]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>)
            }

            {!props?.cashFlowStatement
                ? ("")
                : (<Accordion defaultActiveKey="cashFlowStatement" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="cashFlowStatement">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Cash flow statement
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="cashFlowStatement">
                            <Card.Body id="companyinformation">
                                <div className="financialstatements2">
                                    <div>
                                        <div className="financialstatements">
                                            <table className="financialstatementstable">
                                                <thead>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th className="financialstatementstabledatacenter">{!props.cashFlowStatement.year[0] ? "" : props.cashFlowStatement.year[0]}</th>
                                                        <th className="financialstatementstabledatacenter">{!props.cashFlowStatement.year[1] ? "" : props.cashFlowStatement.year[1]}</th>
                                                        <th className="financialstatementstabledatalast">{!props.cashFlowStatement.year[2] ? "" : props.cashFlowStatement.year[2]}</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                    </tr>
                                                </thead>
                                                {/* form fields */}
                                                <tbody>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={5} className="financialstatementstabledatalast">CASH FLOW FROM OPERATING ACTIVITIES</th>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Net income / (loss)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.netIncome[0] ? "" : props.cashFlowStatement.fromOperatingActivities.netIncome[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.netIncome[1] ? "" : props.cashFlowStatement.fromOperatingActivities.netIncome[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromOperatingActivities.netIncome[2] ? "" : props.cashFlowStatement.fromOperatingActivities.netIncome[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft"><span style={{ fontFamily: "AileronItalic", fontWeight: "100" }}>Adjustments to reconcile net income / (loss) to net cash provided by / (used in) operating activities:</span></th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Depreciation and amortization</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.depreciationAndAmortization[0] ? "" : props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.depreciationAndAmortization[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.depreciationAndAmortization[1] ? "" : props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.depreciationAndAmortization[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.depreciationAndAmortization[2] ? "" : props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.depreciationAndAmortization[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Changes in assets and liabilities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.changesInAssetsAndLiabilities[0] ? "" : props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.changesInAssetsAndLiabilities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.changesInAssetsAndLiabilities[1] ? "" : props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.changesInAssetsAndLiabilities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.changesInAssetsAndLiabilities[2] ? "" : props.cashFlowStatement.fromOperatingActivities.adjustmentsToReconcileNetIncome.changesInAssetsAndLiabilities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net cash provided by / (used in) operating activities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.netCashProvidedByOperatingActivities[0] ? "" : props.cashFlowStatement.fromOperatingActivities.netCashProvidedByOperatingActivities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromOperatingActivities.netCashProvidedByOperatingActivities[1] ? "" : props.cashFlowStatement.fromOperatingActivities.netCashProvidedByOperatingActivities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromOperatingActivities.netCashProvidedByOperatingActivities[2] ? "" : props.cashFlowStatement.fromOperatingActivities.netCashProvidedByOperatingActivities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={5} className="financialstatementstabledatalast">CASH FLOW FROM INVESTING ACTIVITIES</th>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Capital expenditure for property, plant, and equipment</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.capitalExpenditure[0] ? "" : props.cashFlowStatement.fromInvestingActivities.capitalExpenditure[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.capitalExpenditure[1] ? "" : props.cashFlowStatement.fromInvestingActivities.capitalExpenditure[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromInvestingActivities.capitalExpenditure[2] ? "" : props.cashFlowStatement.fromInvestingActivities.capitalExpenditure[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Capitalized software development costs</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.capitalizedSoftwareDevelopmentCosts[0] ? "" : props.cashFlowStatement.fromInvestingActivities.capitalizedSoftwareDevelopmentCosts[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.capitalizedSoftwareDevelopmentCosts[1] ? "" : props.cashFlowStatement.fromInvestingActivities.capitalizedSoftwareDevelopmentCosts[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromInvestingActivities.capitalizedSoftwareDevelopmentCosts[2] ? "" : props.cashFlowStatement.fromInvestingActivities.capitalizedSoftwareDevelopmentCosts[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Maturities and sales of investments</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.maturitiesAndSalesOfInvestments[0] ? "" : props.cashFlowStatement.fromInvestingActivities.maturitiesAndSalesOfInvestments[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.maturitiesAndSalesOfInvestments[1] ? "" : props.cashFlowStatement.fromInvestingActivities.maturitiesAndSalesOfInvestments[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromInvestingActivities.maturitiesAndSalesOfInvestments[2] ? "" : props.cashFlowStatement.fromInvestingActivities.maturitiesAndSalesOfInvestments[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cash paid for acquisition of businesses / (net of cash acquired)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.cashPaidForAcquisitionOfBusiness[0] ? "" : props.cashFlowStatement.fromInvestingActivities.cashPaidForAcquisitionOfBusiness[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.cashPaidForAcquisitionOfBusiness[1] ? "" : props.cashFlowStatement.fromInvestingActivities.cashPaidForAcquisitionOfBusiness[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromInvestingActivities.cashPaidForAcquisitionOfBusiness[2] ? "" : props.cashFlowStatement.fromInvestingActivities.cashPaidForAcquisitionOfBusiness[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net cash provided by / (used in) investing activities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.netCashProvidedByInvestingActivities[0] ? "" : props.cashFlowStatement.fromInvestingActivities.netCashProvidedByInvestingActivities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromInvestingActivities.netCashProvidedByInvestingActivities[1] ? "" : props.cashFlowStatement.fromInvestingActivities.netCashProvidedByInvestingActivities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromInvestingActivities.netCashProvidedByInvestingActivities[2] ? "" : props.cashFlowStatement.fromInvestingActivities.netCashProvidedByInvestingActivities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={5} className="financialstatementstabledatalast">CASH FLOW FROM FINANCING ACTIVITIES</th>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Proceeds from issuance / (repayment) of debt</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfDebt[0] ? "" : props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfDebt[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfDebt[1] ? "" : props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfDebt[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfDebt[2] ? "" : props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfDebt[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Proceeds from issuance / (repayment) of equity</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfEquity[0] ? "" : props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfEquity[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfEquity[1] ? "" : props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfEquity[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfEquity[2] ? "" : props.cashFlowStatement.fromFinancingActivities.proceedsFromIssuanceOfEquity[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Debt-related costs and other, net</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.debtRelatedCostsAndOtherNet[0] ? "" : props.cashFlowStatement.fromFinancingActivities.debtRelatedCostsAndOtherNet[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.debtRelatedCostsAndOtherNet[1] ? "" : props.cashFlowStatement.fromFinancingActivities.debtRelatedCostsAndOtherNet[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromFinancingActivities.debtRelatedCostsAndOtherNet[2] ? "" : props.cashFlowStatement.fromFinancingActivities.debtRelatedCostsAndOtherNet[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net cash provided by (used in) financing activities</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.netCashProvidedByFinancingActivities[0] ? "" : props.cashFlowStatement.fromFinancingActivities.netCashProvidedByFinancingActivities[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.fromFinancingActivities.netCashProvidedByFinancingActivities[1] ? "" : props.cashFlowStatement.fromFinancingActivities.netCashProvidedByFinancingActivities[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.fromFinancingActivities.netCashProvidedByFinancingActivities[2] ? "" : props.cashFlowStatement.fromFinancingActivities.netCashProvidedByFinancingActivities[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Changes in cash during the period</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.changesInCashDuringPeriod[0] ? "" : props.cashFlowStatement.changesInCashDuringPeriod[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.changesInCashDuringPeriod[1] ? "" : props.cashFlowStatement.changesInCashDuringPeriod[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.changesInCashDuringPeriod[2] ? "" : props.cashFlowStatement.changesInCashDuringPeriod[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cash at the beginning of the period</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.cashAtBeginningOfPeriod[0] ? "" : props.cashFlowStatement.cashAtBeginningOfPeriod[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.cashAtBeginningOfPeriod[1] ? "" : props.cashFlowStatement.cashAtBeginningOfPeriod[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.cashAtBeginningOfPeriod[2] ? "" : props.cashFlowStatement.cashAtBeginningOfPeriod[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Cash at the end of the period</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.cashAtEndOfPeriod[0] ? "" : props.cashFlowStatement.cashAtEndOfPeriod[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.cashFlowStatement.cashAtEndOfPeriod[1] ? "" : props.cashFlowStatement.cashAtEndOfPeriod[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.cashFlowStatement.cashAtEndOfPeriod[2] ? "" : props.cashFlowStatement.cashAtEndOfPeriod[2]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>)
            }

            {!props?.breakEvenAnalysis
                ? ("")
                : (<Accordion defaultActiveKey="breakEvenAnalysis" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="breakEvenAnalysis">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Break-even analysis
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="breakEvenAnalysis">
                            <Card.Body id="companyinformation">
                                <div className="financialstatements2">
                                    <div>
                                        <div className="financialstatements">
                                            <table className="financialstatementstable">
                                                <thead>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th className="financialstatementstabledatacenter">{!props.breakEvenAnalysis.year[0] ? "" : props.breakEvenAnalysis.year[0]}</th>
                                                        <th className="financialstatementstabledatacenter">{!props.breakEvenAnalysis.year[1] ? "" : props.breakEvenAnalysis.year[1]}</th>
                                                        <th className="financialstatementstabledatalast">{!props.breakEvenAnalysis.year[2] ? "" : props.breakEvenAnalysis.year[2]}</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                    </tr>
                                                </thead>
                                                {/* form fields */}
                                                <tbody>
                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Time frame (monthly / yearly)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.timeFrame[0] ? "" : props.breakEvenAnalysis.timeFrame[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.timeFrame[1] ? "" : props.breakEvenAnalysis.timeFrame[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.timeFrame[2] ? "" : props.breakEvenAnalysis.timeFrame[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Average price of each product or service sold (in USD)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.averagePriceSold[0] ? "" : props.breakEvenAnalysis.averagePriceSold[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.averagePriceSold[1] ? "" : props.breakEvenAnalysis.averagePriceSold[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.averagePriceSold[2] ? "" : props.breakEvenAnalysis.averagePriceSold[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Average cost of each product or service to make or deliver (in USD)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.averageCostToMakeOrDeliver[0] ? "" : props.breakEvenAnalysis.averageCostToMakeOrDeliver[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.averageCostToMakeOrDeliver[1] ? "" : props.breakEvenAnalysis.averageCostToMakeOrDeliver[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.averageCostToMakeOrDeliver[2] ? "" : props.breakEvenAnalysis.averageCostToMakeOrDeliver[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Fixed costs for the month or year (in USD)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.fixedCostsforMonthOrYear[0] ? "" : props.breakEvenAnalysis.fixedCostsforMonthOrYear[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.fixedCostsforMonthOrYear[1] ? "" : props.breakEvenAnalysis.fixedCostsforMonthOrYear[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.fixedCostsforMonthOrYear[2] ? "" : props.breakEvenAnalysis.fixedCostsforMonthOrYear[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Percentage of price that is profit (in %)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.percentageOfPriceProfit[0] ? "" : props.breakEvenAnalysis.percentageOfPriceProfit[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.percentageOfPriceProfit[1] ? "" : props.breakEvenAnalysis.percentageOfPriceProfit[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.percentageOfPriceProfit[2] ? "" : props.breakEvenAnalysis.percentageOfPriceProfit[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total sales needed to break even (in USD)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.totalSalesNeededToBreakEven[0] ? "" : props.breakEvenAnalysis.totalSalesNeededToBreakEven[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.totalSalesNeededToBreakEven[1] ? "" : props.breakEvenAnalysis.totalSalesNeededToBreakEven[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.totalSalesNeededToBreakEven[2] ? "" : props.breakEvenAnalysis.totalSalesNeededToBreakEven[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Number of units sold needed to break even</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.numberOfUnitsSoldNeededToBreakEven[0] ? "" : props.breakEvenAnalysis.numberOfUnitsSoldNeededToBreakEven[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.breakEvenAnalysis.numberOfUnitsSoldNeededToBreakEven[1] ? "" : props.breakEvenAnalysis.numberOfUnitsSoldNeededToBreakEven[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.breakEvenAnalysis.numberOfUnitsSoldNeededToBreakEven[2] ? "" : props.breakEvenAnalysis.numberOfUnitsSoldNeededToBreakEven[2]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse >
                    </Card >
                </Accordion>)
            }

            {!props?.financialRatiosAnalysis
                ? ("")
                : (<Accordion defaultActiveKey="financialRatiosAnalysis" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="financialRatiosAnalysis">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Financial ratios analysis
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="financialRatiosAnalysis">
                            <Card.Body id="companyinformation">
                                <div className="financialstatements2">
                                    <div>
                                        <div className="financialstatements">
                                            <table className="financialstatementstable">
                                                <thead>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th className="financialstatementstabledatacenter">{!props.financialRatiosAnalysis.year[0] ? "" : props.financialRatiosAnalysis.year[0]}</th>
                                                        <th className="financialstatementstabledatacenter">{!props.financialRatiosAnalysis.year[1] ? "" : props.financialRatiosAnalysis.year[1]}</th>
                                                        <th className="financialstatementstabledatalast">{!props.financialRatiosAnalysis.year[2] ? "" : props.financialRatiosAnalysis.year[2]}</th>
                                                    </tr>
                                                    <tr className="financialstatementstablerowbreak">
                                                        <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                        <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                    </tr>
                                                </thead>
                                                {/* form fields */}
                                                <tbody>
                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Profitability ratios</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Profit margin</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.profitMargin[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.profitMargin[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.profitMargin[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.profitMargin[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.profitMargin[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.profitMargin[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Return on equity (ROE), after tax (in %)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.returnOnEquityAfterTax[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.returnOnEquityAfterTax[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.returnOnEquityAfterTax[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.returnOnEquityAfterTax[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.returnOnEquityAfterTax[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.returnOnEquityAfterTax[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Return on assets (ROA) (in %)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.returnOnAssets[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.returnOnAssets[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.returnOnAssets[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.returnOnAssets[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.returnOnAssets[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.returnOnAssets[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Gross margin</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.grossMargin[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.grossMargin[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.grossMargin[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.grossMargin[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.grossMargin[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.grossMargin[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Gross profit margin (in %)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.grossProfitMargin[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.grossProfitMargin[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.grossProfitMargin[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.grossProfitMargin[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.grossProfitMargin[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.grossProfitMargin[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Net profit margin (in %)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.netProfitMargin[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.netProfitMargin[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.netProfitMargin[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.netProfitMargin[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.netProfitMargin[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.netProfitMargin[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Operating margin (return on sales) (in %)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.operatingMargin[0] ? "" : props.financialRatiosAnalysis.profitabilityRatios.operatingMargin[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.operatingMargin[1] ? "" : props.financialRatiosAnalysis.profitabilityRatios.operatingMargin[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.profitabilityRatios.operatingMargin[2] ? "" : props.financialRatiosAnalysis.profitabilityRatios.operatingMargin[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Liquidity ratios</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Current ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.currentRatio[0] ? "" : props.financialRatiosAnalysis.liquidityRatios.currentRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.currentRatio[1] ? "" : props.financialRatiosAnalysis.liquidityRatios.currentRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.currentRatio[2] ? "" : props.financialRatiosAnalysis.liquidityRatios.currentRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Quick ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.quickRatio[0] ? "" : props.financialRatiosAnalysis.liquidityRatios.quickRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.quickRatio[1] ? "" : props.financialRatiosAnalysis.liquidityRatios.quickRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.quickRatio[2] ? "" : props.financialRatiosAnalysis.liquidityRatios.quickRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Cash ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.cashRatio[0] ? "" : props.financialRatiosAnalysis.liquidityRatios.cashRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.cashRatio[1] ? "" : props.financialRatiosAnalysis.liquidityRatios.cashRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.liquidityRatios.cashRatio[2] ? "" : props.financialRatiosAnalysis.liquidityRatios.cashRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Efficiency (activity) ratios</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Inventory turnover (days)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.inventoryTurnover[0] ? "" : props.financialRatiosAnalysis.efficiencyRatios.inventoryTurnover[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.inventoryTurnover[1] ? "" : props.financialRatiosAnalysis.efficiencyRatios.inventoryTurnover[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.inventoryTurnover[2] ? "" : props.financialRatiosAnalysis.efficiencyRatios.inventoryTurnover[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Asset turnover (days)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.assetTurnover[0] ? "" : props.financialRatiosAnalysis.efficiencyRatios.assetTurnover[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.assetTurnover[1] ? "" : props.financialRatiosAnalysis.efficiencyRatios.assetTurnover[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.assetTurnover[2] ? "" : props.financialRatiosAnalysis.efficiencyRatios.assetTurnover[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Receivables turnover (days)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.receivablesTurnover[0] ? "" : props.financialRatiosAnalysis.efficiencyRatios.receivablesTurnover[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.receivablesTurnover[1] ? "" : props.financialRatiosAnalysis.efficiencyRatios.receivablesTurnover[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.receivablesTurnover[2] ? "" : props.financialRatiosAnalysis.efficiencyRatios.receivablesTurnover[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>EBIT (Earnings before interest and taxes)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.ebit[0] ? "" : props.financialRatiosAnalysis.efficiencyRatios.ebit[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.ebit[1] ? "" : props.financialRatiosAnalysis.efficiencyRatios.ebit[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.ebit[2] ? "" : props.financialRatiosAnalysis.efficiencyRatios.ebit[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>EBITDA (Earnings before interest, taxes, depreciation and amortization)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.ebitda[0] ? "" : props.financialRatiosAnalysis.efficiencyRatios.ebitda[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.ebitda[1] ? "" : props.financialRatiosAnalysis.efficiencyRatios.ebitda[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.efficiencyRatios.ebitda[2] ? "" : props.financialRatiosAnalysis.efficiencyRatios.ebitda[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Leverage (solvency) ratios</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Debt ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.leverageRatios.debtRatio[0] ? "" : props.financialRatiosAnalysis.leverageRatios.debtRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.leverageRatios.debtRatio[1] ? "" : props.financialRatiosAnalysis.leverageRatios.debtRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.leverageRatios.debtRatio[2] ? "" : props.financialRatiosAnalysis.leverageRatios.debtRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Debt-to-equity ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.leverageRatios.debtToEquityRatio[0] ? "" : props.financialRatiosAnalysis.leverageRatios.debtToEquityRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.leverageRatios.debtToEquityRatio[1] ? "" : props.financialRatiosAnalysis.leverageRatios.debtToEquityRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.leverageRatios.debtToEquityRatio[2] ? "" : props.financialRatiosAnalysis.leverageRatios.debtToEquityRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Interest coverage ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.leverageRatios.interestCoverageRatio[0] ? "" : props.financialRatiosAnalysis.leverageRatios.interestCoverageRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.leverageRatios.interestCoverageRatio[1] ? "" : props.financialRatiosAnalysis.leverageRatios.interestCoverageRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.leverageRatios.interestCoverageRatio[2] ? "" : props.financialRatiosAnalysis.leverageRatios.interestCoverageRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerowbreak"></tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Market (price) ratios</th>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatacenter"></td>
                                                        <td className="financialstatementstabledatalast"></td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Earnings per share (basic)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.earningsPerShareBasic[0] ? "" : props.financialRatiosAnalysis.marketRatios.earningsPerShareBasic[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.earningsPerShareBasic[1] ? "" : props.financialRatiosAnalysis.marketRatios.earningsPerShareBasic[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.marketRatios.earningsPerShareBasic[2] ? "" : props.financialRatiosAnalysis.marketRatios.earningsPerShareBasic[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Earnings per share (diluted)</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.earningsPerShareDiluted[0] ? "" : props.financialRatiosAnalysis.marketRatios.earningsPerShareDiluted[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.earningsPerShareDiluted[1] ? "" : props.financialRatiosAnalysis.marketRatios.earningsPerShareDiluted[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.marketRatios.earningsPerShareDiluted[2] ? "" : props.financialRatiosAnalysis.marketRatios.earningsPerShareDiluted[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Dividend payout ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.dividendPayoutRatio[0] ? "" : props.financialRatiosAnalysis.marketRatios.dividendPayoutRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.dividendPayoutRatio[1] ? "" : props.financialRatiosAnalysis.marketRatios.dividendPayoutRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.marketRatios.dividendPayoutRatio[2] ? "" : props.financialRatiosAnalysis.marketRatios.dividendPayoutRatio[2]}
                                                        </td>
                                                    </tr>

                                                    <tr className="financialstatementstablerow">
                                                        <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Price-to-earnings ratio</th>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.priceToEarningsRatio[0] ? "" : props.financialRatiosAnalysis.marketRatios.priceToEarningsRatio[0]}
                                                        </td>
                                                        <td className="financialstatementstabledatacenter">
                                                            {!props.financialRatiosAnalysis.marketRatios.priceToEarningsRatio[1] ? "" : props.financialRatiosAnalysis.marketRatios.priceToEarningsRatio[1]}
                                                        </td>
                                                        <td className="financialstatementstabledatalast">
                                                            {!props.financialRatiosAnalysis.marketRatios.priceToEarningsRatio[2] ? "" : props.financialRatiosAnalysis.marketRatios.priceToEarningsRatio[2]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>)
            }
        </>
    )
}

export default ViewFinancials;