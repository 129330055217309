import React, { useReducer, useState } from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Col, Card, Form, ProgressBar, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline, IoMdAdd } from 'react-icons/io';

const CreateEditFinancials = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    const date = new Date();
    let year = date.getFullYear();

    return (

        <div className="ideacheckertabbody">
            <Col className="ideacheckerquestions">

                <Accordion className="companyaccordion" defaultActiveKey="monetizationModel">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="monetizationModel">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Monetization model
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="monetizationModel">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How do you monetize or charge for your product-or-service offering?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.monetizationModel.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="monetizationModel"
                                                                                value={props.state.monetizationModel[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update monetizationModel", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.monetizationModel.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove monetizationModel", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.monetizationModel.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add monetizationModel", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Are you building an annuity business, such as a movie streaming subscription service that continues for years?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.areYouBuildingAnAnnuityBusiness.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="areYouBuildingAnAnnuityBusiness"
                                                                                value={props.state.areYouBuildingAnAnnuityBusiness[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update areYouBuildingAnAnnuityBusiness", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.areYouBuildingAnAnnuityBusiness.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove areYouBuildingAnAnnuityBusiness", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.areYouBuildingAnAnnuityBusiness.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add areYouBuildingAnAnnuityBusiness", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Are you selling a durable product that is purchased only occasionally during a lifetime, such as a washing machine or a car?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.areYouSellingDurableProduct.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="areYouSellingDurableProduct"
                                                                                value={props.state.areYouSellingDurableProduct[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update areYouSellingDurableProduct", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.areYouSellingDurableProduct.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove areYouSellingDurableProduct", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.areYouSellingDurableProduct.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add areYouSellingDurableProduct", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are the margins?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.financialMargins.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="financialMargins"
                                                                                value={props.state.financialMargins[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update financialMargins", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.financialMargins.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove financialMargins", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.financialMargins.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add financialMargins", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are the financial metrics are you observing?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.observedUnitEconomics.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="observedUnitEconomics"
                                                                                value={props.state.observedUnitEconomics[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update observedUnitEconomics", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.observedUnitEconomics.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove observedUnitEconomics", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.observedUnitEconomics.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add observedUnitEconomics", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Do the financial metrics make sense for your industry and/or vertical market?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.doUnitEconomicsMakeSense.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="doUnitEconomicsMakeSense"
                                                                                value={props.state.doUnitEconomicsMakeSense[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update doUnitEconomicsMakeSense", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.doUnitEconomicsMakeSense.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove doUnitEconomicsMakeSense", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.doUnitEconomicsMakeSense.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add doUnitEconomicsMakeSense", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="pricingModel" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="pricingModel">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Pricing model
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="pricingModel">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your pricing model?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.pricingModel.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="pricingModel"
                                                                                value={props.state.pricingModel[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update pricingModel", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.pricingModel.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove pricingModel", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.pricingModel.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add pricingModel", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Will your pricing model be fixed or tiered or variable depending on customer demand?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.pricingModelCustomerDemand.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="pricingModelCustomerDemand"
                                                                                value={props.state.pricingModelCustomerDemand[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update pricingModelCustomerDemand", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.pricingModelCustomerDemand.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove pricingModelCustomerDemand", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.pricingModelCustomerDemand.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add pricingModelCustomerDemand", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            At what price point are you offering your product-or-service?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.pricePointProductOffer.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="pricePointProductOffer"
                                                                                value={props.state.pricePointProductOffer[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update pricePointProductOffer", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.pricePointProductOffer.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove pricePointProductOffer", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.pricePointProductOffer.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add pricePointProductOffer", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Will there be an established price?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.isPriceEstablished.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="isPriceEstablished"
                                                                                value={props.state.isPriceEstablished[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update isPriceEstablished", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.isPriceEstablished.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove isPriceEstablished", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.isPriceEstablished.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add isPriceEstablished", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your relevant pricing strategy?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.relevantPricingStrategy.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="relevantPricingStrategy"
                                                                                value={props.state.relevantPricingStrategy[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update relevantPricingStrategy", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.relevantPricingStrategy.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove relevantPricingStrategy", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.relevantPricingStrategy.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add relevantPricingStrategy", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is the appropriate pricing strategy?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.appropriatePricingStrategy.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="appropriatePricingStrategy"
                                                                                value={props.state.appropriatePricingStrategy[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update appropriatePricingStrategy", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.appropriatePricingStrategy.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove appropriatePricingStrategy", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.appropriatePricingStrategy.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add appropriatePricingStrategy", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="revenue" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="revenue">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Revenue
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="revenue">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your revenue?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.financialRevenue.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="financialRevenue"
                                                                                value={props.state.financialRevenue[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update financialRevenue", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.financialRevenue.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove financialRevenue", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.financialRevenue.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add financialRevenue", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How much money have you made or going to make from your target market?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.moneyMadeFromTargetMarket.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="moneyMadeFromTargetMarket"
                                                                                value={props.state.moneyMadeFromTargetMarket[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update moneyMadeFromTargetMarket", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.moneyMadeFromTargetMarket.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove moneyMadeFromTargetMarket", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.moneyMadeFromTargetMarket.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add moneyMadeFromTargetMarket", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            At what point do you expect the business to make money?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.whenIsBusinessExpectedToMakeMoney.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="whenIsBusinessExpectedToMakeMoney"
                                                                                value={props.state.whenIsBusinessExpectedToMakeMoney[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update whenIsBusinessExpectedToMakeMoney", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.whenIsBusinessExpectedToMakeMoney.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove whenIsBusinessExpectedToMakeMoney", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.whenIsBusinessExpectedToMakeMoney.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add whenIsBusinessExpectedToMakeMoney", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Will it take up to six months or more than two years?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.durationToMakeMoney.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="durationToMakeMoney"
                                                                                value={props.state.durationToMakeMoney[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update durationToMakeMoney", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.durationToMakeMoney.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove durationToMakeMoney", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.durationToMakeMoney.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add durationToMakeMoney", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            At what level of sales will you reach the break-even point?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.whenIsBusinessExpectedToReachBreakEven.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="whenIsBusinessExpectedToReachBreakEven"
                                                                                value={props.state.whenIsBusinessExpectedToReachBreakEven[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update whenIsBusinessExpectedToReachBreakEven", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.whenIsBusinessExpectedToReachBreakEven.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove whenIsBusinessExpectedToReachBreakEven", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.whenIsBusinessExpectedToReachBreakEven.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add whenIsBusinessExpectedToReachBreakEven", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            When do you anticipate to break-even?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.anticipateToBreakEven.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="anticipateToBreakEven"
                                                                                value={props.state.anticipateToBreakEven[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update anticipateToBreakEven", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.anticipateToBreakEven.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove anticipateToBreakEven", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.anticipateToBreakEven.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add anticipateToBreakEven", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your sales target based on projected year-on-year growth?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.salesTargetBasedOnProjectedGrowth.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="salesTargetBasedOnProjectedGrowth"
                                                                                value={props.state.salesTargetBasedOnProjectedGrowth[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update salesTargetBasedOnProjectedGrowth", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.salesTargetBasedOnProjectedGrowth.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove salesTargetBasedOnProjectedGrowth", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.salesTargetBasedOnProjectedGrowth.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add salesTargetBasedOnProjectedGrowth", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            After that, how long will it take to reach the next sales milestones?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howLongToReachNextSalesMilestone.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howLongToReachNextSalesMilestone"
                                                                                value={props.state.howLongToReachNextSalesMilestone[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howLongToReachNextSalesMilestone", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howLongToReachNextSalesMilestone.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howLongToReachNextSalesMilestone", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howLongToReachNextSalesMilestone.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howLongToReachNextSalesMilestone", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="costStructure" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="costStructure">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Cost structure
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="costStructure">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is the cost of acquiring a customer?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.costAcquiringCustomer.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="costAcquiringCustomer"
                                                                                value={props.state.costAcquiringCustomer[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update costAcquiringCustomer", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.costAcquiringCustomer.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove costAcquiringCustomer", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.costAcquiringCustomer.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add costAcquiringCustomer", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What do you think that customer will be worth over time with the small sample size of data that you may have?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howMuchWillCustomerBeWorth.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howMuchWillCustomerBeWorth"
                                                                                value={props.state.howMuchWillCustomerBeWorth[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howMuchWillCustomerBeWorth", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howMuchWillCustomerBeWorth.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howMuchWillCustomerBeWorth", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howMuchWillCustomerBeWorth.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howMuchWillCustomerBeWorth", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are your general costs assumptions based on the internal components of your business, such as variable and fixed costs, cost of capital, seasonal cash flow fluctuations, and so on?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.internalComponentsAssumptions.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="internalComponentsAssumptions"
                                                                                value={props.state.internalComponentsAssumptions[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update internalComponentsAssumptions", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.internalComponentsAssumptions.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove internalComponentsAssumptions", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.internalComponentsAssumptions.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add internalComponentsAssumptions", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="incomeStatement" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="incomeStatement">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Income statement
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="incomeStatement">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="financialstatements2">
                                                <div>
                                                    <div className="financialstatements">
                                                        <table className="financialstatementstable">
                                                            <thead>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th className="financialstatementstabledatacenter">{year - 2}</th>
                                                                    <th className="financialstatementstabledatacenter">{year - 1}</th>
                                                                    <th className="financialstatementstabledatalast">{year}</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                                </tr>
                                                            </thead>
                                                            {/* form fields */}
                                                            <tbody>
                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Revenue</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="revenueYear2" value={props.state.revenueYear2} onChange={(e) => editField({ type: "update revenueYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="revenueYear1" value={props.state.revenueYear1} onChange={(e) => editField({ type: "update revenueYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="revenueYear0" value={props.state.revenueYear0} onChange={(e) => editField({ type: "update revenueYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cost of revenue</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="costOfRevenueYear2" value={props.state.costOfRevenueYear2} onChange={(e) => editField({ type: "update costOfRevenueYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="costOfRevenueYear1" value={props.state.costOfRevenueYear1} onChange={(e) => editField({ type: "update costOfRevenueYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="costOfRevenueYear0" value={props.state.costOfRevenueYear0} onChange={(e) => editField({ type: "update costOfRevenueYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Gross profit</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="grossProfitYear2" value={props.state.grossProfitYear2} onChange={(e) => editField({ type: "update grossProfitYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="grossProfitYear1" value={props.state.grossProfitYear1} onChange={(e) => editField({ type: "update grossProfitYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="grossProfitYear0" value={props.state.grossProfitYear0} onChange={(e) => editField({ type: "update grossProfitYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Operating expenses:</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Research and development</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="researchAndDevelopmentYear2" value={props.state.researchAndDevelopmentYear2} onChange={(e) => editField({ type: "update researchAndDevelopmentYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="researchAndDevelopmentYear1" value={props.state.researchAndDevelopmentYear1} onChange={(e) => editField({ type: "update researchAndDevelopmentYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="researchAndDevelopmentYear0" value={props.state.researchAndDevelopmentYear0} onChange={(e) => editField({ type: "update researchAndDevelopmentYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Sales and marketing</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="salesAndMarketingYear2" value={props.state.salesAndMarketingYear2} onChange={(e) => editField({ type: "update salesAndMarketingYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="salesAndMarketingYear1" value={props.state.salesAndMarketingYear1} onChange={(e) => editField({ type: "update salesAndMarketingYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="salesAndMarketingYear0" value={props.state.salesAndMarketingYear0} onChange={(e) => editField({ type: "update salesAndMarketingYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>General and administrative</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="generalAndAdministrativeYear2" value={props.state.generalAndAdministrativeYear2} onChange={(e) => editField({ type: "update generalAndAdministrativeYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="generalAndAdministrativeYear1" value={props.state.generalAndAdministrativeYear1} onChange={(e) => editField({ type: "update generalAndAdministrativeYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="generalAndAdministrativeYear0" value={props.state.generalAndAdministrativeYear0} onChange={(e) => editField({ type: "update generalAndAdministrativeYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total operating expenses</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalOperatingExpensesYear2" value={props.state.totalOperatingExpensesYear2} onChange={(e) => editField({ type: "update totalOperatingExpensesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalOperatingExpensesYear1" value={props.state.totalOperatingExpensesYear1} onChange={(e) => editField({ type: "update totalOperatingExpensesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalOperatingExpensesYear0" value={props.state.totalOperatingExpensesYear0} onChange={(e) => editField({ type: "update totalOperatingExpensesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Operating income / (loss)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="operatingIncomeYear2" value={props.state.operatingIncomeYear2} onChange={(e) => editField({ type: "update operatingIncomeYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="operatingIncomeYear1" value={props.state.operatingIncomeYear1} onChange={(e) => editField({ type: "update operatingIncomeYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="operatingIncomeYear0" value={props.state.operatingIncomeYear0} onChange={(e) => editField({ type: "update operatingIncomeYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Interest expense</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="interestExpenseYear2" value={props.state.interestExpenseYear2} onChange={(e) => editField({ type: "update interestExpenseYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="interestExpenseYear1" value={props.state.interestExpenseYear1} onChange={(e) => editField({ type: "update interestExpenseYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="interestExpenseYear0" value={props.state.interestExpenseYear0} onChange={(e) => editField({ type: "update interestExpenseYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other income / (loss), net</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="otherIncomeNetYear2" value={props.state.otherIncomeNetYear2} onChange={(e) => editField({ type: "update otherIncomeNetYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="otherIncomeNetYear1" value={props.state.otherIncomeNetYear1} onChange={(e) => editField({ type: "update otherIncomeNetYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="otherIncomeNetYear0" value={props.state.otherIncomeNetYear0} onChange={(e) => editField({ type: "update otherIncomeNetYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Income / (loss) before income taxes</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="incomeBeforeIncomeTaxesYear2" value={props.state.incomeBeforeIncomeTaxesYear2} onChange={(e) => editField({ type: "update incomeBeforeIncomeTaxesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="incomeBeforeIncomeTaxesYear1" value={props.state.incomeBeforeIncomeTaxesYear1} onChange={(e) => editField({ type: "update incomeBeforeIncomeTaxesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="incomeBeforeIncomeTaxesYear0" value={props.state.incomeBeforeIncomeTaxesYear0} onChange={(e) => editField({ type: "update incomeBeforeIncomeTaxesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Provision / (benefit) for income taxes</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="provisionForIncomeTaxesYear2" value={props.state.provisionForIncomeTaxesYear2} onChange={(e) => editField({ type: "update provisionForIncomeTaxesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="provisionForIncomeTaxesYear1" value={props.state.provisionForIncomeTaxesYear1} onChange={(e) => editField({ type: "update provisionForIncomeTaxesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="provisionForIncomeTaxesYear0" value={props.state.provisionForIncomeTaxesYear0} onChange={(e) => editField({ type: "update provisionForIncomeTaxesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net income / (net loss)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netIncomeNetLossYear2" value={props.state.netIncomeNetLossYear2} onChange={(e) => editField({ type: "update netIncomeYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netIncomeNetLossYear1" value={props.state.netIncomeNetLossYear1} onChange={(e) => editField({ type: "update netIncomeYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="netIncomeNetLossYear0" value={props.state.netIncomeNetLossYear0} onChange={(e) => editField({ type: "update netIncomeYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="balanceSheet" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="balanceSheet">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Balance sheet
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="balanceSheet">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="financialstatements2">
                                                <div>
                                                    <div className="financialstatements">
                                                        <table className="financialstatementstable">
                                                            <thead>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th className="financialstatementstabledatacenter">{year - 2}</th>
                                                                    <th className="financialstatementstabledatacenter">{year - 1}</th>
                                                                    <th className="financialstatementstabledatalast">{year}</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                                </tr>
                                                            </thead>
                                                            {/* form fields */}
                                                            <tbody>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={5} className="financialstatementstabledatalast">ASSETS</th>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Current assets:</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cash and cash equivalents</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashAndCashEquivalentsYear2" value={props.state.cashAndCashEquivalentsYear2} onChange={(e) => editField({ type: "update cashAndCashEquivalentsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashAndCashEquivalentsYear1" value={props.state.cashAndCashEquivalentsYear1} onChange={(e) => editField({ type: "update cashAndCashEquivalentsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="cashAndCashEquivalentsYear0" value={props.state.cashAndCashEquivalentsYear0} onChange={(e) => editField({ type: "update cashAndCashEquivalentsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Accounts receivable, net</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="accountsReceivableNetYear2" value={props.state.accountsReceivableNetYear2} onChange={(e) => editField({ type: "update accountsReceivableNetYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="accountsReceivableNetYear1" value={props.state.accountsReceivableNetYear1} onChange={(e) => editField({ type: "update accountsReceivableNetYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="accountsReceivableNetYear0" value={props.state.accountsReceivableNetYear0} onChange={(e) => editField({ type: "update accountsReceivableNetYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Inventory</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="inventoryYear2" value={props.state.inventoryYear2} onChange={(e) => editField({ type: "update inventoryYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="inventoryYear1" value={props.state.inventoryYear1} onChange={(e) => editField({ type: "update inventoryYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="inventoryYear0" value={props.state.inventoryYear0} onChange={(e) => editField({ type: "update inventoryYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other current assets</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="otherCurrentAssetsYear2" value={props.state.otherCurrentAssetsYear2} onChange={(e) => editField({ type: "update otherCurrentAssetsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="otherCurrentAssetsYear1" value={props.state.otherCurrentAssetsYear1} onChange={(e) => editField({ type: "update otherCurrentAssetsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="otherCurrentAssetsYear0" value={props.state.otherCurrentAssetsYear0} onChange={(e) => editField({ type: "update otherCurrentAssetsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total current assets</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalCurrentAssetsYear2" value={props.state.totalCurrentAssetsYear2} onChange={(e) => editField({ type: "update totalCurrentAssetsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalCurrentAssetsYear1" value={props.state.totalCurrentAssetsYear1} onChange={(e) => editField({ type: "update totalCurrentAssetsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalCurrentAssetsYear0" value={props.state.totalCurrentAssetsYear0} onChange={(e) => editField({ type: "update totalCurrentAssetsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Non-current assets:</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Property, plant, and equipment, net</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="propertyPlantAndEquipmentNetYear2" value={props.state.propertyPlantAndEquipmentNetYear2} onChange={(e) => editField({ type: "update propertyPlantAndEquipmentNetYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="propertyPlantAndEquipmentNetYear1" value={props.state.propertyPlantAndEquipmentNetYear1} onChange={(e) => editField({ type: "update propertyPlantAndEquipmentNetYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="propertyPlantAndEquipmentNetYear0" value={props.state.propertyPlantAndEquipmentNetYear0} onChange={(e) => editField({ type: "update propertyPlantAndEquipmentNetYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Intangible assets, net</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="intangibleAssetsNetYear2" value={props.state.intangibleAssetsNetYear2} onChange={(e) => editField({ type: "update intangibleAssetsNetYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="intangibleAssetsNetYear1" value={props.state.intangibleAssetsNetYear1} onChange={(e) => editField({ type: "update intangibleAssetsNetYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="intangibleAssetsNetYear0" value={props.state.intangibleAssetsNetYear0} onChange={(e) => editField({ type: "update intangibleAssetsNetYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other non-current assets</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="otherNonCurrentAssetsYear2" value={props.state.otherNonCurrentAssetsYear2} onChange={(e) => editField({ type: "update otherNonCurrentAssetsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="otherNonCurrentAssetsYear1" value={props.state.otherNonCurrentAssetsYear1} onChange={(e) => editField({ type: "update otherNonCurrentAssetsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="otherNonCurrentAssetsYear0" value={props.state.otherNonCurrentAssetsYear0} onChange={(e) => editField({ type: "update otherNonCurrentAssetsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent">Total non-current assets</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalNonCurrentAssetsYear2" value={props.state.totalNonCurrentAssetsYear2} onChange={(e) => editField({ type: "update totalNonCurrentAssetsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalNonCurrentAssetsYear1" value={props.state.totalNonCurrentAssetsYear1} onChange={(e) => editField({ type: "update totalNonCurrentAssetsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalNonCurrentAssetsYear0" value={props.state.totalNonCurrentAssetsYear0} onChange={(e) => editField({ type: "update totalNonCurrentAssetsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total assets</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalAssetsYear2" value={props.state.totalAssetsYear2} onChange={(e) => editField({ type: "update totalAssetsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalAssetsYear1" value={props.state.totalAssetsYear1} onChange={(e) => editField({ type: "update totalAssetsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalAssetsYear0" value={props.state.totalAssetsYear0} onChange={(e) => editField({ type: "update totalAssetsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={5} className="financialstatementstabledatalast">LIABILITIES & SHAREHOLDERS' EQUITY</th>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Current liabilities:</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Accounts payable</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="accountsPayableYear2" value={props.state.accountsPayableYear2} onChange={(e) => editField({ type: "update accountsPayableYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="accountsPayableYear1" value={props.state.accountsPayableYear1} onChange={(e) => editField({ type: "update accountsPayableYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="accountsPayableYear0" value={props.state.accountsPayableYear0} onChange={(e) => editField({ type: "update accountsPayableYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Accrued and other</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="accruedAndOtherYear2" value={props.state.accruedAndOtherYear2} onChange={(e) => editField({ type: "update accruedAndOtherYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="accruedAndOtherYear1" value={props.state.accruedAndOtherYear1} onChange={(e) => editField({ type: "update accruedAndOtherYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="accruedAndOtherYear0" value={props.state.accruedAndOtherYear0} onChange={(e) => editField({ type: "update accruedAndOtherYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Short-term debt</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="shortTermDebtYear2" value={props.state.shortTermDebtYear2} onChange={(e) => editField({ type: "update shortTermDebtYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="shortTermDebtYear1" value={props.state.shortTermDebtYear1} onChange={(e) => editField({ type: "update shortTermDebtYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="shortTermDebtYear0" value={props.state.shortTermDebtYear0} onChange={(e) => editField({ type: "update shortTermDebtYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total current liabilities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalCurrentLiabilitiesYear2" value={props.state.totalCurrentLiabilitiesYear2} onChange={(e) => editField({ type: "update totalCurrentLiabilitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalCurrentLiabilitiesYear1" value={props.state.totalCurrentLiabilitiesYear1} onChange={(e) => editField({ type: "update totalCurrentLiabilitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalCurrentLiabilitiesYear0" value={props.state.totalCurrentLiabilitiesYear0} onChange={(e) => editField({ type: "update totalCurrentLiabilitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft">Non-current liabilities:</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Long-term debt</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="longTermDebtYear2" value={props.state.longTermDebtYear2} onChange={(e) => editField({ type: "update longTermDebtYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="longTermDebtYear1" value={props.state.longTermDebtYear1} onChange={(e) => editField({ type: "update longTermDebtYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="longTermDebtYear0" value={props.state.longTermDebtYear0} onChange={(e) => editField({ type: "update longTermDebtYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Other liabilities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="otherLiabilitiesYear2" value={props.state.otherLiabilitiesYear2} onChange={(e) => editField({ type: "update otherLiabilitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="otherLiabilitiesYear1" value={props.state.otherLiabilitiesYear1} onChange={(e) => editField({ type: "update otherLiabilitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="otherLiabilitiesYear0" value={props.state.otherLiabilitiesYear0} onChange={(e) => editField({ type: "update otherLiabilitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total non-current liabilities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalNonCurrentLiabilitiesYear2" value={props.state.totalNonCurrentLiabilitiesYear2} onChange={(e) => editField({ type: "update totalNonCurrentLiabilitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalNonCurrentLiabilitiesYear1" value={props.state.totalNonCurrentLiabilitiesYear1} onChange={(e) => editField({ type: "update totalNonCurrentLiabilitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalNonCurrentLiabilitiesYear0" value={props.state.totalNonCurrentLiabilitiesYear0} onChange={(e) => editField({ type: "update totalNonCurrentLiabilitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total liabilities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalLiabilitiesYear2" value={props.state.totalLiabilitiesYear2} onChange={(e) => editField({ type: "update totalLiabilitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalLiabilitiesYear1" value={props.state.totalLiabilitiesYear1} onChange={(e) => editField({ type: "update totalLiabilitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalLiabilitiesYear0" value={props.state.totalLiabilitiesYear0} onChange={(e) => editField({ type: "update totalLiabilitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={5} className="financialstatementstabledatalast">SHAREHOLDERS' EQUITY</th>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Equity capital</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="equityCapitalYear2" value={props.state.equityCapitalYear2} onChange={(e) => editField({ type: "update equityCapitalYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="equityCapitalYear1" value={props.state.equityCapitalYear1} onChange={(e) => editField({ type: "update equityCapitalYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="equityCapitalYear0" value={props.state.equityCapitalYear0} onChange={(e) => editField({ type: "update equityCapitalYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Retained earnings / (accumulated deficit)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="retainedEarningsYear2" value={props.state.retainedEarningsYear2} onChange={(e) => editField({ type: "update retainedEarningsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="retainedEarningsYear1" value={props.state.retainedEarningsYear1} onChange={(e) => editField({ type: "update retainedEarningsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="retainedEarningsYear0" value={props.state.retainedEarningsYear0} onChange={(e) => editField({ type: "update retainedEarningsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Additional paid-in capital</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="additionalPaidInCapitalYear2" value={props.state.additionalPaidInCapitalYear2} onChange={(e) => editField({ type: "update additionalPaidInCapitalYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="additionalPaidInCapitalYear1" value={props.state.additionalPaidInCapitalYear1} onChange={(e) => editField({ type: "update additionalPaidInCapitalYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="additionalPaidInCapitalYear0" value={props.state.additionalPaidInCapitalYear0} onChange={(e) => editField({ type: "update additionalPaidInCapitalYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent companytitlecard">Total shareholders' equity</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalShareholdersEquityYear2" value={props.state.totalShareholdersEquityYear2} onChange={(e) => editField({ type: "update totalShareholdersEquityYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalShareholdersEquityYear1" value={props.state.totalShareholdersEquityYear1} onChange={(e) => editField({ type: "update totalShareholdersEquityYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalShareholdersEquityYear0" value={props.state.totalShareholdersEquityYear0} onChange={(e) => editField({ type: "update totalShareholdersEquityYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total liabilities and shareholders' equity</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalLiabilitiesAndShareholdersEquityYear2" value={props.state.totalLiabilitiesAndShareholdersEquityYear2} onChange={(e) => editField({ type: "update totalLiabilitiesAndShareholdersEquityYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalLiabilitiesAndShareholdersEquityYear1" value={props.state.totalLiabilitiesAndShareholdersEquityYear1} onChange={(e) => editField({ type: "update totalLiabilitiesAndShareholdersEquityYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalLiabilitiesAndShareholdersEquityYear0" value={props.state.totalLiabilitiesAndShareholdersEquityYear0} onChange={(e) => editField({ type: "update totalLiabilitiesAndShareholdersEquityYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="cashFlowStatement" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="cashFlowStatement">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Cash flow statement
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="cashFlowStatement">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="financialstatements2">
                                                <div>
                                                    <div className="financialstatements">
                                                        <table className="financialstatementstable">
                                                            <thead>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th className="financialstatementstabledatacenter">{year - 2}</th>
                                                                    <th className="financialstatementstabledatacenter">{year - 1}</th>
                                                                    <th className="financialstatementstabledatalast">{year}</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                                </tr>
                                                            </thead>
                                                            {/* form fields */}
                                                            <tbody>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={5} className="financialstatementstabledatalast">CASH FLOW FROM OPERATING ACTIVITIES</th>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Net income / (loss)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netIncomeYear2" value={props.state.netIncomeYear2} onChange={(e) => editField({ type: "update netIncomeYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netIncomeYear1" value={props.state.netIncomeYear1} onChange={(e) => editField({ type: "update netIncomeYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="netIncomeYear0" value={props.state.netIncomeYear0} onChange={(e) => editField({ type: "update netIncomeYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft"><span style={{ fontFamily: "AileronItalic", fontWeight: "100" }}>Adjustments to reconcile net income / (loss) to net cash provided by / (used in) operating activities:</span></th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Depreciation and amortization</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="depreciationAndAmortizationYear2" value={props.state.depreciationAndAmortizationYear2} onChange={(e) => editField({ type: "update depreciationAndAmortizationYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="depreciationAndAmortizationYear1" value={props.state.depreciationAndAmortizationYear1} onChange={(e) => editField({ type: "update depreciationAndAmortizationYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="depreciationAndAmortizationYear0" value={props.state.depreciationAndAmortizationYear0} onChange={(e) => editField({ type: "update depreciationAndAmortizationYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Changes in assets and liabilities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="changesInAssetsAndLiabilitiesYear2" value={props.state.changesInAssetsAndLiabilitiesYear2} onChange={(e) => editField({ type: "update changesInAssetsAndLiabilitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="changesInAssetsAndLiabilitiesYear1" value={props.state.changesInAssetsAndLiabilitiesYear1} onChange={(e) => editField({ type: "update changesInAssetsAndLiabilitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="changesInAssetsAndLiabilitiesYear0" value={props.state.changesInAssetsAndLiabilitiesYear0} onChange={(e) => editField({ type: "update changesInAssetsAndLiabilitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net cash provided by / (used in) operating activities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netCashProvidedByOperatingActivitiesYear2" value={props.state.netCashProvidedByOperatingActivitiesYear2} onChange={(e) => editField({ type: "update netCashProvidedByOperatingActivitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netCashProvidedByOperatingActivitiesYear1" value={props.state.netCashProvidedByOperatingActivitiesYear1} onChange={(e) => editField({ type: "update netCashProvidedByOperatingActivitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="netCashProvidedByOperatingActivitiesYear0" value={props.state.netCashProvidedByOperatingActivitiesYear0} onChange={(e) => editField({ type: "update netCashProvidedByOperatingActivitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={5} className="financialstatementstabledatalast">CASH FLOW FROM INVESTING ACTIVITIES</th>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Capital expenditure for property, plant, and equipment</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="capitalExpenditureYear2" value={props.state.capitalExpenditureYear2} onChange={(e) => editField({ type: "update capitalExpenditureYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="capitalExpenditureYear1" value={props.state.capitalExpenditureYear1} onChange={(e) => editField({ type: "update capitalExpenditureYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="capitalExpenditureYear0" value={props.state.capitalExpenditureYear0} onChange={(e) => editField({ type: "update capitalExpenditureYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Capitalized software development costs</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="capitalizedSoftwareDevelopmentCostsYear2" value={props.state.capitalizedSoftwareDevelopmentCostsYear2} onChange={(e) => editField({ type: "update capitalizedSoftwareDevelopmentCostsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="capitalizedSoftwareDevelopmentCostsYear1" value={props.state.capitalizedSoftwareDevelopmentCostsYear1} onChange={(e) => editField({ type: "update capitalizedSoftwareDevelopmentCostsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="capitalizedSoftwareDevelopmentCostsYear0" value={props.state.capitalizedSoftwareDevelopmentCostsYear0} onChange={(e) => editField({ type: "update capitalizedSoftwareDevelopmentCostsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Maturities and sales of investments</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="maturitiesAndSalesOfInvestmentsYear2" value={props.state.maturitiesAndSalesOfInvestmentsYear2} onChange={(e) => editField({ type: "update maturitiesAndSalesOfInvestmentsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="maturitiesAndSalesOfInvestmentsYear1" value={props.state.maturitiesAndSalesOfInvestmentsYear1} onChange={(e) => editField({ type: "update maturitiesAndSalesOfInvestmentsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="maturitiesAndSalesOfInvestmentsYear0" value={props.state.maturitiesAndSalesOfInvestmentsYear0} onChange={(e) => editField({ type: "update maturitiesAndSalesOfInvestmentsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cash paid for acquisition of businesses / (net of cash acquired)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="cashPaidForAcquisitionOfBusinessYear2" value={props.state.cashPaidForAcquisitionOfBusinessYear2} onChange={(e) => editField({ type: "update cashPaidForAcquisitionOfBusinessYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="cashPaidForAcquisitionOfBusinessYear1" value={props.state.cashPaidForAcquisitionOfBusinessYear1} onChange={(e) => editField({ type: "update cashPaidForAcquisitionOfBusinessYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="cashPaidForAcquisitionOfBusinessYear0" value={props.state.cashPaidForAcquisitionOfBusinessYear0} onChange={(e) => editField({ type: "update cashPaidForAcquisitionOfBusinessYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net cash provided by / (used in) investing activities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netCashProvidedByInvestingActivitiesYear2" value={props.state.netCashProvidedByInvestingActivitiesYear2} onChange={(e) => editField({ type: "update netCashProvidedByInvestingActivitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="netCashProvidedByInvestingActivitiesYear1" value={props.state.netCashProvidedByInvestingActivitiesYear1} onChange={(e) => editField({ type: "update netCashProvidedByInvestingActivitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="netCashProvidedByInvestingActivitiesYear0" value={props.state.netCashProvidedByInvestingActivitiesYear0} onChange={(e) => editField({ type: "update netCashProvidedByInvestingActivitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={5} className="financialstatementstabledatalast">CASH FLOW FROM FINANCING ACTIVITIES</th>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Proceeds from issuance / (repayment) of debt</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="proceedsFromIssuanceOfDebtYear2" value={props.state.proceedsFromIssuanceOfDebtYear2} onChange={(e) => editField({ type: "update revenueproceedsFromIssuanceOfDebtYear2Year2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="proceedsFromIssuanceOfDebtYear1" value={props.state.proceedsFromIssuanceOfDebtYear1} onChange={(e) => editField({ type: "update proceedsFromIssuanceOfDebtYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="proceedsFromIssuanceOfDebtYear0" value={props.state.proceedsFromIssuanceOfDebtYear0} onChange={(e) => editField({ type: "update proceedsFromIssuanceOfDebtYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Proceeds from issuance / (repayment) of equity</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="proceedsFromIssuanceOfEquityYear2" value={props.state.proceedsFromIssuanceOfEquityYear2} onChange={(e) => editField({ type: "update proceedsFromIssuanceOfEquityYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="text" name="proceedsFromIssuanceOfEquityYear1" value={props.state.proceedsFromIssuanceOfEquityYear1} onChange={(e) => editField({ type: "update proceedsFromIssuanceOfEquityYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="text" name="proceedsFromIssuanceOfEquityYear0" value={props.state.proceedsFromIssuanceOfEquityYear0} onChange={(e) => editField({ type: "update proceedsFromIssuanceOfEquityYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Debt-related costs and other, net</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="debtRelatedCostsAndOtherNetYear2" value={props.state.debtRelatedCostsAndOtherNetYear2} onChange={(e) => editField({ type: "update debtRelatedCostsAndOtherNetYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="debtRelatedCostsAndOtherNetYear1" value={props.state.debtRelatedCostsAndOtherNetYear1} onChange={(e) => editField({ type: "update debtRelatedCostsAndOtherNetYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="debtRelatedCostsAndOtherNetYear0" value={props.state.debtRelatedCostsAndOtherNetYear0} onChange={(e) => editField({ type: "update debtRelatedCostsAndOtherNetYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Net cash provided by (used in) financing activities</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="netCashProvidedByFinancingActivitiesYear2" value={props.state.netCashProvidedByFinancingActivitiesYear2} onChange={(e) => editField({ type: "update netCashProvidedByFinancingActivitiesYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="netCashProvidedByFinancingActivitiesYear1" value={props.state.netCashProvidedByFinancingActivitiesYear1} onChange={(e) => editField({ type: "update netCashProvidedByFinancingActivitiesYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="netCashProvidedByFinancingActivitiesYear0" value={props.state.netCashProvidedByFinancingActivitiesYear0} onChange={(e) => editField({ type: "update netCashProvidedByFinancingActivitiesYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Changes in cash during the period</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="changesInCashDuringPeriodYear2" value={props.state.changesInCashDuringPeriodYear2} onChange={(e) => editField({ type: "update changesInCashDuringPeriodYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="changesInCashDuringPeriodYear1" value={props.state.changesInCashDuringPeriodYear1} onChange={(e) => editField({ type: "update changesInCashDuringPeriodYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="changesInCashDuringPeriodYear0" value={props.state.changesInCashDuringPeriodYear0} onChange={(e) => editField({ type: "update changesInCashDuringPeriodYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft" style={{ fontWeight: "100" }}>Cash at the beginning of the period</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashAtBeginningOfPeriodYear2" value={props.state.cashAtBeginningOfPeriodYear2} onChange={(e) => editField({ type: "update cashAtBeginningOfPeriodYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashAtBeginningOfPeriodYear1" value={props.state.cashAtBeginningOfPeriodYear1} onChange={(e) => editField({ type: "update cashAtBeginningOfPeriodYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="cashAtBeginningOfPeriodYear0" value={props.state.cashAtBeginningOfPeriodYear0} onChange={(e) => editField({ type: "update cashAtBeginningOfPeriodYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Cash at the end of the period</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashAtEndOfPeriodYear2" value={props.state.cashAtEndOfPeriodYear2} onChange={(e) => editField({ type: "update cashAtEndOfPeriodYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashAtEndOfPeriodYear1" value={props.state.cashAtEndOfPeriodYear1} onChange={(e) => editField({ type: "update cashAtEndOfPeriodYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="cashAtEndOfPeriodYear0" value={props.state.cashAtEndOfPeriodYear0} onChange={(e) => editField({ type: "update cashAtEndOfPeriodYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="breakEvenAnalysis" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="breakEvenAnalysis">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Break-even analysis
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="breakEvenAnalysis">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="financialstatements2">
                                                <div>
                                                    <div className="financialstatements">
                                                        <table className="financialstatementstable">
                                                            <thead>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th className="financialstatementstabledatacenter">{year - 2}</th>
                                                                    <th className="financialstatementstabledatacenter">{year - 1}</th>
                                                                    <th className="financialstatementstabledatalast">{year}</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                                </tr>
                                                            </thead>
                                                            {/* form fields */}
                                                            <tbody>
                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Time frame (monthly / yearly)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="timeFrameYear2" value={props.state.timeFrameYear2} onChange={(e) => editField({ type: "update timeFrameYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="timeFrameYear1" value={props.state.timeFrameYear1} onChange={(e) => editField({ type: "update timeFrameYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="timeFrameYear0" value={props.state.timeFrameYear0} onChange={(e) => editField({ type: "update timeFrameYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Average price of each product or service sold (in USD)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="averagePriceSoldYear2" value={props.state.averagePriceSoldYear2} onChange={(e) => editField({ type: "update averagePriceSoldYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="averagePriceSoldYear1" value={props.state.averagePriceSoldYear1} onChange={(e) => editField({ type: "update averagePriceSoldYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="averagePriceSoldYear0" value={props.state.averagePriceSoldYear0} onChange={(e) => editField({ type: "update averagePriceSoldYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Average cost of each product or service to make or deliver (in USD)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="averageCostToMakeOrDeliverYear2" value={props.state.averageCostToMakeOrDeliverYear2} onChange={(e) => editField({ type: "update averageCostToMakeOrDeliverYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="averageCostToMakeOrDeliverYear1" value={props.state.averageCostToMakeOrDeliverYear1} onChange={(e) => editField({ type: "update averageCostToMakeOrDeliverYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="averageCostToMakeOrDeliverYear0" value={props.state.averageCostToMakeOrDeliverYear0} onChange={(e) => editField({ type: "update averageCostToMakeOrDeliverYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Fixed costs for the month or year (in USD)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="fixedCostsforMonthOrYearYear2" value={props.state.fixedCostsforMonthOrYearYear2} onChange={(e) => editField({ type: "update fixedCostsforMonthOrYearYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="fixedCostsforMonthOrYearYear1" value={props.state.fixedCostsforMonthOrYearYear1} onChange={(e) => editField({ type: "update fixedCostsforMonthOrYearYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="fixedCostsforMonthOrYearYear0" value={props.state.fixedCostsforMonthOrYearYear0} onChange={(e) => editField({ type: "update fixedCostsforMonthOrYearYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Percentage of price that is profit (in %)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="percentageOfPriceProfitYear2" value={props.state.percentageOfPriceProfitYear2} onChange={(e) => editField({ type: "update percentageOfPriceProfitYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="percentageOfPriceProfitYear1" value={props.state.percentageOfPriceProfitYear1} onChange={(e) => editField({ type: "update percentageOfPriceProfitYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="percentageOfPriceProfitYear0" value={props.state.percentageOfPriceProfitYear0} onChange={(e) => editField({ type: "update percentageOfPriceProfitYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Total sales needed to break even (in USD)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalSalesNeededToBreakEvenYear2" value={props.state.totalSalesNeededToBreakEvenYear2} onChange={(e) => editField({ type: "update totalSalesNeededToBreakEvenYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="totalSalesNeededToBreakEvenYear1" value={props.state.totalSalesNeededToBreakEvenYear1} onChange={(e) => editField({ type: "update totalSalesNeededToBreakEvenYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="totalSalesNeededToBreakEvenYear0" value={props.state.totalSalesNeededToBreakEvenYear0} onChange={(e) => editField({ type: "update totalSalesNeededToBreakEvenYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Number of units sold needed to break even (in USD)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="numberOfUnitsSoldNeededToBreakEvenYear2" value={props.state.numberOfUnitsSoldNeededToBreakEvenYear2} onChange={(e) => editField({ type: "update numberOfUnitsSoldNeededToBreakEvenYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="numberOfUnitsSoldNeededToBreakEvenYear1" value={props.state.numberOfUnitsSoldNeededToBreakEvenYear1} onChange={(e) => editField({ type: "update numberOfUnitsSoldNeededToBreakEvenYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="numberOfUnitsSoldNeededToBreakEvenYear0" value={props.state.numberOfUnitsSoldNeededToBreakEvenYear0} onChange={(e) => editField({ type: "update numberOfUnitsSoldNeededToBreakEvenYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="financialRatiosAnalysis" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="financialRatiosAnalysis">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Financial ratios analysis
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="financialRatiosAnalysis">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">

                                    <Form>
                                        <Form.Group>
                                            <div className="financialstatements2">
                                                <div>
                                                    <div className="financialstatements">
                                                        <table className="financialstatementstable">
                                                            <thead>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">Year ended December 31,</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th className="financialstatementstabledatacenter">{year - 2}</th>
                                                                    <th className="financialstatementstabledatacenter">{year - 1}</th>
                                                                    <th className="financialstatementstabledatalast">{year}</th>
                                                                </tr>
                                                                <tr className="financialstatementstablerowbreak">
                                                                    <th colSpan={2} className="financialstatementstabledatacenter" style={{ borderRight: "none" }}></th>
                                                                    <th colSpan={3} className="financialstatementstabledatalast">(in thousands)</th>
                                                                </tr>
                                                            </thead>
                                                            {/* form fields */}
                                                            <tbody>
                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Profitability ratios</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Profit margin</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="profitMarginYear2" value={props.state.profitMarginYear2} onChange={(e) => editField({ type: "update profitMarginYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="profitMarginYear1" value={props.state.profitMarginYear1} onChange={(e) => editField({ type: "update profitMarginYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="profitMarginYear0" value={props.state.profitMarginYear0} onChange={(e) => editField({ type: "update profitMarginYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Return on equity (ROE), after tax (in %)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="returnOnEquityAfterTaxYear2" value={props.state.returnOnEquityAfterTaxYear2} onChange={(e) => editField({ type: "update returnOnEquityAfterTaxYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="returnOnEquityAfterTaxYear1" value={props.state.returnOnEquityAfterTaxYear1} onChange={(e) => editField({ type: "update returnOnEquityAfterTaxYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="returnOnEquityAfterTaxYear0" value={props.state.returnOnEquityAfterTaxYear0} onChange={(e) => editField({ type: "update returnOnEquityAfterTaxYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Return on assets (ROA) (in %)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="returnOnAssetsYear2" value={props.state.returnOnAssetsYear2} onChange={(e) => editField({ type: "update returnOnAssetsYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="returnOnAssetsYear1" value={props.state.returnOnAssetsYear1} onChange={(e) => editField({ type: "update returnOnAssetsYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="returnOnAssetsYear0" value={props.state.returnOnAssetsYear0} onChange={(e) => editField({ type: "update returnOnAssetsYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Gross margin</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="grossMarginYear2" value={props.state.grossMarginYear2} onChange={(e) => editField({ type: "update grossMarginYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="grossMarginYear1" value={props.state.grossMarginYear1} onChange={(e) => editField({ type: "update grossMarginYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="grossMarginYear0" value={props.state.grossMarginYear0} onChange={(e) => editField({ type: "update grossMarginYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Gross profit margin (in %)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="grossProfitMarginYear2" value={props.state.grossProfitMarginYear2} onChange={(e) => editField({ type: "update grossProfitMarginYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="grossProfitMarginYear1" value={props.state.grossProfitMarginYear1} onChange={(e) => editField({ type: "update grossProfitMarginYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="grossProfitMarginYear0" value={props.state.grossProfitMarginYear0} onChange={(e) => editField({ type: "update grossProfitMarginYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Net profit margin (in %)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="netProfitMarginYear2" value={props.state.netProfitMarginYear2} onChange={(e) => editField({ type: "update netProfitMarginYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="netProfitMarginYear1" value={props.state.netProfitMarginYear1} onChange={(e) => editField({ type: "update netProfitMarginYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="netProfitMarginYear0" value={props.state.netProfitMarginYear0} onChange={(e) => editField({ type: "update netProfitMarginYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Operating margin (return on sales) (in %)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="operatingMarginYear2" value={props.state.operatingMarginYear2} onChange={(e) => editField({ type: "update operatingMarginYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="operatingMarginYear1" value={props.state.operatingMarginYear1} onChange={(e) => editField({ type: "update operatingMarginYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="operatingMarginYear0" value={props.state.operatingMarginYear0} onChange={(e) => editField({ type: "update operatingMarginYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Liquidity ratios</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Current ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="currentRatioYear2" value={props.state.currentRatioYear2} onChange={(e) => editField({ type: "update currentRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="currentRatioYear1" value={props.state.currentRatioYear1} onChange={(e) => editField({ type: "update currentRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="currentRatioYear0" value={props.state.currentRatioYear0} onChange={(e) => editField({ type: "update currentRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Quick ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="quickRatioYear2" value={props.state.quickRatioYear2} onChange={(e) => editField({ type: "update quickRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="quickRatioYear1" value={props.state.quickRatioYear1} onChange={(e) => editField({ type: "update quickRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="quickRatioYear0" value={props.state.quickRatioYear0} onChange={(e) => editField({ type: "update quickRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Cash ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashRatioYear2" value={props.state.cashRatioYear2} onChange={(e) => editField({ type: "update cashRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="cashRatioYear1" value={props.state.cashRatioYear1} onChange={(e) => editField({ type: "update cashRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="cashRatioYear0" value={props.state.cashRatioYear0} onChange={(e) => editField({ type: "update cashRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Efficiency (activity) ratios</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Inventory turnover (days)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="inventoryTurnoverYear2" value={props.state.inventoryTurnoverYear2} onChange={(e) => editField({ type: "update inventoryTurnoverYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="inventoryTurnoverYear1" value={props.state.inventoryTurnoverYear1} onChange={(e) => editField({ type: "update inventoryTurnoverYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="inventoryTurnoverYear0" value={props.state.inventoryTurnoverYear0} onChange={(e) => editField({ type: "update inventoryTurnoverYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Asset turnover (days)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="assetTurnoverYear2" value={props.state.assetTurnoverYear2} onChange={(e) => editField({ type: "update assetTurnoverYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="assetTurnoverYear1" value={props.state.assetTurnoverYear1} onChange={(e) => editField({ type: "update assetTurnoverYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="assetTurnoverYear0" value={props.state.assetTurnoverYear0} onChange={(e) => editField({ type: "update assetTurnoverYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Receivables turnover (days)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="receivablesTurnoverYear2" value={props.state.receivablesTurnoverYear2} onChange={(e) => editField({ type: "update receivablesTurnoverYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="receivablesTurnoverYear1" value={props.state.receivablesTurnoverYear1} onChange={(e) => editField({ type: "update receivablesTurnoverYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="receivablesTurnoverYear0" value={props.state.receivablesTurnoverYear0} onChange={(e) => editField({ type: "update receivablesTurnoverYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>EBIT (Earnings before interest and taxes)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="ebitYear2" value={props.state.ebitYear2} onChange={(e) => editField({ type: "update ebitYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="ebitYear1" value={props.state.ebitYear1} onChange={(e) => editField({ type: "update ebitYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="ebitYear0" value={props.state.ebitYear0} onChange={(e) => editField({ type: "update ebitYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>EBITDA (Earnings before interest, taxes, depreciation and amortization)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="ebitdaYear2" value={props.state.ebitdaYear2} onChange={(e) => editField({ type: "update ebitdaYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="ebitdaYear1" value={props.state.ebitdaYear1} onChange={(e) => editField({ type: "update ebitdaYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="ebitdaYear0" value={props.state.ebitdaYear0} onChange={(e) => editField({ type: "update ebitdaYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Leverage (solvency) ratios</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Debt ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="debtRatioYear2" value={props.state.debtRatioYear2} onChange={(e) => editField({ type: "update debtRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="debtRatioYear1" value={props.state.debtRatioYear1} onChange={(e) => editField({ type: "update debtRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="debtRatioYear0" value={props.state.debtRatioYear0} onChange={(e) => editField({ type: "update debtRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Debt-to-equity ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="debtToEquityRatioYear2" value={props.state.debtToEquityRatioYear2} onChange={(e) => editField({ type: "update debtToEquityRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="debtToEquityRatioYear1" value={props.state.debtToEquityRatioYear1} onChange={(e) => editField({ type: "update debtToEquityRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="debtToEquityRatioYear0" value={props.state.debtToEquityRatioYear0} onChange={(e) => editField({ type: "update debtToEquityRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Interest coverage ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="interestCoverageRatioYear2" value={props.state.interestCoverageRatioYear2} onChange={(e) => editField({ type: "update interestCoverageRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="interestCoverageRatioYear1" value={props.state.interestCoverageRatioYear1} onChange={(e) => editField({ type: "update interestCoverageRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="interestCoverageRatioYear0" value={props.state.interestCoverageRatioYear0} onChange={(e) => editField({ type: "update interestCoverageRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerowbreak"></tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataleft companytitlecard">Market (price) ratios</th>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatacenter"></td>
                                                                    <td className="financialstatementstabledatalast"></td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Earnings per share (basic)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="earningsPerShareBasicYear2" value={props.state.earningsPerShareBasicYear2} onChange={(e) => editField({ type: "update earningsPerShareBasicYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="earningsPerShareBasicYear1" value={props.state.earningsPerShareBasicYear1} onChange={(e) => editField({ type: "update earningsPerShareBasicYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="earningsPerShareBasicYear0" value={props.state.earningsPerShareBasicYear0} onChange={(e) => editField({ type: "update earningsPerShareBasicYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Earnings per share (diluted)</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="earningsPerShareDilutedYear2" value={props.state.earningsPerShareDilutedYear2} onChange={(e) => editField({ type: "update earningsPerShareDilutedYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="earningsPerShareDilutedYear1" value={props.state.earningsPerShareDilutedYear1} onChange={(e) => editField({ type: "update earningsPerShareDilutedYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="earningsPerShareDilutedYear0" value={props.state.earningsPerShareDilutedYear0} onChange={(e) => editField({ type: "update earningsPerShareDilutedYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Dividend payout ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="dividendPayoutRatioYear2" value={props.state.dividendPayoutRatioYear2} onChange={(e) => editField({ type: "update dividendPayoutRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="dividendPayoutRatioYear1" value={props.state.dividendPayoutRatioYear1} onChange={(e) => editField({ type: "update dividendPayoutRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="dividendPayoutRatioYear0" value={props.state.dividendPayoutRatioYear0} onChange={(e) => editField({ type: "update dividendPayoutRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>

                                                                <tr className="financialstatementstablerow">
                                                                    <th colSpan={2} className="financialstatementstabledataindent" style={{ fontWeight: "100" }}>Price-to-earnings ratio</th>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="priceToEarningsRatioYear2" value={props.state.priceToEarningsRatioYear2} onChange={(e) => editField({ type: "update priceToEarningsRatioYear2", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatacenter">
                                                                        <Form.Control type="number" min="0" name="priceToEarningsRatioYear1" value={props.state.priceToEarningsRatioYear1} onChange={(e) => editField({ type: "update priceToEarningsRatioYear1", value: e.target.value })} />
                                                                    </td>
                                                                    <td className="financialstatementstabledatalast">
                                                                        <Form.Control type="number" min="0" name="priceToEarningsRatioYear0" value={props.state.priceToEarningsRatioYear0} onChange={(e) => editField({ type: "update priceToEarningsRatioYear0", value: e.target.value })} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </Col>

            <Col className="ideacheckerresults">
                <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats" >
                            <div className="companytitlecard">
                                Results
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="resultstats">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>

                                <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                    Financials score: {props.state.analysisResult?.financials.score
                                        ? props.state.analysisResult.financials.score > 0
                                            ? props.state.analysisResult.financials.score
                                            : 0
                                        : 0}%
                                </div>

                                <ProgressBar now={props.state.analysisResult?.financials.score ? props.state.analysisResult.financials.score : 0} variant="danger" style={{ height: "2rem" }} />

                                <br />

                                {/* {
                                    (ideaCheckerRes?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {ideaCheckerRes.error}
                                        </Alert>)
                                        : ""
                                } */}

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="notification" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification" >
                            <div className="companytitlecard">
                                Notification
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="notification">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                <span className="info-notes" style={{ fontSize: "16px" }}>
                                    Note:
                                    <ul style={{ paddingLeft: "17px" }}>
                                        <li id="notes-points">Write no less than 2 words for short answers</li>
                                        <li id="notes-points">Write more than 7 words for normal answers</li>
                                        <li id="notes-points">Due to large datasets, it may take up to 1 min to compute and show results.</li>
                                        <li id="notes-points">We use advanced real-time analytics and text analytics to analyze your inputs.</li>
                                    </ul>
                                </span>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                            <div className="companytitlecard">
                                Feedback
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="feedback">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                {!props.state.analysisResult
                                    ? ("")
                                    : (<div className="pitchdeckrecommended">
                                        Recommended readings:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {props.state.analysisResult?.financials.recommended_read.map((reading, index) =>
                                                <li key={index}>
                                                    <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>)
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </div>

    )

}

export default CreateEditFinancials;