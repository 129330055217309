import React, { useReducer } from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Col, Card, Form, ProgressBar, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline, IoMdAdd } from 'react-icons/io';

const CreateEditOperations = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    return (

        <div className="ideacheckertabbody">
            <Col className="ideacheckerquestions">

                <Accordion className="companyaccordion" defaultActiveKey="traction">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="traction">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Traction
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="traction">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your traction (KPI metrics)?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.kpiMetrics.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="kpiMetrics"
                                                                                value={props.state.kpiMetrics[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update kpiMetrics", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.kpiMetrics.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove kpiMetrics", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.kpiMetrics.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add kpiMetrics", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are the traction metrics that show what you have achieved so far?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.metricsThatShowAchievement.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="metricsThatShowAchievement"
                                                                                value={props.state.metricsThatShowAchievement[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update metricsThatShowAchievement", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.metricsThatShowAchievement.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove metricsThatShowAchievement", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.metricsThatShowAchievement.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add metricsThatShowAchievement", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are the week-over-week or month-over-month growth rate?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.growthRate.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="growthRate"
                                                                                value={props.state.growthRate[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update growthRate", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.growthRate.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove growthRate", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.growthRate.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add growthRate", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is there proof of latent demand?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.proofOfLatentDemand.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="proofOfLatentDemand"
                                                                                value={props.state.proofOfLatentDemand[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update proofOfLatentDemand", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.proofOfLatentDemand.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove proofOfLatentDemand", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.proofOfLatentDemand.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add proofOfLatentDemand", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is your business growing?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.isBusinessGrowing.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="isBusinessGrowing"
                                                                                value={props.state.isBusinessGrowing[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update isBusinessGrowing", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.isBusinessGrowing.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove isBusinessGrowing", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.isBusinessGrowing.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add isBusinessGrowing", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is your product-or-service self-perpetuating its growth?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.isProductSelfPerpetuatingGrowth.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="isProductSelfPerpetuatingGrowth"
                                                                                value={props.state.isProductSelfPerpetuatingGrowth[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update isProductSelfPerpetuatingGrowth", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.isProductSelfPerpetuatingGrowth.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove isProductSelfPerpetuatingGrowth", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.isProductSelfPerpetuatingGrowth.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add isProductSelfPerpetuatingGrowth", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Have you been able to accomplish with less money in less time?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.ableToAccomplishWithLess.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="ableToAccomplishWithLess"
                                                                                value={props.state.ableToAccomplishWithLess[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update ableToAccomplishWithLess", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.ableToAccomplishWithLess.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove ableToAccomplishWithLess", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.ableToAccomplishWithLess.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add ableToAccomplishWithLess", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </Col>

            <Col className="ideacheckerresults">
                <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats" >
                            <div className="companytitlecard">
                                Results
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="resultstats">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>

                                <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                    Operations score: {props.state.analysisResult?.operations.score
                                        ? props.state.analysisResult.operations.score > 0
                                            ? props.state.analysisResult.operations.score
                                            : 0
                                        : 0}%
                                </div>

                                <ProgressBar now={props.state.analysisResult?.operations.score ? props.state.analysisResult.operations.score : 0} variant="danger" style={{ height: "2rem" }} />

                                <br />

                                {/* {
                                    (ideaCheckerRes?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {ideaCheckerRes.error}
                                        </Alert>)
                                        : ""
                                } */}

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="notification" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification" >
                            <div className="companytitlecard">
                                Notification
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="notification">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                <span className="info-notes" style={{ fontSize: "16px" }}>
                                    Note:
                                    <ul style={{ paddingLeft: "17px" }}>
                                        <li id="notes-points">Write no less than 2 words for short answers</li>
                                        <li id="notes-points">Write more than 7 words for normal answers</li>
                                        <li id="notes-points">Due to large datasets, it may take up to 1 min to compute and show results.</li>
                                        <li id="notes-points">We use advanced real-time analytics and text analytics to analyze your inputs.</li>
                                    </ul>
                                </span>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                            <div className="companytitlecard">
                                Feedback
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="feedback">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                {!props.state.analysisResult
                                    ? ("")
                                    : (<div className="pitchdeckrecommended">
                                        Recommended readings:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {props.state.analysisResult?.operations.recommended_read.map((reading, index) =>
                                                <li key={index}>
                                                    <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>)
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </div>

    )

}

export default CreateEditOperations;