import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewBusiness(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="businessdescription">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="businessdescription">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Business description
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="businessdescription">
                        <Card.Body id="companyinformation">
                            <div>
                                <p className="companydetailtitle">Business description</p>
                                {!props?.businessDescription
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.businessDescription}</p>)
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="problem" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="problem">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Problem
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="problem">
                        <Card.Body id="companyinformation">
                            {!props?.customerPain
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What real-world problem are you solving (customer pain)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.customerPain.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.areYouSolvingPersonalCriticalPainPoint
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Are you solving a personal critical pain point?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.areYouSolvingPersonalCriticalPainPoint.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howProblemIsAddressed
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How is the problem which you described being addressed in today's market?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howProblemIsAddressed.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.currentSolutionsShortcomings
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the shortcomings to the current solutions in the market?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.currentSolutionsShortcomings.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="growthmodel" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="growthmodel">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Growth model
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="growthmodel">
                        <Card.Body id="companyinformation">
                            {!props?.howToGrowBusiness
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How do you intend to grow the business?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howToGrowBusiness.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.unitEconomics
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What does the unit economics look like at scale, particularly when competitors enter the market and as your defensibility moat develops?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.unitEconomics.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doesPriceChange
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is your business the type that looks to increase or decrease prices as you scale?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doesPriceChange.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="valuation" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="valuation">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Valuation
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="valuation">
                        <Card.Body id="companyinformation">
                            {!props?.preMoneyValuation
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your pre-money valuation?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.preMoneyValuation.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.postMoneyValuation
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your post-money valuation?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.postMoneyValuation.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="equitystake" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="equitystake">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Equity stake
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="equitystake">
                        <Card.Body id="companyinformation">
                            {!props?.equityOwnershipStakeToInvestors
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How much equity ownership stake of your company are you offering to your investor(s)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.equityOwnershipStakeToInvestors.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="financingstrategy" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="financingstrategy">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Financing strategy
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="financingstrategy">
                        <Card.Body id="companyinformation">
                            <div>
                                <p className="companydetailtitle">Financing round</p>
                                {!props?.financingRound
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.financingRound}</p>)
                                }
                            </div>
                            {!props?.financingStrategy
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your financing strategy?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.financingStrategy.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howToUseMoney
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How do you intend to use the money (use of proceeds)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howToUseMoney.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howMuchMoneyToRaise
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How much money do you need to raise?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howMuchMoneyToRaise.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howMuchExpectedFromInvestors
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How much do you expect from investors?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howMuchExpectedFromInvestors.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.milestonesToAchieveBeforeRaiseMoney
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the key milestones that you have to achieve before raising money again?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.milestonesToAchieveBeforeRaiseMoney.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.areYouRaisingEnoughToAccomplishMilestones
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Are you raising enough money to accomplish the milestones you set, such that the next investor will be willing to invest new money at a substantially higher valuation than the current round?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.areYouRaisingEnoughToAccomplishMilestones.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="exitstrategy" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="exitstrategy">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Exit strategy
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="exitstrategy">
                        <Card.Body id="companyinformation">
                            {!props?.exitStrategy
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your business exit strategy?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.exitStrategy.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howInvestorsGetMoney
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How will the investors ultimately get their money out of the business, assuming that it is successful, even if only marginally so?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howInvestorsGetMoney.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewBusiness;