import React, { useRef } from 'react';
import '../data-contribution.css';
import { Card, Form, Button } from 'react-bootstrap';
import { IoMdClose, IoMdAdd, IoMdInformationCircleOutline } from 'react-icons/io';
import { Multiselect } from 'multiselect-react-dropdown';
import { countries } from '../../dropdown-options/countries';
import { categories } from '../../dropdown-options/categories';
import { transactionalModelTypes } from '../../dropdown-options/transactional-model-types';
import { competitiveMoats } from '../../dropdown-options/competitive-moats';
import { stagesOfDevelopment } from '../../dropdown-options/stages-of-development';
import { sustainableDevelopmentGoals } from '../../dropdown-options/sustainable-development-goals';

const AddEditBusiness = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    const multiselectDefensibilityMoatsRef1 = useRef(null);
    const multiselectDefensibilityMoatsRef2 = useRef(null);
    const multiselectPreferredInvestmentStagesRef = useRef(null);
    const multiselectPreferredInvestmentFocusRef = useRef(null);
    const multiselectPreferredInvestmentGeographyRef = useRef(null);
    const multiselectTransactionalModelTypeRef1 = useRef(null);
    const multiselectTransactionalModelTypeRef2 = useRef(null);
    const multiselectMarketsRef = useRef(null);
    const multiselectGoalsRef = useRef(null);

    return (
        <>
            {props.state.profileType === "Finance"
                ? (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv col-12">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Short investment thesis
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Briefly describe your investment thesis in less than 50 words"
                                                        value={props.state.shortInvestmentThesis}
                                                        onChange={(e) => { props.setState({ "type": "update shortInvestmentThesis", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Describe your investment thesis within 50 words</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Long investment thesis
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Briefly describe your investment thesis in less than 300 words"
                                                        value={props.state.longInvestmentThesis}
                                                        onChange={(e) => { props.setState({ "type": "update longInvestmentThesis", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Describe your investment thesis within 300 words</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Stage of development
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.stageOfDevelopment}
                                                        onChange={(e) => { props.setState({ "type": "update stageOfDevelopment", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            stagesOfDevelopment.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select development stage</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Transactional model type(s)
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectTransactionalModelTypeRef1}
                                                        options={transactionalModelTypes}
                                                        selectedValues={props.state.transactionalModelType.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="-1"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="What is your transactional model type(s)?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectTransactionalModelTypeRef1.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update transactionalModelType", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select transactional model type(s)</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Competitive moat(s)
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectDefensibilityMoatsRef1}
                                                        options={competitiveMoats}
                                                        selectedValues={props.state.defensibilityMoats.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which moat best defines your defensibility?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectDefensibilityMoatsRef1.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update defensibilityMoats", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select competitive moat(s) (maximum 5)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Assets under management
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your total assets under management?"
                                                        value={props.state.assetsUnderManagement}
                                                        onChange={(e) => { props.setState({ "type": "update assetsUnderManagement", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter assets under management</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Investment range
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your range of investment?"
                                                        value={props.state.investmentRange}
                                                        onChange={(e) => { props.setState({ "type": "update investmentRange", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter investment range</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Sweet spot
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your investment sweet spot?"
                                                        value={props.state.sweetSpot}
                                                        onChange={(e) => { props.setState({ "type": "update sweetSpot", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter sweet spot</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Preferred investment stage(s)
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectPreferredInvestmentStagesRef}
                                                        options={[
                                                            { key: "Funding round" },
                                                            { key: "Angel" },
                                                            { key: "Convertible Note" },
                                                            { key: "Corporate Round" },
                                                            { key: "Debt Financing" },
                                                            { key: "Early Stage" },
                                                            { key: "Equity Crowdfunding" },
                                                            { key: "Funding Round" },
                                                            { key: "Grant" },
                                                            { key: "IPO" },
                                                            { key: "Initial Coin Offering" },
                                                            { key: "Initial Public Offering" },
                                                            { key: "Late Stage" },
                                                            { key: "M&A" },
                                                            { key: "Non-Equity Assistance" },
                                                            { key: "Post-IPO Debt" },
                                                            { key: "Post-IPO Equity" },
                                                            { key: "Pre-Seed" },
                                                            { key: "Private Equity" },
                                                            { key: "Product Crowdfunding" },
                                                            { key: "Secondary Market" },
                                                            { key: "Seed" },
                                                            { key: "Series A" },
                                                            { key: "Series B" },
                                                            { key: "Series C" },
                                                            { key: "Series D" },
                                                            { key: "Series E" },
                                                            { key: "Series F" },
                                                            { key: "Series G" },
                                                            { key: "Venture Round" }
                                                        ]}
                                                        selectedValues={props.state.preferredInvestmentStages.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="-1"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="What is your preferred investment stage?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectPreferredInvestmentStagesRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update preferredInvestmentStages", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select preferred investment stage(s)</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Preferred investment focus
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectPreferredInvestmentFocusRef}
                                                        options={categories}
                                                        selectedValues={props.state.preferredInvestmentFocus.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="-1"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="What is your preferred investment focus?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectPreferredInvestmentFocusRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update preferredInvestmentFocus", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select preferred investment focus</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Preferred investment geography
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectPreferredInvestmentGeographyRef}
                                                        options={countries}
                                                        selectedValues={props.state.preferredInvestmentGeography.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="-1"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="What is your preferred investment geography?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectPreferredInvestmentGeographyRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update preferredInvestmentGeography", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select preferred investment geography</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>

                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv col-12">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">

                                            {
                                                props.state.fundProduct.map((x, i) => {
                                                    return (
                                                        <>
                                                            <div key={i}>
                                                                <div className="addnewshortrow">
                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Fund product name
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="fundProductName"
                                                                            placeholder="What is the name of your fund product?"
                                                                            value={props.state.fundProduct[i].fundProductName}
                                                                            onChange={(e) => { editField({ type: "update fundProduct", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter fund product name</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Fund product short description
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="fundProductShortDescription"
                                                                            placeholder="Briefly describe your fund product in less than 50 words"
                                                                            value={props.state.fundProduct[i].fundProductShortDescription}
                                                                            onChange={(e) => { editField({ type: "update fundProduct", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe your fund product within 50 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Fund product long description
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="fundProductLongDescription"
                                                                            placeholder="Briefly describe your fund product within 300 words"
                                                                            value={props.state.fundProduct[i].fundProductLongDescription}
                                                                            onChange={(e) => { editField({ type: "update fundProduct", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe your fund product within 300 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    props.state.fundProduct.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove fundProduct", index: i }) }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }

                                                                {
                                                                    props.state.fundProduct.length - 1 === i &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { addField({ type: "add fundProduct", value: { fundProductName: "", fundProductShortDescription: "", fundProductLongDescription: "" } }) }}>
                                                                        <IoMdAdd />
                                                                    </Button>
                                                                }

                                                                <hr />
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }

                                        </div>
                                    </Form.Group>
                                </Form>

                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv col-12">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Stage of development
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.stageOfDevelopment}
                                                        onChange={(e) => { props.setState({ "type": "update stageOfDevelopment", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            stagesOfDevelopment.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select development stage</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Transactional model type(s)
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectTransactionalModelTypeRef2}
                                                        options={transactionalModelTypes}
                                                        selectedValues={props.state.transactionalModelType.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="-1"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="What is your transactional model type(s)?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectTransactionalModelTypeRef2.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update transactionalModelType", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select transactional model type(s)</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Competitive moat(s)
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectDefensibilityMoatsRef2}
                                                        options={competitiveMoats}
                                                        selectedValues={props.state.defensibilityMoats.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which moat best defines your defensibility?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectDefensibilityMoatsRef2.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update defensibilityMoats", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select competitive moat(s) (maximum 5)</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>

                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv col-12">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">

                                            {
                                                props.state.product.map((x, i) => {
                                                    return (
                                                        <>
                                                            <div key={i}>
                                                                <div className="addnewshortrow">
                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Product name
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="productName"
                                                                            placeholder="What is the name of your product?"
                                                                            value={props.state.product[i].productName}
                                                                            onChange={(e) => { editField({ type: "update product", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter product name</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Is this a proprietory product?
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="productProprietorship"
                                                                            placeholder="Do you own this product?"
                                                                            value={props.state.product[i].productProprietorship}
                                                                            onChange={(e) => { editField({ type: "update product", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter product proprietorship</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Product short description
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="productShortDescription"
                                                                            placeholder="Briefly describe your product in less than 50 words"
                                                                            value={props.state.product[i].productShortDescription}
                                                                            onChange={(e) => { editField({ type: "update product", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe your product within 50 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Product long description
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="productLongDescription"
                                                                            placeholder="Briefly describe your product within 300 words"
                                                                            value={props.state.product[i].productLongDescription}
                                                                            onChange={(e) => { editField({ type: "update product", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe your product within 300 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    props.state.product.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove product", index: i }) }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }

                                                                {
                                                                    props.state.product.length - 1 === i &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { addField({ type: "add product", value: { productName: "", productProprietorship: "", productShortDescription: "", productLongDescription: "" } }) }}>
                                                                        <IoMdAdd />
                                                                    </Button>
                                                                }

                                                                <hr />
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }

                                        </div>
                                    </Form.Group>
                                </Form>

                            </div>
                        </Card.Body>
                    </Card>
                </>)
            }

            {props.state.profileType === "Organization" || props.state.profileType === "Finance" || props.state.profileType === "Education" || props.state.profileType === "Project" || props.state.profileType === "Product"
                ? (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv col-12">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Does your business idea support/align with one or multiple SDGs (Sustainable Development Goals) of the United Nations?
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectGoalsRef}
                                                        options={sustainableDevelopmentGoals}
                                                        selectedValues={props.state.supportSustainableDevelopmentGoals.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="-1"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder=""
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectGoalsRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update supportSustainableDevelopmentGoals", "value": list });
                                                        }}
                                                    />
                                                    <Form.Text className="addnewprompt">Select Sustainable Development Goal(s)</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Which market(s) are you targeting?
                                                        <sup data-tip="Select your market location(s) where you offer and sell your product-or-solution">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectMarketsRef}
                                                        options={countries}
                                                        selectedValues={props.state.markets.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which market(s) are you targeting?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectMarketsRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update markets", "value": list });
                                                        }}
                                                    />
                                                    <Form.Text className="addnewprompt">Select market(s) (maximum 5)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        How big is your Total Addressable Market (TAM)?
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Define your TAM in USD value"
                                                        value={props.state.totalAddressableMarket}
                                                        onChange={(e) => { props.setState({ "type": "update totalAddressableMarket", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter total addressable market</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        How big is your Serviceable Available Market (SAM)?
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Define your SAM in USD value"
                                                        value={props.state.serviceableAvailableMarket}
                                                        onChange={(e) => { props.setState({ "type": "update serviceableAvailableMarket", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter serviceable available market</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        How big is your Serviceable Obtainable Market (SOM)?
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Define your SOM in USD value"
                                                        value={props.state.serviceableObtainableMarket}
                                                        onChange={(e) => { props.setState({ "type": "update serviceableObtainableMarket", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter serviceable obtainable market</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        What real-world problem are you solving (customer pain)?
                                                        <sup data-tip="Clearly describe the problems that your customers are facing whereby existing solutions in the market are not good enough.">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    {
                                                        props.state.customerPain.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="customerPain"
                                                                            value={props.state.customerPain[i]}
                                                                            placeholder="Describe the core problems that you want to solve"
                                                                            onChange={(e) => editField({ type: "update customerPain", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.customerPain.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove customerPain", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.customerPain.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add customerPain", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        What is your differentiated solution?
                                                    </Form.Label>
                                                    {
                                                        props.state.competitiveDifferentiation.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="competitiveDifferentiation"
                                                                            value={props.state.competitiveDifferentiation[i]}
                                                                            placeholder="Describe how your solution is unique from existing solutions"
                                                                            onChange={(e) => editField({ type: "update competitiveDifferentiation", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.competitiveDifferentiation.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove competitiveDifferentiation", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.competitiveDifferentiation.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add competitiveDifferentiation", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Who are your direct/indirect competitors? (It's almost impossible to have no competitors)
                                                    </Form.Label>
                                                    {
                                                        props.state.directCompetitors.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="directCompetitors"
                                                                            value={props.state.directCompetitors[i]}
                                                                            placeholder="Tell us who you are competing with"
                                                                            onChange={(e) => editField({ type: "update directCompetitors", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.directCompetitors.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove directCompetitors", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.directCompetitors.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add directCompetitors", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : ""
            }
        </>
    );
}

export default AddEditBusiness