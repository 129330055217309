import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewSolution(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="solution">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="solution">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Solution
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="solution">
                        <Card.Body id="companyinformation">
                            {!props?.customerGain
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What product-or-service are you offering (customer gain)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.customerGain.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="productorservice" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="productorservice">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Product-or-service
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="productorservice">
                        <Card.Body id="companyinformation">
                            {!props?.howProductMeetNeeds
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How does your product-or-service uniquely meet the needs of your target market?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howProductMeetNeeds.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.willProductSolveNeeds
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will the product-or-service solve a fundamental need in the market (whether or not that need is known currently to customers) such that customers will pay real money to purchase it?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.willProductSolveNeeds.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howDidYouGetIdea
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How did you get to the current solution idea, incorporating which insights and market data to help inform your opinion?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howDidYouGetIdea.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isProductAspirin
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is your product-or-service more of an aspirin?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isProductAspirin.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isProductVitamin
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is your product-or-service more of a vitamin?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isProductVitamin.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.factorsImportantInChoosing
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What factors are important to your customer segments in choosing this type of product-or-service?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.factorsImportantInChoosing.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howYouRespondToNeed
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How are you responding to a customer need in a new and useful way?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howYouRespondToNeed.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doCustomersLoveProduct
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Do users and customers love your product-or-service?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doCustomersLoveProduct.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewSolution;