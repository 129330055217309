import React from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { IoMdClose, IoMdAdd } from 'react-icons/io';
import '../data-contribution.css';

const AddEditManagement = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    return (
        // card based on subtopics
        <Card style={{ marginTop: "40px" }}>
            <Card.Body className="addnewaccordioncardbody">

                <div className="addnewaccordioncardbodydiv col-12">
                    <Form>
                        <Form.Group>
                            <div className="addnewscrollablediv">
                                <div className="addnewlongrow">
                                    {/* form field */}
                                    <div className="addnewlongfield">
                                        <Form.Label>
                                            Management
                                        </Form.Label>

                                        {
                                            props.state.management.map((x, i) => {
                                                return (
                                                    <div key={i} style={{ display: "flex" }}>
                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                            <Form.Control
                                                                name="management"
                                                                value={props.state.management[i]}
                                                                placeholder="Who are the people in your management staff?"
                                                                onChange={(e) => editField({ type: "update management", value: e.target.value, index: i })}
                                                                style={{ borderColor: "transparent" }}
                                                            />
                                                            {
                                                                props.state.management.length !== 1 &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { removeField({ type: "remove management", index: i }); }}>
                                                                    <IoMdClose />
                                                                </Button>
                                                            }
                                                        </div>
                                                        {
                                                            props.state.management.length - 1 === i &&
                                                            <Button className="pitchdeckaddfield"
                                                                onClick={() => { addField({ type: "add management", value: "" }) }}>
                                                                <IoMdAdd />
                                                            </Button>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                        <Form.Text className="addnewprompt">Enter management staff</Form.Text>
                                    </div>
                                </div>

                                <div className="addnewshortrow">
                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            No. of employees
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="How many employees does your entity have?"
                                            value={props.state.numOfEmployees}
                                            onChange={(e) => { props.setState({ "type": "update numOfEmployees", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter no. of employees</Form.Text>
                                    </div>

                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            No. of partners
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="How many partners does your entity have?"
                                            value={props.state.numOfPartners}
                                            onChange={(e) => { props.setState({ "type": "update numOfPartners", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter no. of partners</Form.Text>
                                    </div>

                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Partners
                                        </Form.Label>

                                        {
                                            props.state.partners.map((x, i) => {
                                                return (
                                                    <div key={i} style={{ display: "flex" }}>
                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                            <Form.Control
                                                                name="partners"
                                                                value={props.state.partners[i]}
                                                                placeholder="Who are the partners in your entity?"
                                                                onChange={(e) => editField({ type: "update partners", value: e.target.value, index: i })}
                                                                style={{ borderColor: "transparent" }}
                                                            />
                                                            {
                                                                props.state.partners.length !== 1 &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { removeField({ type: "remove partners", index: i }); }}>
                                                                    <IoMdClose />
                                                                </Button>
                                                            }
                                                        </div>
                                                        {
                                                            props.state.partners.length - 1 === i &&
                                                            <Button className="pitchdeckaddfield"
                                                                onClick={() => { addField({ type: "add partners", value: "" }) }}>
                                                                <IoMdAdd />
                                                            </Button>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                        <Form.Text className="addnewprompt">Enter partners</Form.Text>
                                    </div>
                                </div>

                                <div className="addnewshortrow">
                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            No. of board member(s)
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="How many board members does your entity have?"
                                            value={props.state.numOfBoardMembers}
                                            onChange={(e) => { props.setState({ "type": "update numOfBoardMembers", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter no. of board member(s)</Form.Text>
                                    </div>

                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Board member(s)
                                        </Form.Label>

                                        {
                                            props.state.boardMembers.map((x, i) => {
                                                return (
                                                    <div key={i} style={{ display: "flex" }}>
                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                            <Form.Control
                                                                name="boardMembers"
                                                                value={props.state.boardMembers[i]}
                                                                placeholder="Who are the board members in your entity?"
                                                                onChange={(e) => editField({ type: "update boardMembers", value: e.target.value, index: i })}
                                                                style={{ borderColor: "transparent" }}
                                                            />
                                                            {
                                                                props.state.boardMembers.length !== 1 &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { removeField({ type: "remove boardMembers", index: i }); }}>
                                                                    <IoMdClose />
                                                                </Button>
                                                            }
                                                        </div>
                                                        {
                                                            props.state.boardMembers.length - 1 === i &&
                                                            <Button className="pitchdeckaddfield"
                                                                onClick={() => { addField({ type: "add boardMembers", value: "" }) }}>
                                                                <IoMdAdd />
                                                            </Button>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                        <Form.Text className="addnewprompt">Enter board member(s)</Form.Text>
                                    </div>
                                </div>

                                <div className="addnewshortrow">
                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Email
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="What is your email?"
                                            value={props.state.managementEmail}
                                            onChange={(e) => { props.setState({ "type": "update managementEmail", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter management email</Form.Text>
                                    </div>

                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Mobile phone
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="What is your mobile phone number?"
                                            value={props.state.managementMobilePhone}
                                            onChange={(e) => { props.setState({ "type": "update managementMobilePhone", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter management mobile phone number</Form.Text>
                                    </div>

                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Website
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="What is your website?"
                                            value={props.state.managementWebsite}
                                            onChange={(e) => { props.setState({ "type": "update managementWebsite", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter management website</Form.Text>
                                    </div>
                                </div>

                                <div className="addnewshortrow">
                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Blog
                                        </Form.Label>

                                        <Form.Control
                                            placeholder="What is your blog?"
                                            value={props.state.managementBlog}
                                            onChange={(e) => { props.setState({ "type": "update managementBlog", "value": e.target.value }) }}
                                        />

                                        <Form.Text className="addnewprompt">Enter management blog</Form.Text>
                                    </div>

                                    {/* form field */}
                                    <div className="addnewshortfield">
                                        <Form.Label>
                                            Social media
                                        </Form.Label>

                                        {
                                            props.state.managementSocialMedia.map((x, i) => {
                                                return (
                                                    <div key={i} style={{ display: "flex" }}>
                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                            <Form.Control
                                                                name="managementSocialMedia"
                                                                value={props.state.managementSocialMedia[i]}
                                                                placeholder="What are links to your social media?"
                                                                onChange={(e) => editField({ type: "update managementSocialMedia", value: e.target.value, index: i })}
                                                                style={{ borderColor: "transparent" }}
                                                            />
                                                            {
                                                                props.state.managementSocialMedia.length !== 1 &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { removeField({ type: "remove managementSocialMedia", index: i }); }}>
                                                                    <IoMdClose />
                                                                </Button>
                                                            }
                                                        </div>
                                                        {
                                                            props.state.managementSocialMedia.length - 1 === i &&
                                                            <Button className="pitchdeckaddfield"
                                                                onClick={() => { addField({ type: "add managementSocialMedia", value: "" }) }}>
                                                                <IoMdAdd />
                                                            </Button>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                        <Form.Text className="addnewprompt">Enter management social media</Form.Text>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </Card.Body>
        </Card>
    );
}

export default AddEditManagement