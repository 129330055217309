import React, { useState, useEffect } from 'react';
import RoiquantLogo from '../../../assets/navbar/roiquantlogo-600x200px-01.png';
import RoiquantLogo2 from '../../../assets/navbar/roiquant-48x48px-newfavicon-01.png';
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';
import ReactTooltip from 'react-tooltip';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Accordion, Card, Spinner, Alert, Button } from 'react-bootstrap';
import { AiFillAppstore, AiOutlineClose, AiOutlineCheckCircle } from 'react-icons/ai';
import { FaBell, FaQuestionCircle, FaCog, FaUserAlt, FaBars, FaArrowAltCircleLeft, FaChevronLeft, FaChevronDown } from 'react-icons/fa';
import { BiAnalyse } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import { useRealmApp } from '../../RealmApp';
import { useHistory, useLocation } from 'react-router';
import '../navbar/navbar.css';

const NavBar = () => {

    // alert hook
    const [alert, setAlert] = useState(false);

    // message hook
    const [message, setMessage] = useState("");

    // function to close alert
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const { logOut, isLoggedIn, currentUser, getUserDetails } = useRealmApp();

    const userDetails = getUserDetails();

    // const [billingOverview, setBillingOverview] = useState('#');

    // Render effect once
    // useEffect(() => {
    //     if (isLoggedIn()) {
    //         async function getUrl() {
    //             if (currentUser.customData.stripeCustomerId) {

    //                 try {
    //                     const url = await currentUser.functions.getMyStripeCustomerPortalLink();

    //                     if (url) {
    //                         setBillingOverview(url);
    //                     }
    //                     else {
    //                         setBillingOverview("#");
    //                     }
    //                 } catch (err) {
    //                     // console.error(err);
    //                 }
    //             }
    //         }
    //         getUrl();
    //     }
    // }, []);

    // function loadBillingOverview() {
    //     // show loading message
    //     setAlert(true);
    //     setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
    //         <div style={{ width: "90%" }}>
    //             <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
    //             <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're connecting to your billing customer portal.</p>
    //             <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
    //         </div>
    //         <div style={{ width: "10%", textAlign: "right" }}>
    //             <Spinner animation="border" variant="secondary" />
    //         </div>
    //     </div>);

    //     if (billingOverview !== "#") {
    //         // show loading message
    //         setAlert(true);
    //         setMessage(<div style={{ paddingTop: "10px" }}>
    //             <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Ready</p>
    //             <p style={{ marginLeft: "28px" }}>Your billing customer portal is ready. <Link to={billingOverview} target="_blank" rel="noopener noreferrer" className="alert-cta">View billing overview</Link></p>
    //         </div>);
    //     }
    // }

    async function openStripeTab() {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're connecting to your billing customer portal.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        let url;
        try {
            const response = await currentUser.functions.getMyStripeCustomerPortalLink();
            url = response;
        }
        catch (error) {

            setAlert(true);

            if (error.name === "Error") {

                if (error.message.includes("no id")) {

                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>No record available</p>
                    </div>);

                    return;
                }
                if (error.message.includes("failed to create session")) {

                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>Failed to create session</p>
                    </div>);
                    return;
                }
                if (error.message.includes("mode not specified")) {
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>Stripe configuration error</p>
                    </div>);
                    return;
                }
            }
            else {
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Unhandled error</p>
                </div>);
                return;
            }

        }
        closeAlert();
        window.open(url, "_blank");
    }

    const [showSidebar, setShowSidebar] = useState({ open: false });

    const history = useHistory();

    const location = useLocation();

    function toggleOpen() {
        setShowSidebar({ open: true });
    }

    function toggleClose() {
        setShowSidebar({ open: false });
    }

    const [changeArrow, setChangeArrow] = useState(
        {
            data: false,
            shutdownData: false,
            businessData: false,
            startupIntelligence: false,
            ventureIntelligence: false,
            competitiveIntelligence: false,
            tools: false,
            foundersTools: false,
            simulation: false,
            foundersSimulation: false,
            sourcing: false,
            fundingSourcing: false,
            dealsSourcing: false
        }
    );

    function changeData() {
        setChangeArrow({ data: !changeArrow.data });
    }

    function changeShutdownData() {
        setChangeArrow({ shutdownData: !changeArrow.shutdownData, data: true });
    }

    function changeBusinessData() {
        setChangeArrow({ businessData: !changeArrow.businessData, data: true });
    }

    function changeStartupIntelligence() {
        setChangeArrow({ startupIntelligence: !changeArrow.startupIntelligence });
    }

    function changeVentureIntelligence() {
        setChangeArrow({ ventureIntelligence: !changeArrow.ventureIntelligence });
    }

    function changeCompetitiveIntelligence() {
        setChangeArrow({ competitiveIntelligence: !changeArrow.competitiveIntelligence });
    }

    function changeTools() {
        setChangeArrow({ tools: !changeArrow.tools });
    }

    function changeFoundersTools() {
        setChangeArrow({ foundersTools: !changeArrow.foundersTools, tools: true });
    }

    function changeSimulation() {
        setChangeArrow({ simulation: !changeArrow.simulation });
    }

    function changeFoundersSimulation() {
        setChangeArrow({ foundersSimulation: !changeArrow.foundersSimulation, simulation: true });
    }

    function changeSourcing() {
        setChangeArrow({ sourcing: !changeArrow.sourcing });
    }

    function changeFundingSourcing() {
        setChangeArrow({ fundingSourcing: !changeArrow.fundingSourcing, sourcing: true });
    }

    function changeDealsSourcing() {
        setChangeArrow({ dealsSourcing: !changeArrow.dealsSourcing, sourcing: true });
    }

    async function handleLogOut() {
        await logOut();
        history.go(0);
    }

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY === 0) {
                setScroll(false);
            }
            else {
                setScroll(true);
            }
        });
    }, []);

    return (
        <div>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div style={{ display: showSidebar.open ? "block" : "none" }}>
                <div id="sidebarcont">
                    <div className="sidebarclose">
                        <button className="sidebarbutton" onClick={toggleClose}><AiOutlineClose /></button>
                    </div>

                    <div className="sidebarhead">
                        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
                            <img src={HeaderLogo} alt="roiquant" className="sidebarlogo" />
                        </a>
                    </div>

                    <div className="sidebarback">
                        <FaArrowAltCircleLeft className="sidebarbackicon" />
                        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
                            Back to roiquant.com
                        </a>
                    </div>

                    <Accordion>
                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeData} variant="link" eventKey="0">
                                {
                                    changeArrow.data
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                }  <span className="sidebartab">Data</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="sidebarcardbody">
                                    <p>SHUTDOWN DATA</p>
                                    <a href="/data/shuttered-companies">Shuttered companies</a>
                                    <p></p>
                                    <a href="/data/investors-losses">Investors' losses</a>
                                    <p></p>
                                    <a href="/data/discontinued-products">Discontinued products</a>
                                    <p></p>
                                    <a href="#" className="sidebarinactive">Bankruptcies <span className="sidebaravailablelabel">Available soon</span></a>

                                    <hr />

                                    <p>COMMERCIAL DATA</p>
                                    <a href="/data/companies">Active companies</a>
                                    <p></p>
                                    <a href="/data/products-showcase">Products showcase</a>
                                    <p></p>
                                    <a href="/data/data-room">Data room <span className="sidebarbetalabel">Beta</span></a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeStartupIntelligence} variant="link" eventKey="1">
                                {
                                    changeArrow.startupIntelligence
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                } <span className="sidebartab">Startup Intelligence</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className="sidebarcardbody">
                                    <a href="/startup-intel/postmortem-analysis">Postmortem analysis</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeVentureIntelligence} variant="link" eventKey="2">
                                {
                                    changeArrow.ventureIntelligence
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                } <span className="sidebartab">Venture Intelligence</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body className="sidebarcardbody">
                                    <a href="/venture-intel/portfolio-analysis">Portfolio analysis</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeCompetitiveIntelligence} variant="link" eventKey="3">
                                {
                                    changeArrow.competitiveIntelligence
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                } <span className="sidebartab">Competitive Intelligence</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body className="sidebarcardbody">
                                    <a href="/competitive-intel/competitive-landscape">Competitive landscape</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeTools} variant="link" eventKey="4">
                                {
                                    changeArrow.tools
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                } <span className="sidebartab">Tools</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body className="sidebarcardbody">
                                    <p>FOR FOUNDERS</p>
                                    <a href="/tools/founders/idea-validation">Idea validation</a>
                                    <p></p>
                                    <a href="/tools/founders/business-plan">Business plan builder</a>
                                    <p></p>
                                    <a href="/tools/founders/no-code-builder">No-code web builder <span className="sidebarbetalabel">Beta</span></a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeSimulation} variant="link" eventKey="5">
                                {
                                    changeArrow.simulation
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                } <span className="sidebartab">Simulation</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body className="sidebarcardbody">
                                    <p>FOR FOUNDERS</p>
                                    <a href="/simulation/founders/pre-revenue-valuation">Pre-revenue business valuation</a>
                                    <p></p>
                                    <a href="#" className="sidebarinactive">Start-up costing <span className="sidebaravailablelabel">Available soon</span></a>
                                    <p></p>
                                    <a href="/simulation/founders/market-sizing">Market sizing</a>
                                    <p></p>
                                    <a href="#" className="sidebarinactive">Financial modeling <span className="sidebaravailablelabel">Available soon</span></a>
                                    <p></p>
                                    <a href="/simulation/founders/vc-funding-benchmarks">VC funding benchmarks <span className="sidebarbetalabel">Beta</span></a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card className="sidebarheader">
                            <Accordion.Toggle as={Card.Header} onClick={changeSourcing} variant="link" eventKey="6">
                                {
                                    changeArrow.sourcing
                                        ? <FaChevronDown className="sidebarheaderarrow" />
                                        : <FaChevronLeft className="sidebarheaderarrow" />
                                } <span className="sidebartab">Sourcing</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body className="sidebarcardbody">
                                    <p>FUNDING</p>
                                    <a href="/sourcing/funding/very-early-stage">Very-early stage</a>
                                    <p></p>
                                    <a href="/sourcing/funding/early-stage">Early stage</a>
                                    <p></p>
                                    <a href="/sourcing/funding/growth-stage">Growth stage</a>
                                    <p></p>
                                    <a href="/sourcing/funding/late-stage">Late stage</a>
                                    <p></p>
                                    <a href="/sourcing/funding/post-ipo-stage">Post-IPO stage</a>

                                    <hr />

                                    <p>DEALS</p>
                                    <a href="/sourcing/deals/very-early-stage">Very-early stage <span className="sidebarbetalabel">Beta</span></a>
                                    <p></p>
                                    <a href="/sourcing/deals/early-stage">Early stage <span className="sidebarbetalabel">Beta</span></a>
                                    <p></p>
                                    <a href="#" className="sidebarinactive">Growth stage <span className="sidebaravailablelabel">Available soon</span></a>
                                    <p></p>
                                    <a href="#" className="sidebarinactive">Late stage <span className="sidebaravailablelabel">Available soon</span></a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </div>

            <div>
                <Router>
                    <Navbar id="navbarcomponent" className={scroll ? "navbartopsticky" : "navbartop"} bg="white" variant="grey" fluid="true">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">

                            <ReactTooltip className="navbartooltip" place="bottom" type="dark" effect="solid" />

                            <div className="navbarslogan">
                                <span><i>affordable business decision support system</i></span>
                            </div>

                            <NavDropdown alignRight className="navbardropdown" title={<AiFillAppstore className="navbaricon" />} id="basic-nav-dropdown" data-tip="Apps">
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://competition.roiquant.com/" target="_blank" rel="noopener noreferrer">Startup Competition</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="/referral-program">Referral Program</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem">Integrated Apps</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link data-tip="Notifications">
                                <FaBell className="navbaricon" />
                            </Nav.Link>

                            <NavDropdown alignRight className="navbardropdown" title={<FaQuestionCircle className="navbaricon" />} id="basic-nav-dropdown" data-tip="Support">
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://viewpoints.roiquant.com/tutorials/" target="_blank" rel="noopener noreferrer">Tutorials</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://viewpoints.roiquant.com/faq/" target="_blank" rel="noopener noreferrer">Frequently Asked Questions</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://viewpoints.roiquant.com/citation-policy/" target="_blank" rel="noopener noreferrer">Citation Policy</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://viewpoints.roiquant.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://viewpoints.roiquant.com/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of Service</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://viewpoints.roiquant.com/release-notes/" target="_blank" rel="noopener noreferrer">What's New</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">Need More Help</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="https://www.roiquant.com/about" target="_blank" rel="noopener noreferrer">About roiquant</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown alignRight className="navbardropdown" title={<FaCog className="navbaricon" />} id="basic-nav-dropdown" data-tip="Settings">
                                <NavDropdown.Item className="navbardropdownitem" onClick={openStripeTab}>Billing Overview</NavDropdown.Item>
                                <li className="dropdown-submenu">
                                    <a className="navbardropdownitem">Security</a>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-submenu" id="navbaractive">
                                            <a className="navbardropdownitem">Change password</a>
                                        </li>
                                    </ul>
                                </li>
                            </NavDropdown>

                            <NavDropdown alignRight className="navbardropdown" title={<FaUserAlt className="navbaricon" />} id="basic-nav-dropdown" data-tip="Account">
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="/profile">Account</NavDropdown.Item>
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" href="/profile-management">Profile management</NavDropdown.Item>
                                {/* <NavDropdown.Item className="navbardropdownitem">Social Media</NavDropdown.Item> */}
                                {/* <NavDropdown.Item className="navbardropdownitem">Contact Details</NavDropdown.Item> */}
                                <NavDropdown.Divider />
                                <NavDropdown.Item className="navbardropdownitem" id="navbaractive" onClick={handleLogOut} style={{ textAlign: "center" }}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Navbar.Collapse>
                    </Navbar>

                    <Navbar bg="white" variant="grey" fluid="true" className={scroll ? "navbarbottomsticky" : "navbarbottom"}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">

                            <Nav.Link style={{ marginLeft: "-7px" }} data-tip="Menu">
                                <button onClick={toggleOpen} className="sidebarbutton">
                                    <FaBars className="navbaricon" />
                                </button>
                            </Nav.Link>

                            <Nav.Link href="/dashboard" data-tip="Dashboard">
                                <button className="glintanimation navbardashboard">
                                    Dashboard
                                </button>
                            </Nav.Link>

                            <div className="navbarlogodiv">
                                <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
                                    <img className="navbarlogo" src={RoiquantLogo} alt="roiquant" />
                                    <img className="navbarlogo2" src={RoiquantLogo2} alt="roiquant" />
                                </a>
                            </div>

                            {
                                location.pathname === "/dashboard" || location.pathname === "/"
                                    ? (<Nav.Link href="/tools/founders/idea-validation">
                                        <button className="glintanimation navbarideachecker">
                                            Idea checker
                                        </button>
                                    </Nav.Link>)
                                    : location.pathname.includes("/data") || location.pathname.includes("/sourcing/funding")
                                        ? (<Nav.Link href="/profile/add">
                                            <button className="glintanimation navbaraddnew">
                                                Add new
                                            </button>
                                        </Nav.Link>)
                                        : ""
                            }
                        </Navbar.Collapse>
                    </Navbar>
                </Router>
            </div>
        </div>
    )
}

export default NavBar;