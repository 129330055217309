import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import '../pitch-deck-analysis.css';
import { Accordion, Col, Card, Form, ProgressBar } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Multiselect } from 'multiselect-react-dropdown';
import { industriesNew } from '../../../../dropdown-options/industries';
import { countries } from '../../../../dropdown-options/countries';
import { categories } from '../../../../dropdown-options/categories';
import { transactionalModelTypes } from '../../../../dropdown-options/transactional-model-types';
import { competitiveMoats } from '../../../../dropdown-options/competitive-moats';
import { stagesOfDevelopment } from '../../../../dropdown-options/stages-of-development';
import { entityTypes } from '../../../../dropdown-options/entity-types';

const CreateEditOverview = (props) => {

    const multiselectEntityTypeRef = useRef(null);
    const multiselectOwnershipStatusRef = useRef(null);
    const multiselectCountryRef = useRef(null);
    const multiselectMarketsRef = useRef(null);
    const multiselectStageOfDevelopmentRef = useRef(null);
    const multiselectTransactionalModelTypeRef = useRef(null);
    const multiselectDefensibilityMoatsRef = useRef(null);
    const multiselectIndustryRef = useRef(null);
    const multiselectCategoriesRef = useRef(null);
    // const multiselectFundingRef = useRef(null);

    return (

        <div className="ideacheckertabbody">
            <Col className="ideacheckerquestions">
                <Accordion className="companyaccordion" defaultActiveKey="generalinformation">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="generalinformation">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                General information
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="generalinformation">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12">
                                    <IoMdInformationCircleOutline />
                                    <span className="info-text">
                                        Please fill in all required fields marked with asterisks (<sup>*</sup>).
                                    </span>
                                </div>

                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="ideacheckerrow2">
                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        Entity name
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="What is the name of your entity?"
                                                        value={props.state.entityName}
                                                        onChange={(e) => { props.setState({ "type": "update entityName", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="text-muted">Enter entity name</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Entity type
                                                        <sup data-tip="Select the business entity type that dictates both the legal structure of your organization and how your company will be taxed">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectEntityTypeRef}
                                                        options={entityTypes}
                                                        selectedValues={(props.state.entityType) ? [{ key: props.state.entityType }] : []}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="1"
                                                        showCheckbox="true"
                                                        placeholder="Which entity best describes your operation?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectEntityTypeRef.current.getSelectedItems();
                                                            let list = selectedItems[0].key;
                                                            if (!list) list = "";
                                                            props.setState({ type: "update entityType", value: list });
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select entity type</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        Ownership status
                                                        <sup data-tip="">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectOwnershipStatusRef}
                                                        options={[
                                                            { key: "Acquired" },
                                                            { key: "Private" },
                                                            { key: "Bankrupt" },
                                                            { key: "Subsidiary" },
                                                            { key: "Government" },
                                                            { key: "Public" },
                                                            { key: "Public Benefit" },
                                                            { key: "Merged" }
                                                        ]}
                                                        selectedValues={(props.state.ownershipStatus) ? [{ key: props.state.ownershipStatus }] : []}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="1"
                                                        showCheckbox="true"
                                                        placeholder="What is the ownership status of your entity?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectOwnershipStatusRef.current.getSelectedItems();
                                                            let list = selectedItems[0].key;
                                                            if (!list) list = "";
                                                            props.setState({ type: "update ownershipStatus", value: list });
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select ownership status</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Market(s)
                                                        <sup data-tip="Select your market location(s) where you offer and sell your product-or-solution">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectMarketsRef}
                                                        options={countries}
                                                        selectedValues={props.state.markets.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which market(s) are you targeting?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectMarketsRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update markets", "value": list });
                                                        }}

                                                    />
                                                    <Form.Text className="text-muted">Select market(s) (maximum 5)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="ideacheckerrow2">
                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        Headquarter location - city
                                                    </Form.Label>
                                                    <Form.Control
                                                        value={props.state.locationCity}
                                                        placeholder="Which city is your headquarter located at?"
                                                        onChange={(e) => { props.setState({ "type": "update locationCity", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="text-muted">Enter city</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        Headquarter location - state
                                                    </Form.Label>
                                                    <Form.Control
                                                        value={props.state.locationState}
                                                        placeholder="Which state is your headquarter located at?"
                                                        onChange={(e) => { props.setState({ "type": "update locationState", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="text-muted">Enter state</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Headquarter location - country
                                                        <sup data-tip="Select the country where your company is at">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectCountryRef}
                                                        options={countries}
                                                        selectedValues={(props.state.locationCountry) ? [{ key: props.state.locationCountry }] : []}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="1"
                                                        showCheckbox="true"
                                                        placeholder="Which country is your headquarter located at?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {


                                                            const selectedItems = multiselectCountryRef.current.getSelectedItems();
                                                            let list = selectedItems[0].key;
                                                            if (!list) list = "";
                                                            props.setState({ type: "update locationCountry", value: list });

                                                        }}

                                                    />
                                                    <Form.Text className="text-muted">Select country</Form.Text>
                                                </div>
                                            </div>

                                            <div className="ideacheckerrow">
                                                <div>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        One-sentence pitch
                                                        <sup data-tip="In one single sentence, clearly explain your core business idea by focusing on your “why?”">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        value={props.state.onesentencePitch}
                                                        placeholder="Briefly describe what your entity does in less than 50 words"
                                                        onChange={(e) => { props.setState({ "type": "update onesentencePitch", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="text-muted">Describe what you do in 10 to 50 words</Form.Text>
                                                </div>
                                            </div>

                                            <div className="ideacheckerrow2">
                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Stage of development
                                                        <sup data-tip="Describe your current stage of business development">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectStageOfDevelopmentRef}
                                                        options={stagesOfDevelopment}

                                                        selectedValues={(props.state.stageOfDevelopment) ? [{ key: props.state.stageOfDevelopment }] : []}

                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="1"
                                                        showCheckbox="true"
                                                        placeholder="What is your current stage of development?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectStageOfDevelopmentRef.current.getSelectedItems();
                                                            let list = selectedItems[0].key;
                                                            if (!list) list = "";
                                                            props.setState({ type: "update stageOfDevelopment", value: list });

                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select development stage</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Transactional model type
                                                        <sup data-tip="Select the transactional model type that best describe the relationship of transactions between your organization and your counterparts">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectTransactionalModelTypeRef}
                                                        options={transactionalModelTypes}
                                                        selectedValues={(props.state.transactionalModelType) ? [{ key: props.state.transactionalModelType }] : []}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="1"
                                                        showCheckbox="true"
                                                        placeholder="Which model best describes your commercial activity?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}

                                                        onSelect={() => {
                                                            const selectedItems = multiselectTransactionalModelTypeRef.current.getSelectedItems();
                                                            let list = selectedItems[0].key;
                                                            if (!list) list = "";
                                                            props.setState({ type: "update transactionalModelType", value: list });
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select transactional model type</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Competitive moat(s)
                                                        <sup data-tip="Select the defensibility moat that best define your competitive advantage to protect long-term profits and market share">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectDefensibilityMoatsRef}
                                                        options={competitiveMoats}
                                                        selectedValues={props.state.defensibilityMoats.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which moat best defines your defensibility?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectDefensibilityMoatsRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update defensibilityMoats", "value": list });
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select competitive moat(s) (maximum 5)</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Categories
                                                        <sup data-tip="Select the categories that best define the specific niche of your business focus in a vertical market.">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectCategoriesRef}
                                                        options={categories}
                                                        selectedValues={props.state.categories.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="What categories best define your idea?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectCategoriesRef.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update categories", "value": list });
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select categories (maximum 5)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="ideacheckerrow">
                                                <div>
                                                    <Form.Label>
                                                        <sup>*</sup>
                                                        Industry
                                                        <sup data-tip="Select the industrial grouping that best describe the production process, functions, and operations of your core business">
                                                            <IoMdInformationCircleOutline id="information-icon" />
                                                        </sup>
                                                    </Form.Label>
                                                    <Multiselect
                                                        ref={multiselectIndustryRef}
                                                        options={industriesNew}
                                                        selectedValues={(props.state.industry) ? [{ key: props.state.industry }] : []}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="1"
                                                        showCheckbox="true"
                                                        placeholder="Which industry best describes your area of focus?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectIndustryRef.current.getSelectedItems();
                                                            let list = selectedItems[0].key;
                                                            if (!list) list = "";
                                                            props.setState({ type: "update industry", value: list });
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">Select industry</Form.Text>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="goals" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="goals">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Goals
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="goals">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Vision
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        <Form.Control
                                                            value={props.state.vision}
                                                            placeholder="Over the span of 10 years, what do you wish to build?"
                                                            onChange={(e) => { props.setState({ "type": "update vision", "value": e.target.value }) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Mission
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        <Form.Control
                                                            value={props.state.mission}
                                                            placeholder="In 3 years, what do you intend to achieve?"
                                                            onChange={(e) => { props.setState({ "type": "update mission", "value": e.target.value }) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="funding" style={{ marginTop: "10px" }} >
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="funding">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Funding
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="funding">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="ideacheckerrow2">
                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        Last financing date
                                                    </Form.Label>
                                                    <TextField value={props.state.lastFinancingDate} className="dobinputbox" type="date" onChange={(e) => { props.setState({ "type": "update lastFinancingDate", "value": e.target.value }) }} />
                                                    <Form.Text className="text-muted">Enter last financing date</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        Last financing round
                                                    </Form.Label>
                                                    <Form.Control
                                                        value={props.state.lastFinancingRound}
                                                        placeholder="What was your last financing round?"
                                                        onChange={(e) => { props.setState({ "type": "update lastFinancingRound", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="text-muted">Enter last financing round</Form.Text>
                                                </div>

                                                <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                                    <Form.Label>
                                                        No. of funding rounds
                                                    </Form.Label>
                                                    <Form.Control
                                                        value={props.state.numOfFundingRounds}
                                                        placeholder="How many rounds of funding have you raised?"
                                                        onChange={(e) => { props.setState({ "type": "update numOfFundingRounds", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="text-muted">Enter no. of funding rounds</Form.Text>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>

            <Col className="ideacheckerresults">
                <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats" >
                            <div className="companytitlecard">
                                Results
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="resultstats">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>

                                <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                    Overall score: {props.state.analysisResult?.overall.score ? props.state.analysisResult.overall.score : 0}%
                                </div>

                                {
                                    props.state.analysisResult?.overall.score < 50
                                        ? (<ProgressBar now={props.state.analysisResult?.overall.score ? props.state.analysisResult.overall.score : 0} variant="danger" style={{ height: "2rem" }} />)
                                        : (<ProgressBar now={props.state.analysisResult?.overall.score ? props.state.analysisResult.overall.score : 0} variant="success" style={{ height: "2rem" }} />)
                                }

                                <br />

                                {/* {
                                    (props.state.analysisResult?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {props.state.analysisResult.error}
                                        </Alert>)
                                        : ""
                                } */}

                                <br />

                                <span style={{ fontWeight: "bold", fontSize: "14px" }}>How do we analyze your business plan?</span>

                                <br />

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Business</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.business.score
                                                ? props.state.analysisResult.business.score > 0
                                                    ? props.state.analysisResult.business.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.business.score ? props.state.analysisResult.business.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Solution</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.solution.score
                                                ? props.state.analysisResult.solution.score > 0
                                                    ? props.state.analysisResult.solution.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.solution.score ? props.state.analysisResult.solution.score : 0} variant="danger" style={{ height: "0.2rem" }} />

                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Market</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.market.score
                                                ? props.state.analysisResult.market.score > 0
                                                    ? props.state.analysisResult.market.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.market.score ? props.state.analysisResult.market.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Competition</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.competition.score
                                                ? props.state.analysisResult.competition.score > 0
                                                    ? props.state.analysisResult.competition.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.competition.score ? props.state.analysisResult.competition.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Operations</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.operations.score
                                                ? props.state.analysisResult.operations.score > 0
                                                    ? props.state.analysisResult.operations.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.operations.score ? props.state.analysisResult.operations.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Marketing</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.marketing.score
                                                ? props.state.analysisResult.marketing.score > 0
                                                    ? props.state.analysisResult.marketing.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.marketing.score ? props.state.analysisResult.marketing.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Financials</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.financials.score
                                                ? props.state.analysisResult.financials.score > 0
                                                    ? props.state.analysisResult.financials.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.financials.score ? props.state.analysisResult.financials.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Risk</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.risk.score
                                                ? props.state.analysisResult.risk.score > 0
                                                    ? props.state.analysisResult.risk.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.risk.score ? props.state.analysisResult.risk.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div>

                                {/* <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Management</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.management.score
                                                ? props.state.analysisResult.management.score > 0
                                                    ? props.state.analysisResult.management.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.management.score ? props.state.analysisResult.management.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div> */}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="notification" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification" >
                            <div className="companytitlecard">
                                Notification
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="notification">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                <span className="info-notes" style={{ fontSize: "16px" }}>
                                    Note:
                                    <ul style={{ paddingLeft: "17px" }}>
                                        <li id="notes-points">Write no less than 2 words for short answers</li>
                                        <li id="notes-points">Write more than 7 words for normal answers</li>
                                        <li id="notes-points">Due to large datasets, it may take up to 1 min to compute and show results.</li>
                                        <li id="notes-points">We use advanced real-time analytics and text analytics to analyze your inputs.</li>
                                    </ul>
                                </span>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                            <div className="companytitlecard">
                                Feedback
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="feedback">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                {!props.state.analysisResult
                                    ? ("")
                                    : (<div className="pitchdeckrecommended">
                                        Recommended readings:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {props.state.analysisResult?.overview.recommended_read.map((reading, index) =>
                                                <li key={index}>
                                                    <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>)
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </div>

    )

}

export default CreateEditOverview;