import React, { useState, useEffect } from 'react';
import NavBar from '../../../layout/navbar/navbar';
import SampleImage from '../../../../assets/logo/roiquant - logo not found.png';
import DropdownComponent from '../../dropdown';
import NumberDropdownComponent from '../../dropdown/number-dropdown';
import TimeDropdownComponent from '../../dropdown/time-dropdown';
import CompaniesCard from '../../pop-up-card/companies-card';
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import { AiOutlineCloseCircle, AiOutlineExpandAlt } from 'react-icons/ai';
import { FaEdit, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';
import { useRealmApp } from '../../../RealmApp';
import { S3Media } from '../../../third-party-services';
import { Link } from 'react-router-dom';
import './shutdown-data.css';

const ShutteredCompanies = () => {

    // hook for alert component - show alert on page load
    const [alert, setAlert] = useState(true);

    // hook for alert message - show message on page load
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // function to scroll table
    function scrollToTop() {
        window.scrollTo(0, 0);

        document.getElementById('table-data').scrollIntoView();
    }

    // realm functions declaration
    const { currentUser, logInAnonymous, getUserTier } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // table functions declaration
    const { getShutteredCompaniesTest, getCompanyDetailsForExpand } = currentUser.functions;

    // hook for argument to realm function
    const [argument, setArgument] = useState({
        numOfRows: 50,
        currentPage: 1,
        searchTerm: "",
        selectedHeader: "",
        selectedDropdown: "",
    });

    // hook for front-end pagination
    const [page, setPage] = useState({
        startNum: 1,
        endNum: 50,
        lastPage: 0
    });

    // hook for table data
    const [tableData, setTableData] = useState({
        results: [],
        total: 0,
        lastPage: 0,
        statistics: {
            inactiveCompanies: 0,
            reasonsForShutdown: 0
        }
    });

    // fetch data on page load
    useEffect(async () => {
        try {
            let response = await getShutteredCompaniesTest(argument)
            setTableData(JSON.parse(response.body));

            page["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...page });
        } catch (err) { }
        // close alert when data is received
        setAlert(false);
    }, []);

    // function to update hooks
    async function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // change current page value
        if (name.includes("Page")) {

            let currentPageName = "currentPage", currentPageValue,
                startNumName = "startNum", startNumValue,
                endNumName = "endNum", endNumValue;

            if (name === "firstPage") {
                currentPageValue = 1;
                startNumValue = 1;
                endNumValue = argument.numOfRows;
            }
            if (name === "prevPage") {
                currentPageValue = argument.currentPage - 1;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = currentPageValue * argument.numOfRows;
            }
            if (name === "nextPage") {
                currentPageValue = argument.currentPage + 1;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = currentPageValue * argument.numOfRows;
            }
            if (name === "lastPage") {
                currentPageValue = page.lastPage;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = tableData.total;
            }

            argument[currentPageName] = currentPageValue;
            page[startNumName] = startNumValue;
            page[endNumName] = endNumValue;

            setArgument({ ...argument });
            setPage({ ...page });
        }

        // if numOfRows is changed, then reset all values for pagination
        if (name === "numOfRows") {
            let lastPage = Math.ceil(tableData.total / value);

            argument["currentPage"] = 1;
            argument["numOfRows"] = value;
            page["startNum"] = 1;
            page["endNum"] = value;
            page["lastPage"] = lastPage;

            setArgument({ ...argument });
            setPage({ ...page });
        }

        // change search term value
        if (name === "searchTerm") {
            let argumentCopy = { ...argument };

            argumentCopy[name] = value;

            setArgument({ ...argumentCopy });
        }

        // only fetch data if numOfRows or currentPage is changed
        if (name.localeCompare("searchTerm") !== 0) {
            // show alert when data is fetching
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            try {
                let response = await getShutteredCompaniesTest(argument);
                setTableData(JSON.parse(response.body));
                // console.log(tableData);

                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            } catch (err) { }
        }
    }

    // function to submit search term
    async function submitSearch(e) {
        e.preventDefault();

        let argumentCopy = { ...argument };

        argumentCopy["numOfRows"] = "50";
        argumentCopy["currentPage"] = "1";

        setArgument({ ...argumentCopy });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await getShutteredCompaniesTest(argument)
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            if (JSON.parse(response.body).total == 0) {
                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Sorry, your search query returned no results.</p>
                    <ul style={{ marginLeft: "3px" }}>
                        <li>Check for typing or spelling errors</li>
                        <li>Use a different search term and try a new search</li>
                        <li>We may not yet have the item you're searching for</li>
                    </ul>
                    <p style={{ marginLeft: "28px" }}>If you still can't find what you're looking for, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">share your feedback</a> to help us improve.</p>
                </div>);
            }
            else {
                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            }

            let pageCopy = { ...page };

            pageCopy["endNum"] = JSON.parse(response.body).results.length.toString();
            pageCopy["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...pageCopy });
        } catch (err) { }
    }

    // function to listen to keyboard
    function enterSearch(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitSearch(e);
        }
    };

    // function to reset search term
    async function resetSearch(e) {
        e.preventDefault();

        argument["searchTerm"] = "";
        argument["currentPage"] = 1;

        setArgument({ ...argument });
        setTableData({
            results: [],
            total: 0,
            lastPage: 0
        });
        setPage({
            startNum: 1,
            endNum: 50,
            lastPage: 0
        });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await getShutteredCompaniesTest(argument);
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            page["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...page });

            // show alert when data is received
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
            </div>);
        } catch (err) { }
    }

    // hook for expand card modal
    const [expandCard, setExpandCard] = useState(false);

    // hook for expand card data
    const [expandData, setExpandData] = useState();

    // function to fetch more details for expand modal
    async function getCompanyDetails(e) {

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await getCompanyDetailsForExpand(e);

            const array1 = response[0].companies;

            const array2 = response[0].details;

            let mergedArray;

            if (e.field == "competitors" || e.field == "similarEntities") {
                setExpandData(array2);
            }
            else {
                mergedArray = mergeArraysOfObjectsByKey(array1, array2, "name");

                setExpandData(mergedArray);
            }
            setExpandCard(true);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    // merge two arrays of objects together based on key
    function mergeArraysOfObjectsByKey(array1, array2, key) {
        const mergedArray = [];

        // iterate over the first array
        array1.forEach((object1) => {
            // find the corresponding object in the second array
            const object2 = array2.find((object2) => object2[key] === object1[key]);

            // if there is a corresponding object in the second array, merge the two objects
            if (object2) {
                const mergedObject = Object.assign({}, object1, object2);
                mergedArray.push(mergedObject);
            } else {
                // if there is no corresponding object in the second array, just add the first object to the merged array
                mergedArray.push(object1);
            }
        });

        return mergedArray;
    }

    // function to open expand modal
    const openExpandCard = (e) => {
        setExpandCard(true);
        setExpandData(e);
    }

    // function to close expand modal
    function closeExpandCard() {
        setExpandCard(false);
        setExpandData(null);
    }

    // function to show pop up card when user clicks logo 
    const [showCard, setShowCard] = useState(false);
    const [moreInfo, setMoreInfo] = useState([]);

    const handleData = (e) => {

        try {
            const newData = tableData.results.filter(companies => companies.tradeName === e)
                .map(companies =>
                    companies = {
                        "slug": companies.slug,
                        "name": companies.name,
                        "tradeName": companies.tradeName,
                        "description": companies.description,
                        "logo": companies.logo,
                        "reasonsForFailure": companies.reasonsForFailure,
                        "categories": companies.categories,
                        "location": companies.details.location,
                        "country": companies.details.location.country,
                        "countryCode": companies.details.location.countryCode,
                        "state": companies.details.location.state,
                        "city": companies.details.location.city,
                        "industry": companies.industry,
                        "products": companies.products
                    });

            setMoreInfo(newData);
            setShowCard(true);

        } catch (error) {

            const newData = tableData.results.filter(companies => companies.tradeName === e)
                .map(companies =>
                    companies = {
                        "slug": companies.slug,
                        "name": companies.name,
                        "tradeName": companies.tradeName,
                        "description": companies.description,
                        "logo": companies.logo,
                        "reasonsForFailure": companies.reasonsForFailure,
                        "categories": companies.categories,
                        "industry": companies.industry,
                        "products": companies.products
                    });

            setMoreInfo(newData);
            setShowCard(true);
        }
    }

    function hideCard() {
        setShowCard(false);
    }

    const collection = "companies";

    // filter function - the args/constructor are locales (en) language and options (numeric collation are set to true and sensitivity level set to base)
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    const [fieldName, setFieldName] = useState([]);
    const [hideField, setHideField] = useState(false);

    // sort handler
    async function sort(value, header) {

        if (value === " Hide Field") {
            setFieldName(fieldName => [...fieldName, header]);
            setHideField(true);
        };

        if (value === " Sort A to Z" || value === " Sort smaller to larger" || value === " Sort older to newer" || value === " Sort Z to A" || value === " Sort larger to smaller" || value === " Sort newer to older") {
            // show alert when data is being fetched
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Sorting</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            //update the argument state variable
            let newHeader = header;

            switch (header) {
                case "tradeName": { newHeader = "tradeName"; break; }
                case "entityType": { newHeader = "entityType"; break; }
                case "description": { newHeader = "description"; break; }
                case "reasonsForFailure": { newHeader = "reasonsForFailure"; break; }
                case "shutteredPortfolioCompanies": { newHeader = "shutteredPortfolioCompanies"; break; }
                case "industry": { newHeader = "industry"; break; }
                case "categories": { newHeader = "categories"; break; }
                case "ownership": { newHeader = "ownership"; break; }
                case "stageOfDevelopment": { newHeader = "stageOfDevelopment"; break; }
                case "businessModelType": { newHeader = "businessModelType"; break; }
                case "defensibilityMoat": { newHeader = "defensibilityMoat"; break; }
                case "lastFinancingRound": { newHeader = "details.lastFinancingRound"; break; }
                case "totalInvestment": { newHeader = "details.totalInvestment"; break; }
                case "postMoneyValuation": { newHeader = "postMoneyValuation"; break; }
                case "location": { newHeader = "details.location.city"; break; }
                case "numOfFundingRounds": { newHeader = "details.numOfFundingRounds"; break; }
                case "numOfInvestors": { newHeader = "details.numOfInvestors.total"; break; }
                case "investors": { newHeader = "investors"; break; }
                case "revenue": { newHeader = "revenue"; break; }
                case "market": { newHeader = "market"; break; }
                case "investments": { newHeader = "investments"; break; }
                case "numOfAcquisitions": { newHeader = "details.numOfAcquisitions"; break; }
                case "boardSeats": { newHeader = "boardSeats"; break; }
                case "competition": { newHeader = "competitors"; break; }
                case "similarEntities": { newHeader = "similarEntities"; break; }
                case "management": { newHeader = "management.name"; break; }
                case "numOfEmployees": { newHeader = "details.numOfEmployees.min"; break; }
                case "reputation": { newHeader = "reputation"; break; }
                case "phoneNumber": { newHeader = "details.phoneNumber"; break; }
                case "year": { newHeader = "details.year.lifespan"; break; }
                case "former_legalName": { newHeader = "former_legalName"; break; }
                case "legalName": { newHeader = "legalName"; break; }
                case "ticker": { newHeader = "ticker"; break; }
                default: { newHeader = null; }
            }
            //argument is updated
            let argumentCopy = { ...argument },
                name1 = "selectedHeader",
                name2 = "selectedDropdown";

            argumentCopy[name1] = newHeader;
            argumentCopy[name2] = value;
            setArgument({ ...argumentCopy });
        }
    }

    useEffect(() => {
        const getSortedResults = async () => {
            //if these arguments are updated, send to backend
            if ((argument.selectedHeader && argument.selectedDropdown != null) && (argument.searchTerm === null)) {
                try {
                    let response = await getShutteredCompaniesTest(argument);
                    // console.log(argument);
                    const updatedList = JSON.parse(response.body);
                    setTableData(updatedList);

                    // show alert when data is received
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                        <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                    </div>);

                } catch (err) {
                    // show alert when error is received
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>There seems to be an issue and we couldn't sort your selected column(s). Please try again.</p>
                        <p style={{ marginLeft: "28px" }}>If you still have trouble sorting your selected column(s), please <a href="https://www.roiquant.com/contact">contact our support team</a> for help.
                        </p>
                    </div>);
                }
            } else if ((argument.selectedHeader && argument.selectedDropdown != null) && (argument.searchTerm != null)) {
                try {
                    let response = await getShutteredCompaniesTest(argument);
                    const updatedList = JSON.parse(response.body);
                    setTableData(updatedList);

                    // show alert when data is received
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                        <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                    </div>);
                } catch (err) {
                }
            }
        };
        getSortedResults();
        return () => {
        };
    }, [argument]);

    const selectedDropdown = (value, header) => {

        let total = tableData.total;
        let sortedData = [...tableData.results];

        if (value === " Hide Field") {
            setFieldName(fieldName => [...fieldName, header]);
            setHideField(true);
        };

        if (header != null) {
            try {
                if (header === "lastFinancingDate") {
                    if (value === " Sort older to newer") {
                        sortedData.sort((a, b) => {
                            let aa = new Date(a.details?.lastFinancingDate).getTime(), bb = new Date(b.details?.lastFinancingDate).getTime();
                            return collator.compare(aa, bb);
                        });
                    }
                    if (value === " Sort newer to older") {
                        sortedData.sort((a, b) => {
                            let aa = new Date(a.details?.lastFinancingDate).getTime(), bb = new Date(b.details?.lastFinancingDate).getTime();
                            if (aa === b) { return 0; }
                            if (aa === null) { return 1; }
                            if (bb === null) { return -1; }
                            return aa < bb ? 1 : -1;
                        });
                    }
                    setTableData({ results: sortedData, total: total });
                }
                if (header === "shutdownDate") {
                    if (value === " Sort older to newer") {
                        sortedData.sort((a, b) => {
                            let aa = new Date(a.details.date?.ended).getTime(), bb = new Date(b.details.date?.ended).getTime();
                            return collator.compare(aa, bb);
                        });
                    }
                    if (value === " Sort newer to older") {
                        sortedData.sort((a, b) => {
                            let aa = new Date(a.details.date?.ended).getTime(), bb = new Date(b.details.date?.ended).getTime();
                            if (aa === b) { return 0; }
                            if (aa === null) { return 1; }
                            if (bb === null) { return -1; }
                            return aa < bb ? 1 : -1;
                        });
                    }
                    setTableData({ results: sortedData, total: total });
                }
                if (header === "foundedDate") {
                    if (value === " Sort older to newer") {
                        sortedData.sort((a, b) => {
                            let aa = new Date(a.details.date?.started).getTime(), bb = new Date(b.details.date?.started).getTime();
                            return collator.compare(aa, bb);
                        });
                    }
                    if (value === " Sort newer to older") {
                        sortedData.sort((a, b) => {
                            let aa = new Date(a.details.date?.started).getTime(), bb = new Date(b.details.date?.started).getTime();
                            if (aa === b) { return 0; }
                            if (aa === null) { return 1; }
                            if (bb === null) { return -1; }
                            return aa < bb ? 1 : -1;
                        });
                    }
                    setTableData({ results: sortedData, total: total });
                }
            } catch {
                if (value === " Sort A to Z" || value === " Sort smaller to larger" || value === " Sort older to newer") {
                    sortedData.sort((a, b) => {
                        return collator.compare(a[header], b[header]);
                    });
                }
                if (value === " Sort Z to A" || value === " Sort larger to smaller" || value === " Sort newer to older") {
                    sortedData.sort((a, b) => {
                        return collator.compare(b[header], a[header]);
                    });
                }
                setTableData({ results: sortedData, total: total });
            }
        }
    }

    function unhideFields() {
        setHideField(false);
        if (fieldName) {
            fieldName.length = 0;
        }
    }

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="shutdowndata">
                <div className="shutdowndataheaderdiv">
                    <div className="shutdowndatatitlediv">
                        <br />
                        <span className="shutdowndatatext">
                            Shuttered companies
                            <sup data-tip="Discern meaningful insights from our collection of shuttered companies.">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>

                    <div className="shutdowndatastatsdiv">
                        <table className="tg" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td className="table-data db-header">
                                        Inactive companies
                                    </td>
                                    <td className="table-data db-header">
                                        Reasons for shutdown
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-data db-data">
                                        {tableData.statistics.inactiveCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="table-data db-data">
                                        {tableData.statistics.reasonsForShutdown.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="shutdowndatasearchdiv">
                    <div className="shutdowndatasearchbox">
                        <Form>
                            <Form.Group controlId="formSearch">
                                <Form.Control className="inputbox" value={argument.searchTerm} type="name" name="searchTerm" placeholder="Search from shuttered companies" onChange={changeFilters} onKeyDown={enterSearch} />
                            </Form.Group>
                        </Form>
                    </div>

                    <div className="shutdowndatasearchbuttons">
                        {
                            argument.searchTerm == null || argument.searchTerm == ""
                                ? <button className="shutdowndataresetbutton" disabled={true}>
                                    <span>Clear</span>
                                </button>
                                : <button className="shutdowndataresetbutton" onClick={resetSearch}>
                                    <span>Clear</span>
                                </button>
                        }

                        {
                            argument.searchTerm == null || argument.searchTerm == ""
                                ? <Button className="shutdowndatasearchbutton" disabled>
                                    <span>Search</span>
                                </Button>
                                : <Button className="shutdowndatasearchbutton" onClick={submitSearch}>
                                    <span>Search</span>
                                </Button>
                        }
                    </div>

                    <div className="shutdowndatahidefieldbuttons">
                        <p className="shutdowndatahidefieldbutton" onClick={unhideFields}>
                            {!hideField ? <span>Hide Field</span> : hideField && fieldName.length === 1 ? <span>Unhide Field</span> : <span>Unhide Fields</span>}
                        </p>
                        <p className="shutdownellipsisicon"><FaEllipsisH /></p>
                    </div>
                </div>

                <div className="shutdowndatadiv">
                    <table className="shutdowndatatable shuttered-companies" id="table-data">
                        <thead>
                            <tr>
                                <th>No.<span style={{ float: "right" }}></span></th>
                                <th style={{ color: "transparent" }}>Image</th>
                                {!fieldName.includes('tradeName') ? <th>Name<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "tradeName")} /></span></th> : null}
                                {!fieldName.includes('entityType') ? <th>Entity type<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "entityType")} /></span></th> : null}
                                {!fieldName.includes('description') ? <th>Description<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "description")} /></span></th> : null}
                                {!fieldName.includes('reasonsForFailure') ? <th>Reasons for shutdown<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "reasonsForFailure")} /></span></th> : null}
                                {!fieldName.includes('industry') ? <th>Industry<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "industry")} /></span></th> : null}
                                {!fieldName.includes('categories') ? <th>Categories<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "categories")} /></span></th> : null}
                                {!fieldName.includes('location') ? <th>Location<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "location")} /></span></th> : null}
                                {!fieldName.includes('ownership') ? <th>Ownership status<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "ownership")} /></span></th> : null}
                                {!fieldName.includes('stageOfDevelopment') ? <th>Stage of development<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "stageOfDevelopment")} /></span></th> : null}
                                {!fieldName.includes('businessModelType') ? <th>Transactional model type<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "businessModelType")} /></span></th> : null}
                                {!fieldName.includes('defensibilityMoat') ? <th>Defensibility moats<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "defensibilityMoat")} /></span></th> : null}
                                {!fieldName.includes('lastFinancingRound') ? <th>Last financing round<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "lastFinancingRound")} /></span></th> : null}
                                {!fieldName.includes('totalInvestment') ? <th>Total raised<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "totalInvestment")} /></span></th> : null}
                                {!fieldName.includes('postMoneyValuation') ? <th>Post-money valuation<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "postMoneyValuation")} /></span></th> : null}
                                {!fieldName.includes('lastFinancingDate') ? <th>Last financing date<span style={{ float: "right" }}><TimeDropdownComponent onClick={(value, header) => selectedDropdown(value, "lastFinancingDate")} /></span></th> : null}
                                {!fieldName.includes('numOfFundingRounds') ? <th>No. of funding round(s)<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "numOfFundingRounds")} /></span></th> : null}
                                {!fieldName.includes('numOfInvestors') ? <th>No. of investors<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "numOfInvestors")} /></span></th> : null}
                                {!fieldName.includes('investors') ? <th>Investors<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investors")} /></span></th> : null}
                                {!fieldName.includes('revenue') ? <th>Total revenue<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "revenue")} /></span></th> : null}
                                {!fieldName.includes('market') ? <th>Market capitalization<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "market")} /></span></th> : null}
                                {!fieldName.includes('investments') ? <th>No. of investments<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "investments")} /></span></th> : null}
                                {!fieldName.includes('numOfAcquisitions') ? <th>No. of acquisitions<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "numOfAcquisitions")} /></span></th> : null}
                                {!fieldName.includes('boardSeats') ? <th>No. of board seats taken<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "boardSeats")} /></span></th> : null}
                                {!fieldName.includes('competition') ? <th>Competition<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "competition")} /></span></th> : null}
                                {!fieldName.includes('similarEntities') ? <th>Similar entities<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "similarEntities")} /></span></th> : null}
                                {!fieldName.includes('management') ? <th>Management<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "management")} /></span></th> : null}
                                {!fieldName.includes('numOfEmployees') ? <th>No. of employees<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "numOfEmployees")} /></span></th> : null}
                                {!fieldName.includes('reputation') ? <th>Company reputation<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "reputation")} /></span></th> : null}
                                {!fieldName.includes('phoneNumber') ? <th>Contact info<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "phoneNumber")} /></span></th> : null}
                                {!fieldName.includes('year') ? <th>Operational duration<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "year")} /></span></th> : null}
                                {!fieldName.includes('shutdownDate') ? <th>Shutdown date<span style={{ float: "right" }}><TimeDropdownComponent onClick={(value, header) => selectedDropdown(value, "shutdownDate")} /></span></th> : null}
                                {!fieldName.includes('foundedDate') ? <th>Founded date<span style={{ float: "right" }}><TimeDropdownComponent onClick={(value, header) => selectedDropdown(value, "foundedDate")} /></span></th> : null}
                                {!fieldName.includes('legalName') ? <th>Legal name<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "legalName")} /></span></th> : null}
                                {!fieldName.includes('former_legalName') ? <th>Former legal name(s)<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "former_legalName")} /></span></th> : null}
                                {!fieldName.includes('ticker') ? <th>Ticker<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "ticker")} /></span></th> : null}
                                <th>Data room</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.results.map((company, index) =>
                                <tr key={index}>
                                    <td><p className="detailxsdata">{index + 1}.</p></td>

                                    {!company.logo
                                        ? (<td><img src={SampleImage} alt={company.tradeName} className="shutteredlogo" onClick={(e) => handleData(company.tradeName)} /></td>)
                                        : (<td><img src={S3Media + 'assets/photos/logo/' + company.logo} alt={company.tradeName} className="shutteredlogo" onClick={(e) => handleData(company.tradeName)} /></td>)
                                    }

                                    {!company.tradeName
                                        ? (<>{!fieldName.includes('tradeName') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('tradeName') ? <td>
                                            {!company.slug
                                                ? (<p className="detailmediumdata">{company.tradeName}</p>)
                                                : (<>
                                                    {/* <Button className="shutdowndataeditbutton">
                                                        <Link to={`/data/edit/${collection}/${company.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndataedit">
                                                            <FaEdit />
                                                        </Link>
                                                    </Button> */}
                                                    <p className="detailmediumdata">
                                                        {/* dynamically pass slug of element that is clicked */}
                                                        <Link to={`/company/${company.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                            {company.tradeName}
                                                        </Link>
                                                    </p>
                                                </>)
                                            }
                                        </td> : null}</>)
                                        // : (<td><p className="detailmediumdata">{company.tradeName}</p></td>)
                                    }

                                    {!company.entityType
                                        ? (<>{!fieldName.includes('entityType') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}> - </p></td> : null}</>)
                                        : (<>{!fieldName.includes('entityType') ? <td><p className="detailmediumdata">{company.entityType}</p></td> : null}</>)
                                    }

                                    {!company.description
                                        ? (<>{!fieldName.includes('description') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('description')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => openExpandCard(company.description)}><AiOutlineExpandAlt /></Button>
                                                <p className="detaillongdata">{company.description}</p>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.reasonsForFailure
                                        ? (<>{!fieldName.includes('reasonsForFailure') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('reasonsForFailure')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => openExpandCard(company.reasonsForFailure)}><AiOutlineExpandAlt /></Button>
                                                <div className="detailmldata detailcutdata">
                                                    <ol className="detailproductlist">{company.reasonsForFailure.map((reason, index) =>
                                                        !reason
                                                            ? ""
                                                            : (<li key={index} className="detailbodydata">{reason.label}</li>)
                                                    )}
                                                    </ol>
                                                </div>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.industry
                                        ? (<>{!fieldName.includes('industry') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('industry') ? <td><p className="detailmldata">{company.industry}</p></td> : null}</>)
                                    }

                                    {!company.categories
                                        ? (<>{!fieldName.includes('categories') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('categories')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => openExpandCard(company.categories.join(", "))}><AiOutlineExpandAlt /></Button>
                                                <p className="detailmldata detailcutdata">{company.categories.join(", ")}</p>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.details.location
                                        ? (<>{!fieldName.includes('location') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('location') ? <td><p className="detailmediumdata">{!company.details.location.city ? "" : <span>{company.details.location.city},</span>} {!company.details.location.state ? "" : <span>{company.details.location.state},</span>} {!company.details.location.country ? "" : <span>{company.details.location.country}</span>}</p></td> : null}</>)
                                    }

                                    {/* ownership status */}
                                    {!fieldName.includes('ownership') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {!company.stageOfDevelopment
                                        ? (<>{!fieldName.includes('stageOfDevelopment') ? <td><p className="detailml2data" style={{ textAlign: "center" }} >-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('stageOfDevelopment') ? <td><p className="detailml2data" style={{ textAlign: "center" }}>{company.stageOfDevelopment}</p></td> : null}</>)
                                    }

                                    {!company.businessModelType
                                        ? (<>{!fieldName.includes('businessModelType') ? <td><p className="detailml2data" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('businessModelType') ? <td><p className="detailml2data" style={{ textAlign: "center" }}>{company.businessModelType}</p></td> : null}</>)
                                    }

                                    {!company.defensibilityMoat
                                        ? (<>{!fieldName.includes('defensibilityMoat') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('defensibilityMoat')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => openExpandCard(company.defensibilityMoat.join(", "))}><AiOutlineExpandAlt /></Button>
                                                <p className="detailmldata detailcutdata">{company.defensibilityMoat.join(", ")}</p>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.details.lastFinancingRound
                                        ? (<>{!fieldName.includes('lastFinancingRound') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('lastFinancingRound') ? <td><p className="detailmldata">{company.details.lastFinancingRound}</p></td> : null}</>)
                                    }

                                    {!company.details.totalInvestment
                                        ? (<>{!fieldName.includes('totalInvestment') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('totalInvestment') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.totalInvestment.currency} {company.details.totalInvestment.total}{company.details.totalInvestment.symbol}</p></td> : null}</>)
                                    }

                                    {/* post-money valuation */}
                                    {!fieldName.includes('postMoneyValuation') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {!company.details.lastFinancingDate
                                        ? (<>{!fieldName.includes('lastFinancingDate') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('lastFinancingDate') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.lastFinancingDate}</p></td> : null}</>)
                                    }

                                    {!company.details.numOfFundingRounds
                                        ? (<>{!fieldName.includes('numOfFundingRounds') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('numOfFundingRounds') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.details.numOfFundingRounds}</p></td> : null}</>)
                                    }

                                    {!company.details.numOfInvestors
                                        ? (<>{!fieldName.includes('numOfInvestors') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('numOfInvestors') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.numOfInvestors.total}</p></td> : null}</>)
                                    }

                                    {!company.investors
                                        ? (<>{!fieldName.includes('investors') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('investors')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => getCompanyDetails({ slug: company.slug, field: "investors", table: "companies" })}><AiOutlineExpandAlt /></Button>
                                                <div className="detailmldata detailcutdata">
                                                    <ol className="detailproductlist">{company.investors.map((investor, index) =>
                                                        !investor
                                                            ? ""
                                                            : (<li key={index} className="detailbodydata">{investor.name}</li>)
                                                    )}
                                                    </ol>
                                                </div>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {/* total revenue */}
                                    {!fieldName.includes('revenue') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {/* market capitalization */}
                                    {!fieldName.includes('market') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {/* no. of investments */}
                                    {!fieldName.includes('investments') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {!company.details.numOfAcquisitions
                                        ? (<>{!fieldName.includes('numOfAcquisitions') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('numOfAcquisitions') ? <td><p className="detailshortdata">{company.details.numOfAcquisitions}</p></td> : null}</>)
                                    }

                                    {/* no. of board seats taken */}
                                    {!fieldName.includes('boardSeats') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {!company.competitors
                                        ? (<>{!fieldName.includes('competition') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('competition')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => getCompanyDetails({ slug: company.slug, field: "competitors", table: "companies" })}><AiOutlineExpandAlt /></Button>
                                                <div className="detailmldata detailcutdata">
                                                    <ol className="detailproductlist">{company.competitors.map((competitor, index) =>
                                                        !competitor
                                                            ? ""
                                                            : (<li key={index} className="detailbodydata">{competitor.name}</li>)
                                                    )}
                                                    </ol>
                                                </div>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.similarEntities
                                        ? (<>{!fieldName.includes('similarEntities') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('similarEntities')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => getCompanyDetails({ slug: company.slug, field: "similarEntities", table: "companies" })}><AiOutlineExpandAlt /></Button>
                                                <div className="detailmldata detailcutdata">
                                                    <ol className="detailproductlist">{company.similarEntities.map((similar, index) =>
                                                        !similar
                                                            ? ""
                                                            : (<li key={index} className="detailbodydata">{similar.name}</li>)
                                                    )}
                                                    </ol>
                                                </div>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.management
                                        ? (<>{!fieldName.includes('management') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('management')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => openExpandCard(company.management)}><AiOutlineExpandAlt /></Button>
                                                <div className="detailmldata detailcutdata">
                                                    <ol className="detailproductlist">{company.management.map((founder, index) =>
                                                        !founder
                                                            ? ""
                                                            : (<li key={index} className="detailbodydata">{founder.name}</li>)
                                                    )}
                                                    </ol>
                                                </div>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {!company.details.numOfEmployees
                                        ? (<>{!fieldName.includes('numOfEmployees') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('numOfEmployees') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.numOfEmployees.min} - {company.details.numOfEmployees.max}</p></td> : null}</>)
                                    }

                                    {/* company reputation */}
                                    {!fieldName.includes('reputation') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                    {!company.details.phoneNumber
                                        ? (<>{!fieldName.includes('phoneNumber') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('phoneNumber') ? <td><p className="detailshortdata">{company.details.phoneNumber}</p></td> : null}</>)
                                    }

                                    {!company.details.year
                                        ? (<>{!fieldName.includes('year') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : company.details.year.lifespan > 1
                                            ? (<>{!fieldName.includes('year') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.details.year.lifespan} years</p></td> : null}</>)
                                            : (<>{!fieldName.includes('year') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.details.year.lifespan} year</p></td> : null}</>)
                                    }

                                    {!company.details.date
                                        ? (<>{!fieldName.includes('shutdownDate') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('shutdownDate') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.date.ended}</p></td> : null}</>)
                                    }

                                    {!company.details.date
                                        ? (<>{!fieldName.includes('foundedDate') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('foundedDate') ? <td><p className="detailshortdata">{company.details.date.started}</p></td> : null}</>)
                                    }

                                    {!company.legalName
                                        ? (<>{!fieldName.includes('legalName') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('legalName') ? <td><p className="detailmediumdata">{company.legalName}</p></td> : null}</>)
                                    }

                                    {!company.former_legalName
                                        ? (<>{!fieldName.includes('former_legalName') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                        : (<>{!fieldName.includes('former_legalName')
                                            ? <td>
                                                <Button className="expand-button" onClick={() => openExpandCard(company.former_legalName.join(", "))}><AiOutlineExpandAlt /></Button>
                                                <p className="detailmldata detailcutdata">{company.former_legalName.join(", ")}</p>
                                            </td>
                                            : null
                                        }</>)
                                    }

                                    {/* ticker symbol */}
                                    {!fieldName.includes('ticker') ? <td><p className="detailshortdata">-</p></td> : null}

                                    <td><p className="detailshortdata">
                                        {!company.pitchdeck_show || company.pitchdeck_show === false
                                            ? "-"
                                            : <Link to={`/company/${company.slug}#data-room`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                Pitch deck
                                            </Link>
                                        }
                                    </p></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="shutdowndatafilters">
                    <div className="filterrownumber">
                        {argument.searchTerm === "" || argument.selectedHeader === "" || argument.selectedDropdown === ""
                            ? (<p>{page.startNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}-{page.endNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>)
                            : (<p>1-{tableData.results.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>)
                        }
                    </div>

                    <div className="filterpagination">
                        {
                            argument.currentPage != 1
                                ? (<Button name="firstPage" onClick={changeFilters} className="filterpage">First</Button>)
                                : ""
                        }
                        {
                            argument.currentPage > 1
                                ? (<Button name="prevPage" onClick={changeFilters} className="filterpage">Prev</Button>)
                                : ""
                        }
                        {
                            argument.currentPage < page.lastPage
                                ? (<Button name="nextPage" onClick={changeFilters} className="filterpage">Next</Button>)
                                : ""
                        }
                        {
                            argument.currentPage != page.lastPage
                                ? (<Button name="lastPage" onClick={changeFilters} className="filterpage">Last</Button>)
                                : ""
                        }
                    </div>

                    <div className="filterrowshow">
                        <p>Show rows: </p>
                        <Form.Control value={argument.numOfRows} as="select" name="numOfRows" onChange={changeFilters} className="filterrowselect">
                            <option>50</option>
                            {argument.searchTerm === "" || argument.selectedHeader === "" || argument.selectedDropdown === ""
                                ? (<>
                                    <option>100</option>
                                    <option>500</option>
                                </>)
                                : ""
                            }
                        </Form.Control>
                    </div>
                </div>
            </div>

            {/* pop up modal for more row's info */}
            <Modal show={showCard} onHide={hideCard} backdrop="static" fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="companies-header close" closeButton />

                <Modal.Body className="companies-body">

                    {moreInfo.map((companies) =>
                        <div key={companies.slug}>
                            <CompaniesCard
                                slug={companies.slug}
                                name={companies.name}
                                tradeName={companies.tradeName}
                                description={companies.description}
                                logo={companies.logo}
                                reasonsForFailure={companies.reasonsForFailure}
                                categories={companies.categories}
                                location={companies.location}
                                country={companies.country}
                                countryCode={companies.countryCode}
                                state={companies.state}
                                city={companies.city}
                                industry={companies.industry}
                                products={companies.products}
                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* expand card modal */}
            <Modal className="modal-div" show={expandCard} onHide={closeExpandCard} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <div style={{ border: "1px solid #dee2e6", borderRadius: "0.35rem", height: "80vh", overflow: "auto" }}>
                        {Array.isArray(expandData)
                            ? <ol style={{ paddingTop: "1.5em", paddingRight: "1.5em", paddingBottom: "1.5em", margin: "0 !important" }}>
                                {expandData.map((element, index) =>
                                    !element
                                        ? ""
                                        : element.label
                                            ? (<li key={index}>{element.label}{!element.reason ? "" : ": " + element.reason}</li>)
                                            : element.name
                                                ? (<li key={index}>
                                                    {
                                                        !element.slug
                                                            ? element.name
                                                            : (<Link to={`/company/${element.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                                {element.name}
                                                            </Link>)
                                                    }: {
                                                        !element.description
                                                            ? "No data available at this time."
                                                            : element.description
                                                    }
                                                </li>)
                                                : ""
                                )}
                            </ol>
                            : <p style={{ padding: "1.5em", margin: "0 !important" }}>{expandData}</p>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShutteredCompanies;