import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewRisk(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="riskDisclosure">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="riskDisclosure">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Risk disclosure
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="riskDisclosure">
                        <Card.Body id="companyinformation">
                            {!props?.technologyRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Technology risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.technologyRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.intellectualPropertyRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Intellectual property risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.intellectualPropertyRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.developmentRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Development risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.developmentRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.operationalRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Operational risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.operationalRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.productFitRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Product-fit risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.productFitRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.channelRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Channel risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.channelRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.regulatoryRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Regulatory risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.regulatoryRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.competitorRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Competitor risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.competitorRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.teamRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Team risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.teamRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.hiringRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Hiring risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.hiringRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.financingRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Financing risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.financingRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.ceoRisk
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">CEO risk</p>
                                    <p className="companydetaildata">
                                        <ul>{props.ceoRisk.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewRisk;