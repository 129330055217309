import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewMarket(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="customers">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="customers">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Customers
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="customers">
                        <Card.Body id="companyinformation">
                            {!props?.customerSegments
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Who are your customer segments whom you are serving and providing value for?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.customerSegments.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="marketsizing" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="marketsizing">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Market sizing
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="marketsizing">
                        <Card.Body id="companyinformation">
                            {!props?.marketSegments
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How big are the market segments with the same problem worth solving for and how large can the market become?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.marketSegments.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="opportunity" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="opportunity">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Opportunity
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="opportunity">
                        <Card.Body id="companyinformation">
                            {!props?.problemProductSolves
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is the specific customer problem that your product-or-service solves?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.problemProductSolves.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doesProductMakeLivesBetter
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Does the product-or-service you're proposing make your customers' lives better?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doesProductMakeLivesBetter.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="markettiming" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="markettiming">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Market timing
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="markettiming">
                        <Card.Body id="companyinformation">
                            {!props?.marketTiming
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Why now (market timing)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.marketTiming.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewMarket;