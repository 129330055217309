import React, { useReducer } from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Col, Card, Form, ProgressBar, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline, IoMdAdd } from 'react-icons/io';

const CreateEditMarketing = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    return (

        <div className="ideacheckertabbody">
            <Col className="ideacheckerquestions">

                <Accordion className="companyaccordion" defaultActiveKey="goToMarket">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="goToMarket">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Go-to-market (distribution channel)
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="goToMarket">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your go-to-market strategy to gain visibility and expand distribution channels for your product-or-service to acquire new customers?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.goToMarketStrategy.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="goToMarketStrategy"
                                                                                value={props.state.goToMarketStrategy[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update goToMarketStrategy", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.goToMarketStrategy.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove goToMarketStrategy", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.goToMarketStrategy.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add goToMarketStrategy", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Does your business model support customer acquisition profitably?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.doesBusinessModelSupportAcquisition.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="doesBusinessModelSupportAcquisition"
                                                                                value={props.state.doesBusinessModelSupportAcquisition[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update doesBusinessModelSupportAcquisition", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.doesBusinessModelSupportAcquisition.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove doesBusinessModelSupportAcquisition", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.doesBusinessModelSupportAcquisition.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add doesBusinessModelSupportAcquisition", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Are you planning to acquire customers through brand marketing or other online forms of acquisition?
                                                            <sup data-tip="Explain how you are thinking about the costs of such activities relative to the lifetime value of a customer.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.planningToAcquireCustomersThroughMarketing.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="planningToAcquireCustomersThroughMarketing"
                                                                                value={props.state.planningToAcquireCustomersThroughMarketing[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update planningToAcquireCustomersThroughMarketing", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.planningToAcquireCustomersThroughMarketing.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove planningToAcquireCustomersThroughMarketing", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.planningToAcquireCustomersThroughMarketing.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add planningToAcquireCustomersThroughMarketing", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How will your product-or-service be distributed to the end user?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howProductWillBeDistributed.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howProductWillBeDistributed"
                                                                                value={props.state.howProductWillBeDistributed[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howProductWillBeDistributed", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howProductWillBeDistributed.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howProductWillBeDistributed", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howProductWillBeDistributed.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howProductWillBeDistributed", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What distribution channels will you use?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.distributionChannelsUsed.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="distributionChannelsUsed"
                                                                                value={props.state.distributionChannelsUsed[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update distributionChannelsUsed", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.distributionChannelsUsed.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove distributionChannelsUsed", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.distributionChannelsUsed.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add distributionChannelsUsed", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            When, where, why, and how do consumers buy your product-or-service?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.whenWhereWhyHowConsumersBuy.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="whenWhereWhyHowConsumersBuy"
                                                                                value={props.state.whenWhereWhyHowConsumersBuy[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update whenWhereWhyHowConsumersBuy", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.whenWhereWhyHowConsumersBuy.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove whenWhereWhyHowConsumersBuy", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.whenWhereWhyHowConsumersBuy.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add whenWhereWhyHowConsumersBuy", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is it transaction oriented (where you have limited communication with your customers and focus instead on efficiency or speed, such as a self-service kiosk at an airport terminal)?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.isProductTransactionOriented.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="isProductTransactionOriented"
                                                                                value={props.state.isProductTransactionOriented[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update isProductTransactionOriented", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.isProductTransactionOriented.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove isProductTransactionOriented", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.isProductTransactionOriented.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add isProductTransactionOriented", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is the process of buying your product-or-service relationship oriented (like a loyalty program where you learn customer preferences and make suggestions about other items of interest)?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.isBuyingProcessRelationshipOriented.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="isBuyingProcessRelationshipOriented"
                                                                                value={props.state.isBuyingProcessRelationshipOriented[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update isBuyingProcessRelationshipOriented", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.isBuyingProcessRelationshipOriented.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove isBuyingProcessRelationshipOriented", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.isBuyingProcessRelationshipOriented.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add isBuyingProcessRelationshipOriented", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="promotion" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="promotion">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Promotion
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="promotion">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Are you planning to build a direct, outside sales force, and can the average selling price of your product-or-service support this go-to-market strategy?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.areYouPlanningToBuildDirectForce.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="areYouPlanningToBuildDirectForce"
                                                                                value={props.state.areYouPlanningToBuildDirectForce[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update areYouPlanningToBuildDirectForce", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.areYouPlanningToBuildDirectForce.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove areYouPlanningToBuildDirectForce", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.areYouPlanningToBuildDirectForce.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add areYouPlanningToBuildDirectForce", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How will you communicate with consumers and make them aware of your product-or-service?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howToCommunicateWithConsumers.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howToCommunicateWithConsumers"
                                                                                value={props.state.howToCommunicateWithConsumers[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howToCommunicateWithConsumers", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howToCommunicateWithConsumers.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howToCommunicateWithConsumers", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howToCommunicateWithConsumers.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howToCommunicateWithConsumers", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Will you use word of mouth?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.willUseWordOfMouth.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="willUseWordOfMouth"
                                                                                value={props.state.willUseWordOfMouth[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update willUseWordOfMouth", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.willUseWordOfMouth.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove willUseWordOfMouth", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.willUseWordOfMouth.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add willUseWordOfMouth", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Will you opt for sales promotions?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.willOptForSalesPromotions.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="willOptForSalesPromotions"
                                                                                value={props.state.willOptForSalesPromotions[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update willOptForSalesPromotions", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.willOptForSalesPromotions.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove willOptForSalesPromotions", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.willOptForSalesPromotions.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add willOptForSalesPromotions", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Do direct sales make the most sense?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.doDirectSalesMakeSense.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="doDirectSalesMakeSense"
                                                                                value={props.state.doDirectSalesMakeSense[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update doDirectSalesMakeSense", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.doDirectSalesMakeSense.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove doDirectSalesMakeSense", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.doDirectSalesMakeSense.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add doDirectSalesMakeSense", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is traditional advertising a better approach?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.isTraditionalBetter.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="isTraditionalBetter"
                                                                                value={props.state.isTraditionalBetter[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update isTraditionalBetter", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.isTraditionalBetter.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove isTraditionalBetter", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.isTraditionalBetter.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add isTraditionalBetter", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Do you need to build brand loyalty, or is your product-or-service the only one that will fill customers' needs?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.doYouNeedToBuildBrandLoyalty.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="doYouNeedToBuildBrandLoyalty"
                                                                                value={props.state.doYouNeedToBuildBrandLoyalty[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update doYouNeedToBuildBrandLoyalty", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.doYouNeedToBuildBrandLoyalty.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove doYouNeedToBuildBrandLoyalty", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.doYouNeedToBuildBrandLoyalty.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add doYouNeedToBuildBrandLoyalty", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </Col>

            <Col className="ideacheckerresults">
                <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats" >
                            <div className="companytitlecard">
                                Results
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="resultstats">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>

                                <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                    Marketing score: {props.state.analysisResult?.marketing.score
                                        ? props.state.analysisResult.marketing.score > 0
                                            ? props.state.analysisResult.marketing.score
                                            : 0
                                        : 0}%
                                </div>

                                <ProgressBar now={props.state.analysisResult?.marketing.score ? props.state.analysisResult.marketing.score : 0} variant="danger" style={{ height: "2rem" }} />

                                <br />

                                {/* {
                                    (ideaCheckerRes?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {ideaCheckerRes.error}
                                        </Alert>)
                                        : ""
                                } */}

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="notification" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification" >
                            <div className="companytitlecard">
                                Notification
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="notification">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                <span className="info-notes" style={{ fontSize: "16px" }}>
                                    Note:
                                    <ul style={{ paddingLeft: "17px" }}>
                                        <li id="notes-points">Write no less than 2 words for short answers</li>
                                        <li id="notes-points">Write more than 7 words for normal answers</li>
                                        <li id="notes-points">Due to large datasets, it may take up to 1 min to compute and show results.</li>
                                        <li id="notes-points">We use advanced real-time analytics and text analytics to analyze your inputs.</li>
                                    </ul>
                                </span>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                            <div className="companytitlecard">
                                Feedback
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="feedback">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                {!props.state.analysisResult
                                    ? ("")
                                    : (<div className="pitchdeckrecommended">
                                        Recommended readings:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {props.state.analysisResult?.marketing.recommended_read.map((reading, index) =>
                                                <li key={index}>
                                                    <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>)
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </div>

    )

}

export default CreateEditMarketing;