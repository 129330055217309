import React, { useState } from 'react'
import './login-form.css'
import { Card, Button, Form } from 'react-bootstrap'
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';

function LoginForm(props) {

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div id="logincontent">

      <div className="logintop">
        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
          <img src={HeaderLogo} alt="roiquant" width="180px" height="60px" className="roiquantlogo" />
        </a>
      </div>

      <div className="createaccalerttop">
        {props.response}
      </div>

      <div id="loginform">
        <Card className="logincard">
          <div className="loginheader">
            <h3>Log into your roiquant account</h3>
          </div>

          <div className="createaccnote"></div>

          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control className="inputbox" type="email" name="email" placeholder="Email"
                onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { email: e.target.value }))}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Control className="inputbox" type={passwordShown ? "text" : "password"} name="password" placeholder="Password"
                onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { password: e.target.value }))}
              />

              <a href="#" className='showpassword' onClick={togglePassword}>{passwordShown ? "Hide" : "Show"} password</a>

              <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "15px", marginBottom: "-15px" }}>
                <div id="checksField" style={{ width: "50%", paddingLeft: "15%" }}>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdLength(props.credentials.password) ? "green" : "red" }}>At least 8 characters or as long as possible</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdSymbol(props.credentials.password) ? "green" : "red" }}>Include at least 1 symbol character</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdNum(props.credentials.password) ? "green" : "red" }}>Include at least 1 number</p>
                </div>

                <div id="checksField" style={{ width: "50%" }}>
                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdLowercase(props.credentials.password) ? "green" : "red" }}>At least 1 lowercase letter</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdUppercase(props.credentials.password) ? "green" : "red" }}>At least 1 uppercase letter</p>
                </div>
              </div>
            </Form.Group>

            <div style={{ textAlign: "center", marginBottom: "-15px" }}>
              <Button className="loginbtn glintanimation" type="submit"
                onClick={(event) => { event.preventDefault(); props.handleLogin(props.credentials) }}
                disabled={props.credentials.email !== "demo@roiquant.com" ? true : false}>
                <span>Log in</span>
              </Button>
            </div>

          </Form>

        </Card>
      </div>

    </div>
  );
}

export default LoginForm