import React, { useRef } from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Col, Card, Form, ProgressBar, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline, IoMdAdd } from 'react-icons/io';
import { Multiselect } from 'multiselect-react-dropdown';

const CreateEditBusiness = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    const multiselectFinancingRoundRef = useRef(null);

    return (

        <div className="ideacheckertabbody">
            <Col className="ideacheckerquestions">

                <Accordion className="companyaccordion" defaultActiveKey="businessDescription">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="businessDescription">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Business description
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="businessDescription">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Business description
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        <Form.Control
                                                        placeholder="Briefly describe what your entity does in less than 300 words"
                                                        value={props.state.businessDescription}
                                                        onChange={(e) => { props.setState({ "type": "update businessDescription", "value": e.target.value }) }}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="problem" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="problem">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Problem
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="problem">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What real-world problem are you solving (customer pain)?
                                                            <sup data-tip="Clearly describe the problems that your customers are facing whereby existing solutions in the market are not good enough.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.customerPain.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="customerPain"
                                                                                value={props.state.customerPain[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update customerPain", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.customerPain.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove customerPain", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.customerPain.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add customerPain", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Are you solving a personal critical pain point?
                                                            <sup data-tip="If yes, explain how you encountered this critical problem?">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.areYouSolvingPersonalCriticalPainPoint.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="areYouSolvingPersonalCriticalPainPoint"
                                                                                value={props.state.areYouSolvingPersonalCriticalPainPoint[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update areYouSolvingPersonalCriticalPainPoint", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.areYouSolvingPersonalCriticalPainPoint.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove areYouSolvingPersonalCriticalPainPoint", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.areYouSolvingPersonalCriticalPainPoint.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add areYouSolvingPersonalCriticalPainPoint", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How is the problem which you described being addressed in today's market?
                                                            <sup data-tip="Explain how the problems are being solved by existing solutions providers in today's market.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howProblemIsAddressed.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howProblemIsAddressed"
                                                                                value={props.state.howProblemIsAddressed[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howProblemIsAddressed", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howProblemIsAddressed.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howProblemIsAddressed", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howProblemIsAddressed.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howProblemIsAddressed", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are the shortcomings to the current solutions in the market?
                                                            <sup data-tip="Explain how the current solutions in the market are not good enough to fully resolve the problems you described.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.currentSolutionsShortcomings.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="currentSolutionsShortcomings"
                                                                                value={props.state.currentSolutionsShortcomings[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update currentSolutionsShortcomings", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.currentSolutionsShortcomings.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove currentSolutionsShortcomings", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.currentSolutionsShortcomings.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add currentSolutionsShortcomings", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="growthModel" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="growthModel">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Growth model
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="growthModel">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How do you intend to grow the business?
                                                            <sup data-tip="Explain how you are growing your business to the next stage.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howToGrowBusiness.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howToGrowBusiness"
                                                                                value={props.state.howToGrowBusiness[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howToGrowBusiness", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howToGrowBusiness.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howToGrowBusiness", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howToGrowBusiness.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howToGrowBusiness", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What does the unit economics look like at scale, particularly when competitors enter the market and as your defensibility moat develops?
                                                            <sup data-tip="Show your unit economics to quantitatively demonstrate your business growth potential at scale and ability to defend your business.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.unitEconomics.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="unitEconomics"
                                                                                value={props.state.unitEconomics[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update unitEconomics", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.unitEconomics.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove unitEconomics", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.unitEconomics.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add unitEconomics", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Is your business the type that looks to increase or decrease prices as you scale?
                                                            <sup data-html={true} data-tip="a. Define the type of business.<br />b. Explain how your products-or-services' prices increase or decrease as your business scales.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.doesPriceChange.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="doesPriceChange"
                                                                                value={props.state.doesPriceChange[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update doesPriceChange", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.doesPriceChange.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove doesPriceChange", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.doesPriceChange.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add doesPriceChange", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="businessValuation" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="businessValuation">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Business valuation
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="businessValuation">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your pre-money valuation at the current stage?
                                                            <sup data-tip="Determine the economic value of your business before it received outside investment. Need help? Estimate your business value with our pre-revenue valuation simulator.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.preMoneyValuation.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="preMoneyValuation"
                                                                                value={props.state.preMoneyValuation[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update preMoneyValuation", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.preMoneyValuation.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove preMoneyValuation", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.preMoneyValuation.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add preMoneyValuation", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your post-money valuation at the current stage?
                                                            <sup data-tip="Determine the economic value of your business after it received a round of financing, or estimate your projected valuation based on your fundraising target amount if your business have not received financing. Need help? Estimate your business value with our pre-revenue valuation simulator.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.postMoneyValuation.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="postMoneyValuation"
                                                                                value={props.state.postMoneyValuation[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update postMoneyValuation", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.postMoneyValuation.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove postMoneyValuation", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.postMoneyValuation.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add postMoneyValuation", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="equityStake" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="equityStake">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Equity stake
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="equityStake">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How much equity ownership stake of your company are you offering to your investor(s)?
                                                            <sup data-tip="Determine the percentage of equity ownership stake of your company that you decide to offer your investor(s).">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.equityOwnershipStakeToInvestors.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="equityOwnershipStakeToInvestors"
                                                                                value={props.state.equityOwnershipStakeToInvestors[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update equityOwnershipStakeToInvestors", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.equityOwnershipStakeToInvestors.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove equityOwnershipStakeToInvestors", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.equityOwnershipStakeToInvestors.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add equityOwnershipStakeToInvestors", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="financingStrategy" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="financingStrategy">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Financing strategy
                                <sup data-tip="Outline your financing requirements, timeline, and how you will achieve your key milestones with the funding.">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="financingStrategy">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            <sup>*</sup>
                                                            State the financing round that you are fundraising for.
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        <Multiselect
                                                            ref={multiselectFinancingRoundRef}
                                                            options={[
                                                                { key: "Accelerator" },
                                                                { key: "Angel" },
                                                                { key: "Convertible Note" },
                                                                { key: "Equity Crowdfunding" },
                                                                { key: "Grant" },
                                                                { key: "Incubator" },
                                                                { key: "Mezzanine" },
                                                                { key: "PIPE Financing" },
                                                                { key: "Post-IPO Debt" },
                                                                { key: "Post-IPO Equity" },
                                                                { key: "Post-IPO Secondary" },
                                                                { key: "Post-Seed" },
                                                                { key: "Pre-IPO" },
                                                                { key: "Pre-Seed" },
                                                                { key: "Pre-series A" },
                                                                { key: "Pre-series B" },
                                                                { key: "Pre-series C" },
                                                                { key: "SAFE" },
                                                                { key: "Secondary Market" },
                                                                { key: "Seed" },
                                                                { key: "Series A" },
                                                                { key: "Series B" },
                                                                { key: "Series C" },
                                                                { key: "Series D" },
                                                                { key: "Series E" },
                                                                { key: "Series F" },
                                                                { key: "Series G" },
                                                                { key: "Series H" },
                                                                { key: "Series I" },
                                                                { key: "Series J" }
                                                            ]}
                                                            selectedValues={(props.state.financingRound) ? [{ key: props.state.financingRound }] : []}
                                                            displayValue="key"
                                                            avoidHighlightFirstOption="true"
                                                            selectionLimit="1"
                                                            showCheckbox="true"
                                                            placeholder="Which financing round are you fundraising for?"
                                                            hidePlaceholder="true"
                                                            emptyRecordMsg=""
                                                            style={{
                                                                searchBox: {
                                                                    border: "1px solid #ced4da",
                                                                    height: "35px",
                                                                    fontFamily: "AileronRegular",
                                                                    fontSize: "14px",
                                                                    color: "#495057"
                                                                },
                                                                option: {
                                                                    fontFamily: "AileronRegular",
                                                                    fontSize: "14px",
                                                                    color: "#495057"
                                                                }
                                                            }}

                                                            onSelect={() => {
                                                                const selectedItems = multiselectFinancingRoundRef.current.getSelectedItems();
                                                                let list = selectedItems[0].key;
                                                                if (!list) list = "";
                                                                props.setState({ type: "update financingRound", value: list });
                                                            }}
                                                        />
                                                        <Form.Text className="text-muted">Select financing round</Form.Text>
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your financing strategy?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.financingStrategy.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="financingStrategy"
                                                                                value={props.state.financingStrategy[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update financingStrategy", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.financingStrategy.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove financingStrategy", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.financingStrategy.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add financingStrategy", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How do you intend to use the money (use of proceeds)?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howToUseMoney.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howToUseMoney"
                                                                                value={props.state.howToUseMoney[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howToUseMoney", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howToUseMoney.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howToUseMoney", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howToUseMoney.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howToUseMoney", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How much money do you need to raise?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howMuchMoneyToRaise.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howMuchMoneyToRaise"
                                                                                value={props.state.howMuchMoneyToRaise[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howMuchMoneyToRaise", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howMuchMoneyToRaise.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howMuchMoneyToRaise", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howMuchMoneyToRaise.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howMuchMoneyToRaise", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How much do you expect from investors?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howMuchExpectedFromInvestors.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howMuchExpectedFromInvestors"
                                                                                value={props.state.howMuchExpectedFromInvestors[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howMuchExpectedFromInvestors", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howMuchExpectedFromInvestors.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howMuchExpectedFromInvestors", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howMuchExpectedFromInvestors.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howMuchExpectedFromInvestors", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What are the key milestones that you have to achieve before raising money again?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.milestonesToAchieveBeforeRaiseMoney.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="milestonesToAchieveBeforeRaiseMoney"
                                                                                value={props.state.milestonesToAchieveBeforeRaiseMoney[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update milestonesToAchieveBeforeRaiseMoney", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.milestonesToAchieveBeforeRaiseMoney.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove milestonesToAchieveBeforeRaiseMoney", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.milestonesToAchieveBeforeRaiseMoney.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add milestonesToAchieveBeforeRaiseMoney", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Are you raising enough money to accomplish the milestones you set, such that the next investor will be willing to invest new money at a substantially higher valuation than the current round?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.areYouRaisingEnoughToAccomplishMilestones.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="areYouRaisingEnoughToAccomplishMilestones"
                                                                                value={props.state.areYouRaisingEnoughToAccomplishMilestones[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update areYouRaisingEnoughToAccomplishMilestones", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.areYouRaisingEnoughToAccomplishMilestones.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove areYouRaisingEnoughToAccomplishMilestones", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.areYouRaisingEnoughToAccomplishMilestones.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add areYouRaisingEnoughToAccomplishMilestones", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="exitStrategy" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="exitStrategy">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Exit strategy
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="exitStrategy">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            What is your business exit strategy?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.exitStrategy.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="exitStrategy"
                                                                                value={props.state.exitStrategy[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update exitStrategy", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.exitStrategy.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove exitStrategy", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.exitStrategy.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add exitStrategy", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            How will the investors ultimately get their money out of the business, assuming that it is successful, even if only marginally so?
                                                            <sup data-tip="">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.howInvestorsGetMoney.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="howInvestorsGetMoney"
                                                                                value={props.state.howInvestorsGetMoney[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update howInvestorsGetMoney", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.howInvestorsGetMoney.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove howInvestorsGetMoney", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.howInvestorsGetMoney.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add howInvestorsGetMoney", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </Col>

            <Col className="ideacheckerresults">
                <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats" >
                            <div className="companytitlecard">
                                Results
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="resultstats">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>

                                <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                    Business score: {props.state.analysisResult?.business.score
                                        ? props.state.analysisResult.business.score > 0
                                            ? props.state.analysisResult.business.score
                                            : 0
                                        : 0}%
                                </div>

                                <ProgressBar now={props.state.analysisResult?.business.score ? props.state.analysisResult.business.score : 0} variant="danger" style={{ height: "2rem" }} />

                                <br />

                                {/* {
                                    (ideaCheckerRes?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {ideaCheckerRes.error}
                                        </Alert>)
                                        : ""
                                } */}

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="notification" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification" >
                            <div className="companytitlecard">
                                Notification
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="notification">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                <span className="info-notes" style={{ fontSize: "16px" }}>
                                    Note:
                                    <ul style={{ paddingLeft: "17px" }}>
                                        <li id="notes-points">Write no less than 2 words for short answers</li>
                                        <li id="notes-points">Write more than 7 words for normal answers</li>
                                        <li id="notes-points">Due to large datasets, it may take up to 1 min to compute and show results.</li>
                                        <li id="notes-points">We use advanced real-time analytics and text analytics to analyze your inputs.</li>
                                    </ul>
                                </span>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                            <div className="companytitlecard">
                                Feedback
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="feedback">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                {!props.state.analysisResult
                                    ? ("")
                                    : (<div className="pitchdeckrecommended">
                                        Recommended readings:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {props.state.analysisResult?.business.recommended_read.map((reading, index) =>
                                                <li key={index}>
                                                    <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>)
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </div>

    )

}

export default CreateEditBusiness;