import React, { useReducer, useEffect, useState } from 'react';
import '../tools/founders/pitch-deck-analysis/pitch-deck-analysis.css';
import NavBar from '../../layout/navbar/navbar';
import { Button, Alert, Container, Spinner, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import { FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEdit } from 'react-icons/ai';
import { GoArchive } from 'react-icons/go';
import { useRealmApp } from '../../RealmApp';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const DataContribution = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const { currentUser } = useRealmApp();

    // declare realm functions
    const { getMyDataContributions, archiveDataContribution } = currentUser.functions;

    // hook for documents
    const [state, dispatch] = useReducer(reducer, {
        isDataInitialized: false,
        documents: [],
    });

    // function to update documents
    function reducer(state, action) {
        switch (action.type) {
            case 'update documents': {
                return { ...state, documents: action.value }
            }

            case 'initialize documents': {
                return { ...state, documents: action.value, isDataInitialized: true }
            }

            default: {
                return { ...state }
            }
        }
    }

    // get documents on page load
    useEffect(() => {
        async function getDocuments() {
            const response = await getMyDataContributions();

            const documents = JSON.parse(response);

            closeAlert();

            dispatch({ type: "initialize documents", value: documents });
        }
        if (state.isDataInitialized === false) {
            try {
                getDocuments(true);
            } catch (err) {
                // To-do: Alert user an error was returned
                // console.error(err);
            }
        }
    }, [state.isDataInitialized]);

    // hook for id
    const [archiveId, setArchiveId] = useState();

    // hook for archive modal
    const [archiveModal, setArchiveModal] = useState(false);

    // function to open archive modal
    const openArchiveModal = (e) => {
        setArchiveId(e);
        setArchiveModal(true);
    }

    // function to close archive modal
    function closeArchiveModal() {
        setArchiveId();
        setArchiveModal(false);
    }

    // function to archive document
    async function archiveConfirmation() {

        // hide modal component
        closeArchiveModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Archiving</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your profile is archived.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await archiveDataContribution(archiveId);

            const data = JSON.parse(response);

            dispatch({ type: "update documents", value: data });

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Archived</p>
                <p style={{ marginLeft: "28px" }}>Your profile is archived.</p>
            </div>);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    return (

        <>
            {/* navbar */}
            <NavBar />

            {/* alert message */}
            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        {/* title */}
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                Profile management
                                <sup data-tip="">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* buttons */}
                        <div className="pitchdeckstatsdiv" style={{ display: "flex", justifyContent: "end" }}>
                            <a href="/profile/add">
                                <Button className="pitchdeckaddbutton glintanimation">
                                    <span>Create or add new profile</span>
                                </Button>
                            </a>

                            <DropdownButton className="ellipsis-dropdown sort-column-button" title={<FaEllipsisH />} style={{ marginLeft: "20px" }}>
                                <Dropdown.Item href="/profile/archive">Archive</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>

                    {/* table */}
                    <div>
                        <table className="pitchdeckhistorytable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Version</th>
                                    <th>Modified</th>
                                    <th>Edit</th>
                                    <th>Archive</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.documents?.length === 0
                                    ? (<tr className="pitchdecktablerow">
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                    </tr>)
                                    : state.documents?.map((dataContribution, index) =>
                                        <tr key={index} className="pitchdecktablerow">
                                            <td className="pitchdecktabledata">
                                                <Link to={`/profile/view/${dataContribution.dataContributionId}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                    {dataContribution.dataContributionName ? dataContribution.dataContributionName : "Unnamed profile"}
                                                </Link>
                                            </td>
                                            <td className="pitchdecktabledata">{dataContribution.version ? dataContribution.version : "-"}</td>
                                            <td className="pitchdecktabledata">{dataContribution.lastModified ? dataContribution.lastModified.substring(0, 10) : "-"}</td>
                                            <td className="pitchdecktabledata">
                                                <Link to={`/profile/edit/${dataContribution.dataContributionId}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                    <Button className="edit-button"><AiOutlineEdit /></Button>
                                                </Link>
                                            </td>
                                            <td className="pitchdecktabledata">
                                                <Button className="edit-button" onClick={() => openArchiveModal(dataContribution.dataContributionId)}><GoArchive /></Button>
                                            </td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>

            {/* archive modal */}
            <Modal className="modal-div" show={archiveModal} onHide={closeArchiveModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to archive this profile?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeArchiveModal}>Cancel</button>

                        <button className="modal-delete glintanimation" onClick={archiveConfirmation}>
                            Archive
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )

}

export default DataContribution;