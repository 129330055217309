import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewCompetition(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="competitors">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="competitors">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Competitors
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="competitors">
                        <Card.Body id="companyinformation">
                            {!props?.directCompetitors
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Who are your direct competitors in your competitive landscape?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.directCompetitors.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.similarCompanies
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Who are your similar companies in your competitive landscape?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.similarCompanies.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.productsAndServices
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are their products and services?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.productsAndServices.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.marketShare
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How much market share does each competitor control?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.marketShare.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="competitorsstrengthsandweaknesses" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="competitorsstrengthsandweaknesses">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Competitors' strength and weaknesses
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="competitorsstrengthsandweaknesses">
                        <Card.Body id="companyinformation">
                            {!props?.strengthsAndWeaknesses
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are your competitors' strengths and weaknesses?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.strengthsAndWeaknesses.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.doCompetitorsEnjoyRecognition
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Do they enjoy strong brand recognition of their products or services?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.doCompetitorsEnjoyRecognition.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.marketingStrategies
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are their marketing strategies?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.marketingStrategies.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.keyFactorsToProfitability
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the key factors to their profitability?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.keyFactorsToProfitability.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="competitiveDifferentiation" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="competitiveDifferentiation">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Competitive differentiation (unique value proposition)
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="competitiveDifferentiation">
                        <Card.Body id="companyinformation">
                            {!props?.competitiveDifferentiation
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your unique value proposition or unique selling point differentiating you from your competition?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.competitiveDifferentiation.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.whyValuePropositionIsUnique
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Why is your value proposition unique and compelling?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.whyValuePropositionIsUnique.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howYourProductIsBetter
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How is your product ten times better or cheaper than existing alternatives?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howYourProductIsBetter.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.differentiationsFromCompetitorsProduct
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What differentiates your product-or-service from competitors' offerings?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.differentiationsFromCompetitorsProduct.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="industryCompetitiveOutlook" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="industryCompetitiveOutlook">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Industry competitive outlook
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="industryCompetitiveOutlook">
                        <Card.Body id="companyinformation">
                            {!props?.competitiveOutlookForIndustry
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is the competitive outlook for the industry?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.competitiveOutlookForIndustry.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.howCompetitorsThreaten
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">How much of a threat are your competitors to your venture?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.howCompetitorsThreaten.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.willCompetitorsBlock
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will your competitors aggressively block the entrance of a new rival?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.willCompetitorsBlock.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.willCompetitorsPoach
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Will your competitors poach your ideas, appropriating them for their own business (killing your unique value proposition)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.willCompetitorsPoach.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewCompetition;