import React, { useState, useEffect } from 'react';
import './pitch-deck-analysis.css';
import NavBar from '../../../../layout/navbar/navbar';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { useRealmApp } from '../../../../RealmApp';
import { useParams } from 'react-router-dom';
import ViewOverview from './view-pitch-deck-analysis/overview';
import ViewBusiness from './view-pitch-deck-analysis/business';
import ViewSolution from './view-pitch-deck-analysis/solution';
import ViewMarket from './view-pitch-deck-analysis/market';
import ViewCompetition from './view-pitch-deck-analysis/competition';
import ViewOperations from './view-pitch-deck-analysis/operations';
import ViewMarketing from './view-pitch-deck-analysis/marketing';
import ViewFinancials from './view-pitch-deck-analysis/financials';
import ViewRisk from './view-pitch-deck-analysis/risk';
// import ViewManagement from './view-pitch-deck-analysis/management';
import DataRoomView from '../../../data-room/data-room-view';

const ViewPitchDeckAnalysis = () => {

    const { currentUser } = useRealmApp();

    // if (!currentUser) {
    //     throw new Error(`Must be logged in`);
    // }

    // mongoDB realm function
    const { getMyPitchDeck } = currentUser.functions;

    // get pitchDeckId
    const { pitchDeckId } = useParams();

    const [data, setData] = useState();

    useEffect(() => {
        // Get the selected pitch deck
        async function getPitchDeck(id) {
            // console.log(id);
            // console.log("Execute the code below");
            try {
                const response = await getMyPitchDeck({ pitchDeckId: id });
                const pitchDeck = JSON.parse(response);
                setData(pitchDeck);
            } catch (err) {
                // console.error("Couldn't fetch data from Realm");
            }

        }

        if (pitchDeckId) {
            if (!data) {
                // console.log("Pitch Deck Id:", pitchDeckId);
                getPitchDeck(pitchDeckId);
            }
        }
    }, [data, pitchDeckId, getMyPitchDeck]);

    return (
        <>
            <NavBar />

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                {!data?.pitchDeckName
                                    ? ("-")
                                    : data.pitchDeckName
                                }
                            </span>
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="overview">

                            <Tab eventKey="overview" title="Overview">
                                <ViewOverview
                                    entityName={data?.entityName}
                                    entityType={data?.entityType}
                                    locationCity={data?.locationCity}
                                    locationState={data?.locationState}
                                    locationCountry={data?.locationCountry}
                                    onesentencePitch={data?.onesentencePitch}
                                    ownershipStatus={data?.ownershipStatus}
                                    markets={data?.markets}
                                    stageOfDevelopment={data?.stageOfDevelopment}
                                    transactionalModelType={data?.transactionalModelType}
                                    defensibilityMoats={data?.defensibilityMoats}
                                    industry={data?.industry}
                                    categories={data?.categories}
                                    vision={data?.vision}
                                    mission={data?.mission}
                                    lastFinancingDate={data?.lastFinancingDate}
                                    lastFinancingRound={data?.lastFinancingRound}
                                    numOfFundingRounds={data?.numOfFundingRounds}
                                />
                            </Tab>

                            <Tab eventKey="business" title="Business">
                                <ViewBusiness
                                    businessDescription={data?.businessDescription}
                                    customerPain={data?.customerPain}
                                    areYouSolvingPersonalCriticalPainPoint={data?.areYouSolvingPersonalCriticalPainPoint}
                                    howProblemIsAddressed={data?.howProblemIsAddressed}
                                    currentSolutionsShortcomings={data?.currentSolutionsShortcomings}
                                    howToGrowBusiness={data?.howToGrowBusiness}
                                    unitEconomics={data?.unitEconomics}
                                    doesPriceChange={data?.doesPriceChange}
                                    preMoneyValuation={data?.preMoneyValuation}
                                    postMoneyValuation={data?.postMoneyValuation}
                                    equityOwnershipStakeToInvestors={data?.equityOwnershipStakeToInvestors}
                                    financingRound={data?.financingRound}
                                    financingStrategy={data?.financingStrategy}
                                    howToUseMoney={data?.howToUseMoney}
                                    howMuchMoneyToRaise={data?.howMuchMoneyToRaise}
                                    howMuchExpectedFromInvestors={data?.howMuchExpectedFromInvestors}
                                    milestonesToAchieveBeforeRaiseMoney={data?.milestonesToAchieveBeforeRaiseMoney}
                                    areYouRaisingEnoughToAccomplishMilestones={data?.areYouRaisingEnoughToAccomplishMilestones}
                                    exitStrategy={data?.exitStrategy}
                                    howInvestorsGetMoney={data?.howInvestorsGetMoney}
                                />
                            </Tab>

                            <Tab eventKey="solution" title="Solution">
                                <ViewSolution
                                    customerGain={data?.customerGain}
                                    howProductMeetNeeds={data?.howProductMeetNeeds}
                                    willProductSolveNeeds={data?.willProductSolveNeeds}
                                    howDidYouGetIdea={data?.howDidYouGetIdea}
                                    isProductAspirin={data?.isProductAspirin}
                                    isProductVitamin={data?.isProductVitamin}
                                    factorsImportantInChoosing={data?.factorsImportantInChoosing}
                                    howYouRespondToNeed={data?.howYouRespondToNeed}
                                    doCustomersLoveProduct={data?.doCustomersLoveProduct}
                                />
                            </Tab>

                            <Tab eventKey="market" title="Market">
                                <ViewMarket
                                    customerSegments={data?.customerSegments}
                                    marketSegments={data?.marketSegments}
                                    problemProductSolves={data?.problemProductSolves}
                                    doesProductMakeLivesBetter={data?.doesProductMakeLivesBetter}
                                    marketTiming={data?.marketTiming}
                                />
                            </Tab>

                            <Tab eventKey="competition" title="Competition">
                                <ViewCompetition
                                    directCompetitors={data?.directCompetitors}
                                    similarCompanies={data?.similarCompanies}
                                    productsAndServices={data?.productsAndServices}
                                    marketShare={data?.marketShare}
                                    strengthsAndWeaknesses={data?.strengthsAndWeaknesses}
                                    doCompetitorsEnjoyRecognition={data?.doCompetitorsEnjoyRecognition}
                                    marketingStrategies={data?.marketingStrategies}
                                    keyFactorsToProfitability={data?.keyFactorsToProfitability}
                                    competitiveDifferentiation={data?.competitiveDifferentiation}
                                    whyValuePropositionIsUnique={data?.whyValuePropositionIsUnique}
                                    howYourProductIsBetter={data?.howYourProductIsBetter}
                                    differentiationsFromCompetitorsProduct={data?.differentiationsFromCompetitorsProduct}
                                    competitiveOutlookForIndustry={data?.competitiveOutlookForIndustry}
                                    howCompetitorsThreaten={data?.howCompetitorsThreaten}
                                    willCompetitorsBlock={data?.willCompetitorsBlock}
                                    willCompetitorsPoach={data?.willCompetitorsPoach}
                                    whoElseMightExploit={data?.whoElseMightExploit}
                                />
                            </Tab>

                            <Tab eventKey="operations" title="Operations">
                                <ViewOperations
                                    kpiMetrics={data?.kpiMetrics}
                                    metricsThatShowAchievement={data?.metricsThatShowAchievement}
                                    growthRate={data?.growthRate}
                                    proofOfLatentDemand={data?.proofOfLatentDemand}
                                    isBusinessGrowing={data?.isBusinessGrowing}
                                    isProductSelfPerpetuatingGrowth={data?.isProductSelfPerpetuatingGrowth}
                                    ableToAccomplishWithLess={data?.ableToAccomplishWithLess}
                                />
                            </Tab>

                            <Tab eventKey="marketing" title="Marketing">
                                <ViewMarketing
                                    goToMarketStrategy={data?.goToMarketStrategy}
                                    doesBusinessModelSupportAcquisition={data?.doesBusinessModelSupportAcquisition}
                                    planningToAcquireCustomersThroughMarketing={data?.planningToAcquireCustomersThroughMarketing}
                                    howProductWillBeDistributed={data?.howProductWillBeDistributed}
                                    distributionChannelsUsed={data?.distributionChannelsUsed}
                                    whenWhereWhyHowConsumersBuy={data?.whenWhereWhyHowConsumersBuy}
                                    isProductTransactionOriented={data?.isProductTransactionOriented}
                                    isBuyingProcessRelationshipOriented={data?.isBuyingProcessRelationshipOriented}
                                    areYouPlanningToBuildDirectForce={data?.areYouPlanningToBuildDirectForce}
                                    howToCommunicateWithConsumers={data?.howToCommunicateWithConsumers}
                                    willUseWordOfMouth={data?.willUseWordOfMouth}
                                    willOptForSalesPromotions={data?.willOptForSalesPromotions}
                                    doDirectSalesMakeSense={data?.doDirectSalesMakeSense}
                                    isTraditionalBetter={data?.isTraditionalBetter}
                                    doYouNeedToBuildBrandLoyalty={data?.doYouNeedToBuildBrandLoyalty}
                                />
                            </Tab>

                            <Tab eventKey="financials" title="Financials">
                                <ViewFinancials
                                    monetizationModel={data?.monetizationModel}
                                    areYouBuildingAnAnnuityBusiness={data?.areYouBuildingAnAnnuityBusiness}
                                    areYouSellingDurableProduct={data?.areYouSellingDurableProduct}
                                    financialMargins={data?.financialMargins}
                                    observedUnitEconomics={data?.observedUnitEconomics}
                                    doUnitEconomicsMakeSense={data?.doUnitEconomicsMakeSense}
                                    pricingModel={data?.pricingModel}
                                    pricingModelCustomerDemand={data?.pricingModelCustomerDemand}
                                    pricePointProductOffer={data?.pricePointProductOffer}
                                    isPriceEstablished={data?.isPriceEstablished}
                                    relevantPricingStrategy={data?.relevantPricingStrategy}
                                    appropriatePricingStrategy={data?.appropriatePricingStrategy}
                                    financialRevenue={data?.financialRevenue}
                                    moneyMadeFromTargetMarket={data?.moneyMadeFromTargetMarket}
                                    whenIsBusinessExpectedToMakeMoney={data?.whenIsBusinessExpectedToMakeMoney}
                                    durationToMakeMoney={data?.durationToMakeMoney}
                                    whenIsBusinessExpectedToReachBreakEven={data?.whenIsBusinessExpectedToReachBreakEven}
                                    anticipateToBreakEven={data?.anticipateToBreakEven}
                                    salesTargetBasedOnProjectedGrowth={data?.salesTargetBasedOnProjectedGrowth}
                                    howLongToReachNextSalesMilestone={data?.howLongToReachNextSalesMilestone}
                                    costAcquiringCustomer={data?.costAcquiringCustomer}
                                    howMuchWillCustomerBeWorth={data?.howMuchWillCustomerBeWorth}
                                    internalComponentsAssumptions={data?.internalComponentsAssumptions}
                                    incomeStatement={data?.incomeStatement}
                                    balanceSheet={data?.balanceSheet}
                                    cashFlowStatement={data?.cashFlowStatement}
                                    breakEvenAnalysis={data?.breakEvenAnalysis}
                                    financialRatiosAnalysis={data?.financialRatiosAnalysis}
                                />
                            </Tab >

                            <Tab eventKey="risk" title="Risk">
                                <ViewRisk
                                    technologyRisk={data?.technologyRisk}
                                    intellectualPropertyRisk={data?.intellectualPropertyRisk}
                                    developmentRisk={data?.developmentRisk}
                                    operationalRisk={data?.operationalRisk}
                                    productFitRisk={data?.productFitRisk}
                                    channelRisk={data?.channelRisk}
                                    regulatoryRisk={data?.regulatoryRisk}
                                    competitorRisk={data?.competitorRisk}
                                    teamRisk={data?.teamRisk}
                                    hiringRisk={data?.hiringRisk}
                                    financingRisk={data?.financingRisk}
                                    ceoRisk={data?.ceoRisk}
                                />
                            </Tab>

                            <Tab eventKey="management" title="Management" disabled>
                                {/* <ViewManagement management={data?.management} /> */}
                            </Tab>

                            <Tab eventKey="dataRoom" title="Data Room">
                                <DataRoomView />
                            </Tab>

                        </Tabs >
                    </div >
                </Container >
            </div >
        </>
    )

}

export default ViewPitchDeckAnalysis;