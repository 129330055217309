import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewOverview(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="generalinformation">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="generalinformation">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            General information
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="generalinformation">
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Entity name</p>
                                    {!props?.entityName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.entityName}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Entity type</p>
                                    {!props?.entityType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.entityType}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Ownership status</p>
                                    {!props?.ownershipStatus
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.ownershipStatus}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Market(s)</p>
                                    {!props?.markets
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.markets.map((market, index) =>
                                            !market
                                                ? ""
                                                : (<span key={index}>{market}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Headquarter location - city</p>
                                    {!props?.locationCity
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.locationCity}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Headquarter location - state</p>
                                    {!props?.locationState
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.locationState}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Headquarter location - country</p>
                                    {!props?.locationCountry
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.locationCountry}</p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">One-sentence pitch</p>
                                {!props?.onesentencePitch
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.onesentencePitch}</p>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Stage of development</p>
                                    {!props?.stageOfDevelopment
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.stageOfDevelopment}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Transactional model type</p>
                                    {!props?.transactionalModelType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.transactionalModelType}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Categories</p>
                                    {!props?.categories
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.categories.map((category, index) =>
                                            !category
                                                ? ""
                                                : (<span key={index}>{category}, </span>)
                                        )}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Competitive moat(s)</p>
                                    {!props?.defensibilityMoats
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.defensibilityMoats.map((moat, index) =>
                                            !moat
                                                ? ""
                                                : (<span key={index}>{moat}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">Industry</p>
                                {!props?.industry
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.industry}</p>)
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="goals" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="goals">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Goals
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="goals">
                        <Card.Body id="companyinformation">
                            <div>
                                <p className="companydetailtitle">Vision</p>
                                {!props?.vision
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.vision}</p>)
                                }
                            </div>
                            <div>
                                <p className="companydetailtitle">Mission</p>
                                {!props?.mission
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.mission}</p>)
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="funding" style={{ marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="funding">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Funding
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="funding">
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Last financing date</p>
                                    {!props?.lastFinancingDate
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.lastFinancingDate}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Last financing round</p>
                                    {!props?.lastFinancingRound
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.lastFinancingRound}</p>)
                                    }
                                </div>
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">No. of funding rounds</p>
                                    {!props?.numOfFundingRounds
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.numOfFundingRounds}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewOverview;