import React, { useState, useEffect } from 'react';
import { Button, Alert, Card, Spinner, Modal, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { FaEllipsisH } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { GoArchive } from 'react-icons/go';
import { AiOutlineDownload, AiOutlineCheckSquare, AiOutlineEdit, AiOutlineCloseCircle } from 'react-icons/ai';
import { useRealmApp } from '../../RealmApp';
// import { useHistory } from 'react-router-dom';
import '../admins/users.css';

const DataRoomComponent = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // hook for file details
    const [fileDetails, setFileDetails] = useState({
        name: "", // display name in table
        fileName: "", // actual name of file
        // type: "Testing",
        // fileString: ""
    });

    // hook for file chosen
    const [file, setFile] = useState();

    // function to reset file details
    function resetDetails() {
        setFileDetails({
            name: "",
            fileName: "",
            // type: "Testing",
            // fileString: ""
        });
    }

    // function to update file details
    function changeDetails(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let fileDetailsCopy = { ...fileDetails };

        fileDetailsCopy[name] = value;
        setFileDetails({ ...fileDetailsCopy });
    }

    // function to convert image
    // function convertToBase64(e) {
    //     var reader = new FileReader();

    //     reader.readAsDataURL(e.target.files[0]);

    //     reader.onload = () => {
    //         // create a copy of filters with desired changes
    //         let fileDetailsCopy = { ...fileDetails };

    //         fileDetailsCopy["fileString"] = reader.result;
    //         setFileDetails({ ...fileDetailsCopy });
    //     };

    //     reader.onerror = error => {
    //         setAlert(true);
    //         setMessage(<div style={{ paddingTop: "10px" }}>
    //             <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
    //             <p style={{ marginLeft: "28px" }}>File cannot be converted. Please choose another file.</p>
    //         </div>);
    //     }
    // }

    // function to get file
    function chooseFile(e) {
        setFile(document.getElementById("fileInput").files[0]);

        var name = document.getElementById("fileInput").files[0].name;

        // create a copy of filters with desired changes
        let fileDetailsCopy = { ...fileDetails };

        fileDetailsCopy["fileName"] = name;

        setFileDetails({ ...fileDetailsCopy });
    }

    // hook for upload modal
    const [uploadModal, setUploadModal] = useState(false);

    // function to open upload modal
    function openUploadModal() {
        setUploadModal(true);
    }

    // function to close upload modal
    function closeUploadModal() {
        resetDetails();
        setUploadModal(false);
    }

    // hook for edit modal
    const [editModal, setEditModal] = useState(false);

    // function to open edit modal
    const openEditModal = (e) => {
        setFileDetails(e);
        setEditModal(true);
    }

    // function to close edit modal
    function closeEditModal() {
        resetDetails();
        setEditModal(false);
    }

    // hook for archive modal
    const [archiveModal, setArchiveModal] = useState(false);

    // function to open archive modal
    const openArchiveModal = (e) => {
        setFileDetails(e);
        setArchiveModal(true);
    }

    // function to close archive modal
    function closeArchiveModal() {
        resetDetails();
        setArchiveModal(false);
    }

    const { currentUser } = useRealmApp();

    const { getMyFiles, uploadFile, archiveFile, getDataRoomPresignedUrl } = currentUser.functions;

    // hook for files
    const [files, setFiles] = useState([]);

    // fetch users file documents on page load
    useEffect(async () => {

        try {
            const response = await getMyFiles();

            const data = JSON.parse(response);

            setFiles(data);

            setAlert(false);

        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

    }, []);

    // upload file
    async function uploadFileConfirmation() {

        // hide modal component
        closeUploadModal();

        // get file size
        var fileSize = document.getElementById("fileInput").files[0].size;

        // check file size
        if (fileSize > 5000000) {
            // show alert message
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, your document size is too large. Please reduce your document size to not more than 5MB and try again.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If you have trouble uploading your document, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>);
        }
        else {
            // show alert message
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Saving</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your document is saved.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            // Get presigned URL
            let url;

            try {
                url = await getDataRoomPresignedUrl({ ...fileDetails, type: "upload" });
            }
            catch (error) {
                // TODO: Handle error
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Failed to get presigned url.</p>
                </div>);

                // console.log("Error: ", error);
                return;
            }

            // Upload file to S3
            try {
                // console.log("URL: ", url);
                await fetch(url, {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/octet-stream' },
                    body: file,
                });
            }
            catch (error) {
                // TODO: Handle error
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Failed to upload document.</p>
                </div>);

                // console.log("Error: ", error);
                return;
            }

            // Save file details to db
            let response;
            try {
                response = await uploadFile(fileDetails);
            }
            catch (error) {
                // TODO: Handle error
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Failed to save document details.</p>
                </div>);

                // console.log("Error: ", error);
                return;
            }

            // parse response
            const data = JSON.parse(response);

            // set list of files to be displayed in table
            setFiles(data);

            // show alert message
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Saved</p>
                <p style={{ marginLeft: "28px" }}>Your document is saved.</p>
            </div>);
        }

    }

    // download file (base64 from mongodb)
    function downloadFileFromMongoDB(e) {
        const prefix = e.substring(0, e.indexOf(';base64,'));
        const fileType = prefix.split('/')[1];
        const fileName = new Date() + '.' + fileType;

        const decodedString = Buffer.from(e.split(';base64,')[1], "base64");

        const blob = new Blob([decodedString], { type: "application/octet-stream" });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
    }

    // download file (from s3)
    async function downloadFileFromS3(e) {
        // console.log(e);
        let url;

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Downloading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're downloading your document.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await getDataRoomPresignedUrl({ type: "download", fileName: e });
            url = response;
        }
        catch (error) {
            setAlert(true);

            if (error.name === "Error") {
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Bad internet issues or server connection errors</p>
                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be poor internet connection or interrupted connectivity issues or server connection errors. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                </div>);
                return;
                // if (error.message.includes("forbidden")) {
                //     setMessage(<div></div>);
                // }
                // if (error.message.includes("failed request")) {
                // }
            }
        }

        // console.log("Download URL: ", url);

        window.open(url, "_blank");
        setMessage(<div style={{ paddingTop: "10px" }}>
            <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Downloaded</p>
            <p style={{ marginLeft: "28px" }}>Your document is downloaded.</p>
        </div>);
    }

    // edit file
    async function editFileConfirmation() {

        // hide modal component
        closeEditModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Saving</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your updated document is saved.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await uploadFile(fileDetails);

            const data = JSON.parse(response);

            setFiles(data);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Saved</p>
                <p style={{ marginLeft: "28px" }}>Your document is saved and updated.</p>
            </div>);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    // archive file
    async function archiveFileConfirmation() {

        // hide modal component
        closeArchiveModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Archiving</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your document is archived.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await archiveFile(fileDetails);

            const data = JSON.parse(response);

            setFiles(data);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Archived</p>
                <p style={{ marginLeft: "28px" }}>Your document is archived.</p>
            </div>);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    return (
        <>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="data-room-header-div">
                <div className="data-room-dropdown">
                    <Button className="pitchdeckaddbutton glintanimation" onClick={openUploadModal}>
                        <span>Upload</span>
                    </Button>

                    <DropdownButton className="ellipsis-dropdown sort-column-button" title={<FaEllipsisH />} style={{ marginLeft: "20px" }}>
                        <Dropdown.Item href="/data-room/archive" target="_blank" rel="noopener noreferrer">Archive</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>

            <Card>
                <Card.Body id="companyinformation">
                    <div>
                        <table className="pitchdeckhistorytable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {/* <th>Type</th> */}
                                    <th>Uploaded</th>
                                    <th>Download</th>
                                    <th>Edit</th>
                                    <th>Archive</th>
                                </tr>
                            </thead>
                            <tbody>
                                {files?.length === 0 || !files
                                    ? (<tr className="pitchdecktablerow">
                                        <td className="pitchdecktabledata">-</td>
                                        {/* <td className="pitchdecktabledata">-</td> */}
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                    </tr>)
                                    : files?.map((file, index) =>
                                        <tr key={index} className="pitchdecktablerow">
                                            <td className="pitchdecktabledata">{file.name ? file.name : "Unnamed document"}</td>
                                            {/* <td className="pitchdecktabledata">{file.type}</td> */}
                                            <td className="pitchdecktabledata">{file.uploadDate.substring(0, 10)}</td>
                                            <td className="pitchdecktabledata">
                                                {
                                                    !file.fileString
                                                        ? <Button className="download-button" onClick={() => downloadFileFromS3(file.fileName)}><AiOutlineDownload /></Button>
                                                        : <Button className="download-button" onClick={() => downloadFileFromMongoDB(file.fileString)}><AiOutlineDownload /></Button>
                                                }
                                            </td>
                                            <td className="pitchdecktabledata">
                                                <Button className="edit-button" onClick={() => openEditModal(file)}><AiOutlineEdit /></Button>
                                            </td>
                                            <td className="pitchdecktabledata">
                                                <Button className="edit-button" onClick={() => openArchiveModal(file)}><GoArchive /></Button>
                                            </td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>

            <Modal className="modal-div" show={uploadModal} onHide={closeUploadModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFileName">
                            <Form.Label style={{ fontSize: "16px" }}>Document name</Form.Label>
                            <Form.Control className="inputbox" value={fileDetails.name} type="name" name="name" placeholder="Name" onChange={changeDetails} />
                        </Form.Group>

                        <Form.Label style={{ fontSize: "16px" }}>Choose document</Form.Label>
                        <input className="inputbox" type="file" onChange={chooseFile} id="fileInput" />
                    </Form>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeUploadModal}>Cancel</button>

                        <button className="modal-compare glintanimation" onClick={uploadFileConfirmation}>
                            Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modal-div" show={editModal} onHide={closeEditModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFileName">
                            <Form.Label style={{ fontSize: "16px" }}>Document name</Form.Label>
                            <Form.Control className="inputbox" value={fileDetails.name} type="name" name="name" placeholder="Name" onChange={changeDetails} />
                        </Form.Group>
                    </Form>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeEditModal}>Cancel</button>

                        <button className="modal-compare glintanimation" onClick={editFileConfirmation}>
                            Save
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modal-div" show={archiveModal} onHide={closeArchiveModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to archive this document?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeArchiveModal}>Cancel</button>

                        <button className="modal-compare glintanimation" onClick={archiveFileConfirmation}>
                            Archive
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DataRoomComponent;