import React from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';

function ViewOperations(props) {

    return (
        <>
            <Accordion className="companyaccordion" defaultActiveKey="traction">
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="traction">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Traction
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="traction">
                        <Card.Body id="companyinformation">
                            {!props?.kpiMetrics
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What is your traction (KPI metrics)?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.kpiMetrics.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.metricsThatShowAchievement
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the traction metrics that show what you have achieved so far?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.metricsThatShowAchievement.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.growthRate
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">What are the week-over-week or month-over-month growth rate?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.growthRate.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.proofOfLatentDemand
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is there proof of latent demand?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.proofOfLatentDemand.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isBusinessGrowing
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is your business growing?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isBusinessGrowing.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.isProductSelfPerpetuatingGrowth
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Is your product-or-service self-perpetuating its growth?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.isProductSelfPerpetuatingGrowth.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                            {!props?.ableToAccomplishWithLess
                                ? ("")
                                : (<div>
                                    <p className="companydetailtitle">Have you been able to accomplish with less money in less time?</p>
                                    <p className="companydetaildata">
                                        <ul>{props.ableToAccomplishWithLess.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                        )}</ul>
                                    </p>
                                </div>)
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default ViewOperations;