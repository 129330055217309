import React, { useReducer } from 'react';
import '../pitch-deck-analysis.css';
import { Accordion, Col, Card, Form, ProgressBar, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline, IoMdAdd } from 'react-icons/io';

const CreateEditRisk = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    return (

        <div className="ideacheckertabbody">
            <Col className="ideacheckerquestions">

                <Accordion className="companyaccordion" defaultActiveKey="riskDisclosure">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="riskDisclosure">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                Risk disclosure
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="riskDisclosure">
                            <Card.Body className="ideacheckeraccordioncardbody">
                                <div className="col-12 div-filter">
                                    <Form>
                                        <Form.Group>
                                            <div className="addnewscrollablediv">
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Technology risk
                                                            <sup data-tip="Explain how the innovation of your technology might not actually work in the market.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.technologyRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="technologyRisk"
                                                                                value={props.state.technologyRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update technologyRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.technologyRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove technologyRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.technologyRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add technologyRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Intellectual property risk
                                                            <sup data-tip="Explain how you might infringe on existing patents or fail to properly protect your own intellectual property.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.intellectualPropertyRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="intellectualPropertyRisk"
                                                                                value={props.state.intellectualPropertyRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update intellectualPropertyRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.intellectualPropertyRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove intellectualPropertyRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.intellectualPropertyRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add intellectualPropertyRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Development risk
                                                            <sup data-tip="Explain how you might not be able to develop a scalable product-or-service for your target market.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.developmentRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="developmentRisk"
                                                                                value={props.state.developmentRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update developmentRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.developmentRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove developmentRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.developmentRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add developmentRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Operational risk
                                                            <sup data-tip="Explain how you might not be able to provide the product-or-service at the target quality, cost, and capacity.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.operationalRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="operationalRisk"
                                                                                value={props.state.operationalRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update operationalRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.operationalRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove operationalRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.operationalRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add operationalRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Product-fit risk
                                                            <sup data-tip="Explain how you might not know the size of your market, who your customers are, the speed to capture your target market, and at what price will they pay for your product-or-service.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.productFitRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="productFitRisk"
                                                                                value={props.state.productFitRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update productFitRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.productFitRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove productFitRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.productFitRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add productFitRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Channel risk
                                                            <sup data-tip="Explain how you might not know a cost-efficient way to attract customers and sell them your product-or-service.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.channelRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="channelRisk"
                                                                                value={props.state.channelRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update channelRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.channelRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove channelRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.channelRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add channelRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Regulatory risk
                                                            <sup data-tip="Explain how your business might not be compliant with all or some of the laws and permit regulations.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.regulatoryRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="regulatoryRisk"
                                                                                value={props.state.regulatoryRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update regulatoryRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.regulatoryRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove regulatoryRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.regulatoryRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add regulatoryRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Competitor risk
                                                            <sup data-tip="Explain how might an established company launch a similar product-or-service to compete with yours.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.competitorRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="competitorRisk"
                                                                                value={props.state.competitorRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update competitorRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.competitorRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove competitorRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.competitorRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add competitorRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Team risk
                                                            <sup data-tip="Explain how your team might not get along and work well together toward the goals.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.teamRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="teamRisk"
                                                                                value={props.state.teamRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update teamRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.teamRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove teamRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.teamRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add teamRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Hiring risk
                                                            <sup data-tip="Explain how you might not be able to recruit the people you need at the time you need them.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.hiringRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="hiringRisk"
                                                                                value={props.state.hiringRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update hiringRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.hiringRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove hiringRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.hiringRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add hiringRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            Financing risk
                                                            <sup data-tip="Explain how you might not be able to raise all the money you need, even in good times.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.financingRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="financingRisk"
                                                                                value={props.state.financingRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update financingRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.financingRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove financingRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.financingRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add financingRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="ideacheckerrow">
                                                    <div>
                                                        <Form.Label>
                                                            CEO risk
                                                            <sup data-tip="Explain how you or your co-founder might not have the leadership quality and management ability of a CEO.">
                                                                <IoMdInformationCircleOutline id="information-icon" />
                                                            </sup>
                                                        </Form.Label>
                                                        {
                                                            props.state.ceoRisk.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                                        <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                            <Form.Control
                                                                                name="ceoRisk"
                                                                                value={props.state.ceoRisk[i]}
                                                                                placeholder=""
                                                                                onChange={(e) => editField({ type: "update ceoRisk", value: e.target.value, index: i })}
                                                                                style={{ borderColor: "transparent" }}
                                                                            />
                                                                            {
                                                                                props.state.ceoRisk.length !== 1 &&
                                                                                <Button className="pitchdeckaddfield"
                                                                                    onClick={() => { removeField({ type: "remove ceoRisk", index: i }); }}>
                                                                                    <IoMdClose />
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            props.state.ceoRisk.length - 1 === i &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { addField({ type: "add ceoRisk", value: "" }) }}>
                                                                                <IoMdAdd />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </Col>

            <Col className="ideacheckerresults">
                <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats" >
                            <div className="companytitlecard">
                                Results
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="resultstats">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>

                                <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                    Risk score: {props.state.analysisResult?.risk.score
                                        ? props.state.analysisResult.risk.score > 0
                                            ? props.state.analysisResult.risk.score
                                            : 0
                                        : 0}%
                                </div>

                                <ProgressBar now={props.state.analysisResult?.risk.score ? props.state.analysisResult.risk.score : 0} variant="danger" style={{ height: "2rem" }} />

                                <br />

                                {/* {
                                    (ideaCheckerRes?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {ideaCheckerRes.error}
                                        </Alert>)
                                        : ""
                                } */}

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="notification" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification" >
                            <div className="companytitlecard">
                                Notification
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="notification">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                <span className="info-notes" style={{ fontSize: "16px" }}>
                                    Note:
                                    <ul style={{ paddingLeft: "17px" }}>
                                        <li id="notes-points">Write no less than 2 words for short answers</li>
                                        <li id="notes-points">Write more than 7 words for normal answers</li>
                                        <li id="notes-points">Due to large datasets, it may take up to 1 min to compute and show results.</li>
                                        <li id="notes-points">We use advanced real-time analytics and text analytics to analyze your inputs.</li>
                                    </ul>
                                </span>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                            <div className="companytitlecard">
                                Feedback
                            </div>

                            <div className="companyarrowiconright">
                                <FaChevronDown className="arrow-icon" />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="feedback">
                            <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                {!props.state.analysisResult
                                    ? ("")
                                    : (<div className="pitchdeckrecommended">
                                        Recommended readings:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {props.state.analysisResult?.risk.recommended_read.map((reading, index) =>
                                                <li key={index}>
                                                    <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>)
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </div>

    )

}

export default CreateEditRisk;